
import React, { useContext, useRef } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import UserContext from '../UserContext';
import './StackCard.scss';
import EmotionMeter from './EmotionMeter';
import Back from '../helpers/Back';
import UI from './UI';
import WizardPop from './WizardPop';


function StackCard({ thoughts, meterValue, donationsValue, moodAnimation, donationAnimation, onTurnInClick }) {

    const { user, changeUser, handlePop } = useContext(UserContext);

    const [isExpanded, setIsExpanded] = useState(false);
    const [showTurnInButton, setShowTurnInButton] = useState(false);

    const [isPop1Open, setIsPop1Open] = useState(false);
    const [isPop2Open, setIsPop2Open] = useState(false);
    const [isPop3Open, setIsPop3Open] = useState(false);
    const [isPop4Open, setIsPop4Open] = useState(false);
    const [isPop5Open, setIsPop5Open] = useState(false);

    const hoverTimeout = useRef(null);

    let className = "StackCard";

    const completedThoughtCount = thoughts.filter(thought => thought.status === "Completed").length;
    const dollarAmounts = [
        "-", "$0.25", "$0.50", "$0.75", "$1.00", "$1.25", "$2.00", "$2.75", "$3.50", "$4.25", "$5.00"
    ];
    const dollarAmount = dollarAmounts[completedThoughtCount];

    if (isExpanded) {
        className += " IsExpanded";
    }

    useEffect(() => {
        const completedThoughtCount = thoughts.filter(thought => thought.status === "Completed").length;

        if (completedThoughtCount >= 10) {
            setShowTurnInButton(true);
        } else {
            setShowTurnInButton(false);
        }

        if (completedThoughtCount === 1) {
            setTimeout(() => {
                handlePop("completedFirstThought", setIsPop3Open);
            }, 2000);

        }

    }, [thoughts]);

    useEffect(() => {

        console.log('moodAnimation', moodAnimation);

        if (!moodAnimation) {
            document.querySelector('.smile-drop-img').style.opacity = 0;
            return;
        }

        console.log('wer stack check', user, user.pendingPops.includes("moodMeter"));
        // if (user.pendingPops.includes("moodMeter")) {
        //     user.pendingPops = user.pendingPops.filter(p => p !== "moodMeter");
        //     changeUser({ ...user });

        //     Back.saveContext("pendingPops", user.pendingPops);
        //     setIsPop1Open(true);
        //     console.log('wer stack effect done', user);
        // }
        handlePop("moodMeter", setIsPop1Open);

        let optionId = 'response-option-' + moodAnimation.cardIndex;
        const cardEl = document.getElementById(optionId);
        if (!cardEl) {
            return;
        }
        const elRect = cardEl.getBoundingClientRect();

        let startX = UI.zoom(elRect.x); // Starting x position
        let startY = UI.zoom(elRect.y - 60); // Starting y position
        let endX = UI.zoom(344); // Ending x position
        let endY = UI.zoom(636); // Ending y position (200px below starting y position)

        let box = document.querySelector('.smile-drop-img');

        //const box = document.querySelector('.dollar-drop-img');
        const container = document.querySelector('.smile-drop-container');


        const hopHeight = 120; // Height of the hop
        const bounceHeight = 30; // Height of the bounce
        const floatHeight = 15; // Height of the floating up

        const hopDuration = 700; // Duration of the hop in milliseconds
        const bounceDuration = 320; // Duration of the bounce in milliseconds
        const floatDuration = 800; // Duration of the float in milliseconds
        const fadeDuration = 500; // Duration for fading in and out

        // const hopHeight = 100; // Height of the hop
        // const bounceHeight = 100; // Height of the bounce
        // const hopDuration = 620; // Duration of the hop in milliseconds
        // const bounceDuration = 250; // Duration of the bounce in milliseconds
        // const fadeDuration = 200; // Duration for fading in and out
        // const endFadeDuration = 1000;

        // const floatHeight = 50; // Height of the floating up
        // const floatDuration = 400; // Duration of the float in milliseconds

        let startTime;

        function animate(time) {
            if (!startTime) startTime = time;
            const elapsed = time - startTime;
            const totalDuration = hopDuration + bounceDuration + floatDuration;

            // Determine which phase of the animation we're in
            if (elapsed < hopDuration) {
                // Hop phase
                const t = elapsed / hopDuration;
                const x = startX + (endX - startX) * t;
                const y = startY - (4 * hopHeight * t * (1 - t)) + (endY - startY) * t;

                // Handle opacity fade in
                if (elapsed < fadeDuration) {
                    box.style.opacity = (elapsed / fadeDuration).toFixed(2);
                } else {
                    box.style.opacity = 1;
                }

                box.style.left = `${x}px`;
                box.style.top = `${y}px`;
            } else if (elapsed < hopDuration + bounceDuration) {
                // Bounce phase
                const t = (elapsed - hopDuration) / bounceDuration;
                const x = endX;
                const y = endY - (4 * bounceHeight * t * (1 - t));

                box.style.left = `${x}px`;
                box.style.top = `${y}px`;
            } else if (elapsed < totalDuration) {
                // Float phase
                const t = (elapsed - hopDuration - bounceDuration) / floatDuration;
                const x = endX;
                const bounceEndY = endY - (4 * bounceHeight * (1 - 1) * (1 - 1)); // Y position at the end of the bounce phase
                const y = bounceEndY - (floatHeight * t);

                // Handle opacity fade out during float
                if (elapsed > totalDuration - fadeDuration) {
                    box.style.opacity = ((totalDuration - elapsed - 150) / fadeDuration).toFixed(2);
                } else {
                    box.style.opacity = 1;
                }

                box.style.left = `${x}px`;
                box.style.top = `${y}px`;
            } else {
                // End of animation, ensure element is at final position with opacity 0
                const bounceEndY = endY - (4 * bounceHeight * (1 - 1) * (1 - 1)); // Y position at the end of the bounce phase
                box.style.left = `${endX}px`;
                box.style.top = `${bounceEndY - floatHeight}px`;
                box.style.opacity = 0;
                return;
            }

            requestAnimationFrame(animate);
        }

        requestAnimationFrame(animate);
    }, [moodAnimation]);

    useEffect(() => {

        if (!donationAnimation) {
            document.querySelector('.dollar-drop-img').style.opacity = 0;
            return;
        }

        if (user.pendingPops.includes("donationsMeter")) {
            user.pendingPops = user.pendingPops.filter(p => p !== "donationsMeter");
            changeUser({ ...user });

            Back.saveContext("pendingPops", user.pendingPops);
            setIsPop2Open(true);
            console.log('wer stack effect done', user);
        }

        const amount = donationAnimation.amount;

        let optionId = 'response-option-' + donationAnimation.cardIndex;
        const cardEl = document.getElementById(optionId);
        if (!cardEl) {
            return;
        }
        const elRect = cardEl.getBoundingClientRect();

        const targetEl = document.querySelector('.DonationWidget');
        const targetRect = targetEl.getBoundingClientRect();

        let startX = elRect.x + 60; // Starting x position
        let startY = elRect.y - 60; // Starting y position
        let endX = targetRect.x - 2; // Ending x position
        let endY = targetRect.y - 48; // Ending y position (200px below starting y position)

        console.log('elRect', elRect, startX, startY);

        let box = null;
        if (amount === 1) {
            box = document.querySelector('.dollar-drop-img');
            endX -= 35;
            endY -= 11;
        }
        else if (amount === .5) {
            box = document.querySelector('.quarter-2-drop-img');

        } else if (amount === 1.5) {
            box = document.querySelector('.dollar-150-drop-img');
            endX -= 40;
            endY -= 15;
        }
        else {
            box = document.querySelector('.quarter-drop-img');
        }

        // startX = UI.zoom(startX);
        // startY = UI.zoom(startY);
        // endX = UI.zoom(endX);
        // endY = UI.zoom(endY);

        //const box = document.querySelector('.dollar-drop-img');
        const container = document.querySelector('.dollar-drop-container');


        const hopHeight = 120; // Height of the hop
        const bounceHeight = 30; // Height of the bounce
        const floatHeight = 15; // Height of the floating up

        const hopDuration = 660; // Duration of the hop in milliseconds
        const bounceDuration = 320; // Duration of the bounce in milliseconds
        const floatDuration = 800; // Duration of the float in milliseconds
        const fadeDuration = 500; // Duration for fading in and out

        // const hopHeight = 100; // Height of the hop
        // const bounceHeight = 100; // Height of the bounce
        // const hopDuration = 620; // Duration of the hop in milliseconds
        // const bounceDuration = 250; // Duration of the bounce in milliseconds
        // const fadeDuration = 200; // Duration for fading in and out
        // const endFadeDuration = 1000;

        // const floatHeight = 50; // Height of the floating up
        // const floatDuration = 400; // Duration of the float in milliseconds

        let startTime;

        function animate(time) {
            if (!startTime) startTime = time;
            const elapsed = time - startTime;
            const totalDuration = hopDuration + bounceDuration + floatDuration;

            // Determine which phase of the animation we're in
            if (elapsed < hopDuration) {
                // Hop phase
                const t = elapsed / hopDuration;
                const x = startX + (endX - startX) * t;
                const y = startY - (4 * hopHeight * t * (1 - t)) + (endY - startY) * t;

                // Handle opacity fade in
                if (elapsed < fadeDuration) {
                    box.style.opacity = (elapsed / fadeDuration).toFixed(2);
                } else {
                    box.style.opacity = 1;
                }

                box.style.left = `${x}px`;
                box.style.top = `${y}px`;
            } else if (elapsed < hopDuration + bounceDuration) {
                // Bounce phase
                const t = (elapsed - hopDuration) / bounceDuration;
                const x = endX;
                const y = endY - (4 * bounceHeight * t * (1 - t));

                box.style.left = `${x}px`;
                box.style.top = `${y}px`;
            } else if (elapsed < totalDuration) {
                // Float phase
                const t = (elapsed - hopDuration - bounceDuration) / floatDuration;
                const x = endX;
                const bounceEndY = endY - (4 * bounceHeight * (1 - 1) * (1 - 1)); // Y position at the end of the bounce phase
                const y = bounceEndY - (floatHeight * t);

                // Handle opacity fade out during float
                if (elapsed > totalDuration - fadeDuration) {
                    box.style.opacity = ((totalDuration - elapsed - 150) / fadeDuration).toFixed(2);
                } else {
                    box.style.opacity = 1;
                }

                box.style.left = `${x}px`;
                box.style.top = `${y}px`;
            } else {
                // End of animation, ensure element is at final position with opacity 0
                const bounceEndY = endY - (4 * bounceHeight * (1 - 1) * (1 - 1)); // Y position at the end of the bounce phase
                box.style.left = `${endX}px`;
                box.style.top = `${bounceEndY - floatHeight}px`;
                box.style.opacity = 0;
                return;
            }

            requestAnimationFrame(animate);
        }

        requestAnimationFrame(animate);
    }, [donationAnimation]);

    const handleTurnInClick = () => {
        setShowTurnInButton(false);
        onTurnInClick();
    };

    const handleMouseEnter = () => {
        hoverTimeout.current = setTimeout(() => {
            //setIsExpanded(true);
            //document.querySelector('.SessionGrid').classList.add('Considering');
        }, 10);
    };

    const handleMouseLeave = () => {
        clearTimeout(hoverTimeout.current);
        //setIsExpanded(false);
        //document.querySelector('.SessionGrid').classList.remove('Considering');
    };

    if (showTurnInButton) {
        className += " ShowTurnInButton";
    }

    return (
        <div className='StackCard-position'>
            <div className={className} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>



                <div className='CardWidget StackWidget'>
                    <div className='m-heading'>
                        My Board
                    </div>
                    <div className='progress-count'>{completedThoughtCount}<span className='progress-outof'> / 10</span></div>

                    {isPop4Open && <WizardPop name={'completed-first-thought-2'} positions={['top']} shift={[40, -68]} isOpen={isPop4Open} onClose={() => { setIsPop4Open(false); setTimeout(() => setIsPop5Open(true), 200) }}></WizardPop>}
                </div>

                <div className='MoodWidget StackWidget'>
                    <div className='m-heading'>
                        My Mood Boost
                    </div>
                    <div className='mood-meter'>
                        <img src="mood-meter.png" className='meter-img' />
                        <img src="mood-needle.png" className={`needle-img Value${meterValue}`} />
                    </div>
                    {isPop1Open && <WizardPop name={'mood-meter'} positions={['top']} shift={[0, 0]} isOpen={isPop1Open} onClose={() => { setIsPop1Open(false) }}></WizardPop>}
                </div>

                <div className='DonationWidget StackWidget'>
                    <div className='m-heading'>
                        My Contributions
                    </div>
                    <div className='goal-content'>
                        <img src="dollar-bill3.jpg" className='dollar-img' />
                        <img src="dollar-coin.png" className='coin-img' />
                        <div className='goal'><span className='current-count'>${formatToDollarCents(donationsValue)}</span><span className='progress-outof'>/ $50</span></div>
                    </div>
                    {isPop2Open && <WizardPop name={'donation-meter'} positions={['top']} shift={[0, 0]} isOpen={isPop2Open} onClose={() => { setIsPop2Open(false) }}></WizardPop>}
                </div>

                {/* <div className='stack-bubble challenge-bubble'>
                    <div className='card-heading'>
                        My Earnings
                    </div>
                    <div className='progress-count'><span className='current-count'>$4.50</span> <span className='progress-outof'>/ $50</span></div>
                </div> */}

                <div className='turn-in-button' onClick={handleTurnInClick}>
                    Turn In Board
                </div>

                <div className='dollar-drop-container'>
                    <img src="dollar-bill3.jpg" className='dollar-drop-img' />
                    <img src="dollar-coin.png" className='quarter-drop-img' />
                    <img src="dollar-coin-2.png" className='quarter-2-drop-img' />
                    <img src="dollar-150.png" className='dollar-150-drop-img' />
                </div>

                <div className='mood-drop-container'>
                    <img src="green-smile-face.png" className='smile-drop-img' />
                </div>

                <div className='rewards-bubble card-bubble'>
                    <div className='reward-dollars'>{dollarAmount}</div>
                </div>

                {isPop3Open && <WizardPop name={'completed-first-thought'} positions={['right']} shift={[10, 280]} isOpen={isPop3Open} onClose={() => { setIsPop3Open(false); setTimeout(() => setIsPop4Open(true), 200) }}></WizardPop>}
                {isPop5Open && <WizardPop name={'completed-first-thought-3'} positions={['bottom']} shift={[-60, 4]} isOpen={isPop5Open} onClose={() => { setIsPop5Open(false) }}></WizardPop>}

            </div>
        </div>
    );


}

export default StackCard;

function formatToDollarCents(number) {
    // Ensure the input is a number
    if (typeof number !== 'number' || isNaN(number)) {
        return "0.00";
    }

    // Convert the number to a fixed 2 decimal string
    let dollarCents = number.toFixed(2);

    // Return the formatted string
    return dollarCents;
}