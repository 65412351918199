import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import Back from '../helpers/Back';
import './ThoughtSetContent.scss';
import UI from './UI';


function ThoughtSetContent({ slot, isFlat, isIncomplete, isGrid, isLog, hideNumber }) {

    let className = "ThoughtSetContent";

    className += " " + slot.thought.category;

    if (slot.thought.thought_set) {
        slot.thought = { ...slot.thought, ...slot.thought.thought_set };
        console.log('merge thought_set after', slot);
    }

    let progress = slot.thought.progress;
    if (progress !== 0 && !progress && slot.thought.thought_set) {
        progress = slot.thought.thought_set.progress;
    }
    const isThoughtCompleted = progress >= 100;

    let row = slot.row;
    if (!row && slot.thought) {
        row = slot.thought.row;
    }

    const isExploring = slot.thought.reframing_thought === "Empty";
    const isReinforce = !isExploring;

    if (isFlat) {
        className += " IsFlat";
    }
    if (isIncomplete) {
        className += " IsIncomplete";
    }
    if (isGrid) {
        className += " IsGrid";
    }
    if (isLog) {
        className += " IsLog";
    }
    if (isExploring) {
        className += " IsExploring";
    }
    if (hideNumber) {
        className += " HideNumber";
    }

    let area = null;
    if (slot.thought.thought_set) {
        area = slot.thought.thought_set.area;
    } else {
        area = slot.thought.area;
    }

    if (slot.isNeutral || slot.thought.isNeutral && !isReinforce) {
        className += " IsNeutral";
    }
    if (isReinforce) {
        className += " IsReinforce";
    }

    if (!area) {
        area = 'Focus';
    }

    let exploreItemCount = 0;
    let exploreItems = [];
    if (slot.thought.exploreItems) {
        exploreItemCount = slot.thought.exploreItems.length;
        exploreItems = slot.thought.exploreItems;
    } else if (slot.thought.context.exploreItems) {
        exploreItemCount = slot.thought.context.exploreItems.length;
        exploreItems = slot.thought.context.exploreItems;
    }

    let thoughtSetId = slot.thought.id;
    if (!thoughtSetId) {
        thoughtSetId = 0;
    }
    className += " " + area;

    return (
        <div className={className}>
            {/* 
            <div className='card-number-position'>
                <div className='card-number'>
                    <div className='card-content'>
                        <img src={`checkmark-gold2.png`} className='check-img' />
                        <div className='number-label'>{slot.number}</div>
                    </div>
                </div>
            </div>
            <div className='card-icon-position'>
                <div className='card-icon'>
                    <div className='card-content'>
                        <img src={`skill-optimism.png`} className='check-img' />

                    </div>
                </div>
            </div> */}

            <div className='card-ear'>
                <div className='ear-number'>{slot.number}</div>
            </div>
            <div className='card-ear ear-donation'>
                <div className='ear-number'>$.25</div>
            </div>

            <div className='flat-content' style={{ backgroundImage: 'url(sticky-note-' + row.color + '.png)' }}>

                <div className='reframed-content'>
                    <div className='label-content'>
                        <div className='thought-label reframed-label calc-lines-title'>
                            {UI.removeBracketedWord(isExploring ? slot.thought.thought : slot.thought.reframing_thought)}
                        </div>
                    </div>
                </div>

                <div className='notes-content'>
                    <div className='explore-item'>
                        <div className='number'>1</div>
                        <div className='label'>{exploreItems[0].label}</div>
                    </div>
                    <div className='explore-item'>
                        <div className='number'>2</div>
                        <div className='label'>{exploreItems[1].label}</div>
                    </div>
                </div>

                <div className='progress-content'>



                    {isThoughtCompleted && (
                        <div className='completed-content'>
                            <div className='completed-label'>Completed!</div>
                            <div className='more-label'>Reassessment reached</div>
                        </div>
                    )}



                    {!isThoughtCompleted && (
                        <div className='stage-content'>



                            <div className='stage-progress'>

                                <div className='stage-progressbar'>
                                    <div data-thought-set-id={thoughtSetId} data-reframing-thought={slot.thought.reframing_thought} className='stage-fillbar' style={{ width: progress + "%" }}></div>
                                </div>
                                <div className='stage-outof'>{exploreItemCount} <span className='end'>/ 6</span></div>
                            </div>

                        </div>

                    )}



                    {!isIncomplete && area && (
                        <div className='area-tags'>
                            <div className='large-area-tags'>
                                <div className={`AreaTag AreaTag + ${area}`}>
                                    <div className='area-icon'><img src={`skill-${area.toLowerCase()}.png`} className={`area-img area-${area.toLowerCase()}`} /></div>
                                    <div className='area-points'><span className='plus-sign'>+</span>{""}</div>
                                </div>
                            </div>
                        </div>
                    )}


                    {/* <div className='thought-tags'>
                        <div className='thought-tag tag-self-esteem'>{slot.thought.primary_skill}</div>
                    </div> */}
                </div>

                {/* <div className='progress-label progress-label-name'><span className='stage-name'>{isReinforce ? 'Reinforcing' : 'Exploring'}</span></div> */}
                <div className='progress-label'><span className='stage-name'>{isReinforce ? 'Reinforcing' : 'Exploring'}</span>{progress}%</div>
            </div>
        </div >
    );


}

export default ThoughtSetContent;