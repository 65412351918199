

export default {

    getTop: (top) => {
        // Get the zoom level from the body
        const zoomLevel = parseFloat(getComputedStyle(document.body).zoom);

        // Calculate the reciprocal of the zoom level
        const reciprocal = 1 / zoomLevel;

        // Calculate adjusted positions
        const adjustedTop = top * reciprocal;
        const adjustedLeft = left * reciprocal;

        return adjustedTop;
    },

    getLeft: (left) => {
        // Get the zoom level from the body
        const zoomLevel = parseFloat(getComputedStyle(document.body).zoom);

        // Calculate the reciprocal of the zoom level
        const reciprocal = 1 / zoomLevel;

        // Calculate adjusted positions
        const adjustedTop = top * reciprocal;
        const adjustedLeft = left * reciprocal;

        return adjustedLeft;
    },

    zoom: (number) => {
        // Get the zoom level from the body
        const zoomLevel = parseFloat(getComputedStyle(document.body).zoom);

        // Calculate the reciprocal of the zoom level
        const reciprocal = 1 / zoomLevel;

        return number * reciprocal;
    },

    getDropSpaceHeight: () => {

        // Get the zoom level from the body
        const zoomLevel = parseFloat(getComputedStyle(document.body).zoom);

        // Calculate the reciprocal of the zoom level
        const reciprocal = 1 / zoomLevel;

        const thisHeight = window.innerHeight * reciprocal;

        return thisHeight - 108;



        return;
        const width = window.innerWidth;
        const height = window.innerHeight;

        let whiteHeight = 108;

        let dropSpaceHeight = height - 90;
        let pageZoom = 1;
        if (width <= 1380) {
            pageZoom = 1.18;
            dropSpaceHeight = height - 16;
        }
        else if (width <= 1460) {
            pageZoom = 1.05;
            dropSpaceHeight = height - 68;
        }
        else if (width <= 1540) {
            pageZoom = 1.025;
        }
        else if (width <= 1720) {
            dropSpaceHeight = height - 108;
        }
        else if (width >= 1920) {
            dropSpaceHeight = height - 220;
        }

        console.log('dropSpace', dropSpaceHeight);

        //const dropSpaceHeight = (window.innerHeight * pageZoom - 138);
        return dropSpaceHeight;
    },

    extractWordInBrackets: (sentence) => {
        const match = sentence.match(/^\[(.*?)\]/);
        return match ? match[1] : "Ease"; // Return the captured word or null if no match
    },

    removeBracketedWord: (sentence) => {
        if (!sentence) {
            return sentence;
        }

        const match = sentence.match(/^\[(.*?)\] (.*)/); // Capture the bracketed word and the rest of the sentence
        return match ? match[2] : sentence; // Return the remaining sentence or the original sentence if no match
    },

    getScoreClass: (score, className) => {

        if (!className) {
            className = 'score';
        }

        if (score < 45) {
            return className + ' poor';
        }
        if (score < 65) {
            return className + ' fair';
        }
        if (score < 75) {
            return className + ' good';
        }

        return className + ' great';
    },

    getScoreCategory: (score) => {
        if (score < 40) {
            return 'Low';
        }
        if (score < 60) {
            return 'Fair';
        }
        if (score < 70) {
            return 'Good';
        }

        return 'Great';
    },
}

function toHoursAndMinutes(totalMinutes) {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return { hours, minutes };
}