
import React, { useContext, useRef } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import UserContext from '../UserContext';
import './AssessmentReport.scss';
import EmotionMeter from './EmotionMeter';
import Back from '../helpers/Back';
import UI from './UI';


function AssessmentReport({ assessmentRound, userData, onDoneClick }) {

    const { user, changeUser } = useContext(UserContext);

    console.log('assessment report3', assessmentRound);

    let className = "AssessmentReport";

    const handleReportDoneClick = () => {

        let credits = user.context.assessmentCredits;
        credits -= 1;
        user.context.assessmentCredits = credits;
        changeUser({ ...user });

        onDoneClick();
    };

    const assessmentRow = userData.negative_rows.find(
        r => r.name === assessmentRound.name
    );
    const assessmentStats = assessmentRow.assessment_stats;
    const assessmentHistory = assessmentStats.history;
    console.log('assessmentRow', assessmentRow, assessmentHistory);

    console.log('assessmentReport userData', userData);

    return (

        <div className={className}>

            <div className='report-layout'>
                <div className='breakdown-col'>


                    <div className='report-subheading'>
                        Assessment Completed!
                    </div>

                    <div className='assessment-heading'>

                        <div className='label'>{assessmentRound.name}</div>
                    </div>

                    <div className='date-label'>
                        Completed on {getCurrentDateString()}
                    </div>

                    <div className={UI.getScoreClass(assessmentRound.gradedScore, 'score-bubble score-value')}>

                        <div className={UI.getScoreClass(assessmentRound.gradedScore, 'score-value')}>
                            My Score
                        </div>

                        <div className={UI.getScoreClass(assessmentRound.gradedScore, 'score-big')}>
                            {assessmentRound.gradedScore}
                        </div>

                    </div>

                    <div className='history-heading'>
                        My Scores
                    </div>

                    <div className='score-breakdown'>
                        {assessmentHistory.map(item => {
                            return (
                                <div className='score-item'>
                                    <div className='label'>
                                        {item.date}
                                    </div>
                                    <div className={UI.getScoreClass(item.score)}>{item.score}</div>
                                </div>
                            )
                        })}

                    </div>

                    <div className='report-done-button' onClick={handleReportDoneClick}>Finish</div>


                </div>
                <div className='questions-col'>



                    {/* <div className='questions-heading'>
                        My Answers
                    </div>
                    <div className='answers'>
                        {assessmentRound.questions.map((question, index) => {
                            return (
                                <div className='answer'>
                                    <div className='number'>
                                        {index + 1}.
                                    </div>
                                    <div className='label'>
                                        {question.label}
                                    </div>
                                    <div className={UI.getScoreClass(question.answer)}>{question.answer}</div>
                                </div>
                            )
                        })}
                    </div> */}
                </div>
            </div>

        </div>

    );


}

export default AssessmentReport;

function getCurrentDateString() {
    const date = new Date();
    const options = { month: 'long', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
}