import React, { useContext, useEffect, useState } from 'react';
import './TakeAssessmentScreen.scss';

import GridLoader from "react-spinners/GridLoader";
import ContentLoader from 'react-content-loader';
import SuggestionsSheet from './SuggestionsSheet';
import AiMessage from './AiMessage';
import Back from '../helpers/Back';
import EmotionMeter from './EmotionMeter';
import ReframingList from './ReframingList';
import ThoughtSetContent from './ThoughtSetContent';
import AnimationContext from '../AnimationContext';
import WizardPop from './WizardPop';
import UserContext from '../UserContext';
import UI from './UI';
import ThoughtPostRating from './ThoughtPostRating';
import QuestionCard from './QuestionCard';
import AssessmentSidebar from './AssessmentSidebar';
import AssessmentReport from './AssessmentReport';

function TakeAssessmentScreen({ initialAssessment, onAssessmentCompleted }) {

    const { user, changeUser, handlePop } = useContext(UserContext);
    const animations = useContext(AnimationContext);

    function intialQuestions(assessment) {
        if (assessment.type === "Goal") {
            return [...areaQuestions['Goal']];
        }

        return [...areaQuestions[assessment.name]];
    }

    const [activeAssessment, setActiveAssessment] = useState(initialAssessment);
    const [isFirstAssessment, setIsFirstAssessment] = useState(true);

    console.log('wer initialAssessment', initialAssessment);

    const [activeQuestions, setActiveQuestions] = useState(intialQuestions(initialAssessment).splice(0, 4));

    const [userData, setUserData] = useState(null);

    const [isPop1Open, setIsPop1Open] = useState(false);

    useEffect(() => {
        initialAssessment.questions = intialQuestions(initialAssessment);

        const newAssessment = goToNextPage(initialAssessment, true);

        setActiveAssessment({ ...newAssessment });
    }, [initialAssessment]);

    useEffect(() => {
        handlePop("takeAssessment", setIsPop1Open);
    }, []);

    console.log('activeAssessment', activeAssessment, activeQuestions);

    const handleAssessmentDoneClick = async (e) => {


        console.log('handleAssessmentDoneClick', activeAssessment);

        if (isStart) {

            // If user just completed their first assessment 
            // but still needs to do their second one now...
            if (activeAssessment.areaName === selectedGoals[0]) {
                console.log('isStart1');
                const areaName = selectedGoals[1];
                let newAssessment = {
                    areaName: areaName,
                    name: areaName,
                    page: 0,
                    pageQuestions: [],
                    completedQuestions: [],
                    questions: [...areaQuestions[areaName]]
                };
                console.log('newAssessment', newAssessment);
                newAssessment = await goToNextPage(newAssessment);
                console.log('newAssessmentAfter', newAssessment);
                setActiveAssessment({ ...newAssessment });
                setStep("Assessment");
            }

            // Or if the user just completed their second assessment 
            // is totally done now.
            else {
                console.log('isStart2');
                user.PickGoalsDrawer = false;
                user.hasCompletedIntro = true;
                changeUser({ ...user });
                onStartingAssessmentsCompleted();

                setTimeout(() => {
                    setStep("Goals");
                    setActiveAssessment(null);
                    setIsFirstAssessment(false);
                }, 1000);
            }
        }
    };

    const goToNextPage = (assessment) => {

        console.log('goToNextPage start', assessment);

        const newAssessment = { ...assessment };

        const waitingQuestions = newAssessment.questions.filter(q => q.status === "Waiting");
        const pageQuestions = waitingQuestions.slice(0, 4);

        const firstQuestionIndex = pageQuestions.findIndex(q => q.status === "Waiting");

        console.log('goToNextPage middle', waitingQuestions, pageQuestions, firstQuestionIndex);

        if (firstQuestionIndex < 0) {
            newAssessment.isCompleted = true;
            return newAssessment;
        }

        pageQuestions[firstQuestionIndex].status = "Active";

        //newAssessment.page += 1;
        newAssessment.pageQuestions = pageQuestions;

        console.log('goToNextPage done', newAssessment);

        return newAssessment;
    };

    const handleAssessmentCompleted = (assessment) => {

    };

    const handleQuestionCompleted = async (question, option) => {
        console.log('WER handleQuestion', question, option);
        question.status = 'Completed';
        question.isFaded = true;
        question.answer = option;

        const newQuestions = [...activeAssessment.questions];
        const qIndex = newQuestions.findIndex(q => q.number === question.number);
        newQuestions[qIndex] = { ...question };
        activeAssessment.questions = newQuestions;

        console.log('copying pageQuestions', activeAssessment.pageQuestions, activeAssessment);
        const newPageQuestions = [...activeAssessment.pageQuestions];

        const questionIndex = newPageQuestions.findIndex(q => q.number === question.number);

        newPageQuestions[questionIndex] = { ...question };

        const newQuestionIndex = newPageQuestions.findIndex(q => q.status === "Waiting");

        console.log('handleQuestionCompleted', newQuestionIndex, newPageQuestions, newQuestions);

        if (newQuestionIndex < 0) {
            console.log('setIsFirstAssessment top', isFirstAssessment);


            activeAssessment.pageQuestions = newPageQuestions;
            setActiveAssessment({ ...activeAssessment }); // Redundant but needed for visual style change of last question before turning question.

            const newAssessment = await goToNextPage(activeAssessment);

            console.log('WER isCompleted', newAssessment.isCompleted, newAssessment);

            if (newAssessment.isCompleted) {
                gradeAssessment(newAssessment);

                console.log('save assessment3', newAssessment);
                const response = await Back.saveAssessment(
                    newAssessment.type,
                    newAssessment.type === "Goal" ? newAssessment.goal.id : newAssessment.name,
                    newAssessment.gradedScore,
                    newAssessment.questions
                );

                await animatePageLoading(1000);

                const assessmentData = response.assessment;
                const userData = response.user;

                const newUser = { ...user };
                newUser.negative_rows = userData.negative_rows;
                newUser.active_assessments = userData.active_assessments;
                changeUser({ ...newUser });

                console.log('WER done grade', assessmentData, userData, response, newAssessment);

                setUserData(userData);
                //setStep("Score");

                // if (isStart && isFirstAssessment) {
                //     setIsPop2Open(true);
                // }

                //setIsFirstAssessment(false);
            }

            setActiveAssessment({ ...newAssessment });
            setTimeout(() => {
                const newQuestions = [...activeAssessment.questions];
                const qIndex = newQuestions.findIndex(q => q.isFaded === true);
                newQuestions[qIndex].isFaded = false;
                activeAssessment.questions = newQuestions;
                setActiveAssessment({ ...newAssessment });
            }, 10);

            await animatePageLoading(1000);


            setActiveQuestions(newAssessment.pageQuestions);






            return;
        }

        newPageQuestions[newQuestionIndex].status = 'Active';

        activeAssessment.questions = newQuestions;
        setActiveAssessment({ ...activeAssessment });

        setTimeout(() => {
            const newQuestions = [...activeAssessment.questions];
            const qIndex = newQuestions.findIndex(q => q.isFaded === true);
            newQuestions[qIndex].isFaded = false;
            activeAssessment.questions = newQuestions;
            setActiveAssessment({ ...activeAssessment });
        }, 10);
    };

    const gradeAssessment = (assessment) => {



        const totalScore = assessment.questions.reduce(
            (aggregate, q) => {
                return parseInt(aggregate) + parseInt(q.answer);
            }, 0
        );

        const gradedScore = Math.round(totalScore / 16);

        assessment.totalScore = totalScore;
        assessment.gradedScore = gradedScore;

        console.log('WER gradeAssessment', assessment);

        return assessment;
    };

    const handleReportDoneClick = async () => {

        console.log('save assessment2', activeAssessment);

        await animatePageLoading(1000);
        onAssessmentCompleted();
    };

    const animatePageLoading = async (time) => {
        if (!time) {
            time = 900;
        }

        document.querySelector('.assessment-content').classList.add('IsPageLoading');
        await delay(time);
        const el = document.querySelector('.assessment-content').classList.remove('IsPageLoading');
    };

    async function delay(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    let assessmentContent = (
        <div></div>
    );
    if (activeAssessment) {

        if (activeAssessment.isCompleted) {
            assessmentContent = (
                <div className='assessment-content assessment-content-report'>
                    <AssessmentReport assessmentRound={activeAssessment} userData={userData} onDoneClick={handleReportDoneClick}></AssessmentReport>
                </div>
            );
        } else {
            assessmentContent = (
                <div className='assessment-content'>
                    <div className='active-questions'>
                        {activeQuestions.map(question => <QuestionCard question={question} key={question.number} onCompleted={handleQuestionCompleted}></QuestionCard>)}
                    </div>
                </div>
            );
        }
    }

    let className = "TakeAssessmentScreen";

    return (
        <React.Fragment>
            <AssessmentSidebar activeAssessment={activeAssessment}></AssessmentSidebar>
            <div className={className}>
                {isPop1Open && <WizardPop name={'take-assessment'} positions={['bottom']} shift={[387, -42]} isOpen={isPop1Open} onClose={() => { setIsPop1Open(false) }}></WizardPop>}
                {assessmentContent}
            </div>
        </React.Fragment>
    );


}

export default TakeAssessmentScreen;


const areaQuestions = {
    "Focus": [
        {
            number: 1,
            status: "Waiting",
            label: "I’m able to limit distractions during most of my workday."
        },
        {
            number: 2,
            status: "Waiting",
            label: "When I do get distracted during the day, I can usually get back on track without too much trouble."
        },
        {
            number: 3,
            status: "Waiting",
            label: "When I have a difficult project that requires a lot of mental effort, I have ways to apply myself and make progress."
        },
        {
            number: 4,
            status: "Waiting",
            label: "I have good ways to recharge throughout the day that leave me feeling energized and ready to focus again."
        },
        {
            number: 5,
            status: "Waiting",
            label: "Getting myself to stay focused during the day doesn’t feel like a constant battle with myself."
        },
        {
            number: 6,
            status: "Waiting",
            label: "When I need to focus on a work task, I typically don't experience \"brain fog\" or difficulty doing so."
        },
        {
            number: 7,
            status: "Waiting",
            label: "When I'm trying to enjoy something, I can usually focus on the experience and soak it in."
        },
        {
            number: 8,
            status: "Waiting",
            label: "For the most part, I feel like I'm \"in control\" of my mind and not the other way around."
        },
        {
            number: 9,
            status: "Waiting",
            label: "While I’m not perfect, I have healthy habits regarding how I use my phone and devices."
        },
        {
            number: 10,
            status: "Waiting",
            label: "Even when things don't go expected."
        },
        {
            number: 11,
            status: "Waiting",
            label: "Relationships, emotions."
        },
        {
            number: 12,
            status: "Waiting",
            label: "Future-oriented."
        },
        {
            number: 13,
            status: "Waiting",
            label: "While I’m not perfect, I have healthy habits regarding how I use my phone and devices."
        },
        {
            number: 14,
            status: "Waiting",
            label: "Even when things don't go expected."
        },
        {
            number: 15,
            status: "Waiting",
            label: "Relationships, emotions."
        },
        {
            number: 16,
            status: "Waiting",
            label: "Future-oriented."
        }
    ],
    "Mood": [
        {
            number: 1,
            status: "Waiting",
            label: "I wake up feeling excited about the day most mornings."
        },
        {
            number: 2,
            status: "Waiting",
            label: "At least once or twice a day, I feel a spontaneous burst of joy, energy, or confidence."
        },
        {
            number: 3,
            status: "Waiting",
            label: "I have a strong understanding of what creates bad moods for myself."
        },
        {
            number: 4,
            status: "Waiting",
            label: "When I hit my lowest of lows, I feel bad, but not totally helpless and hopeless."
        },
        {
            number: 5,
            status: "Waiting",
            label: "For most of the hours in the day, I feel like I have enough energy to do the things I want to do."
        },
        {
            number: 6,
            status: "Waiting",
            label: "When I get into a slump, it usually doesn’t last all day."
        },
        {
            number: 7,
            status: "Waiting",
            label: "I’m aware of the behaviors that often create bad moods for myself, and am able to avoid or manage those behaviors most days."
        },
        {
            number: 8,
            status: "Waiting",
            label: "I have good ways to take a break and recharge throughout the day that leave me feeling energized and positive."
        },
        {
            number: 9,
            status: "Waiting",
            label: "I’m optimistic that in the future, I'll be able to manage my mood and feel good most days."
        },
        {
            number: 10,
            status: "Waiting",
            label: "A year from now, I see myself experiencing high energy and many joyful days."
        },
        {
            number: 11,
            status: "Waiting",
            label: "I know that, overall, I have a lot of control over how I feel by working with my thoughts and managing my behavior."
        },
        {
            number: 12,
            status: "Waiting",
            label: "Even if I experience setbacks or have to make adjustments, nothing will prevent me from experiencing lots of joy in the future."
        }
    ],
    "Goal": [
        {
            "number": 1,
            "status": "Waiting",
            "label": "I believe I have the skills necessary to achieve my goal."
        },
        {
            "number": 2,
            "status": "Waiting",
            "label": "I feel motivated to work towards my goal every day."
        },
        {
            "number": 3,
            "status": "Waiting",
            "label": "I am confident that I can overcome any obstacles that may arise."
        },
        {
            "number": 4,
            "status": "Waiting",
            "label": "I can clearly visualize the steps needed to reach my goal."
        },
        {
            "number": 5,
            "status": "Waiting",
            "label": "I understand clearly why this goal is important to me."
        },
        {
            "number": 6,
            "status": "Waiting",
            "label": "I am committed to achieving my goal, no matter how long it takes."
        },
        {
            "number": 7,
            "status": "Waiting",
            "label": "I believe that I deserve to achieve this goal."
        },
        {
            "number": 8,
            "status": "Waiting",
            "label": "I feel emotionally connected to the outcome of my goal."
        },
        {
            "number": 9,
            "status": "Waiting",
            "label": "I am open to seeking help or advice when needed."
        },
        {
            "number": 10,
            "status": "Waiting",
            "label": "I consistently track my progress towards my goal."
        },
        {
            "number": 11,
            "status": "Waiting",
            "label": "I believe in my ability to adapt my plans if needed."
        },
        {
            "number": 12,
            "status": "Waiting",
            "label": "I feel a strong sense of purpose in pursuing my goal."
        },
        {
            "number": 13,
            "status": "Waiting",
            "label": "I feel a sense of control over the progress towards my goal."
        },
        {
            "number": 14,
            "status": "Waiting",
            "label": "I stay positive about my goal even when others doubt me."
        },
        {
            "number": 15,
            "status": "Waiting",
            "label": "I am honest with myself about my progress and areas for improvement."
        },
        {
            "number": 16,
            "status": "Waiting",
            "label": "I believe that my hard work will eventually pay off."
        }
    ],
    "Relational Opennesss": [
        {
            "number": 1,
            "status": "Waiting",
            "label": "I feel comfortable sharing my true thoughts and feelings with others."
        },
        {
            "number": 2,
            "status": "Waiting",
            "label": "I consistently work on improving my communication skills."
        },
        {
            "number": 3,
            "status": "Waiting",
            "label": "I can clearly visualize the kind of relationships I want to cultivate."
        },
        {
            "number": 4,
            "status": "Waiting",
            "label": "I can express my emotions in a healthy and constructive way."
        },
        {
            "number": 5,
            "status": "Waiting",
            "label": "I feel confident in my ability to resolve conflicts peacefully."
        },
        {
            "number": 6,
            "status": "Waiting",
            "label": "I seek to understand the needs and desires of others in my relationships."
        },
        {
            "number": 7,
            "status": "Waiting",
            "label": "I am open to receiving feedback about myself from those close to me."
        },
        {
            "number": 8,
            "status": "Waiting",
            "label": "I feel motivated to understand myself better for the sake of my relationships."
        },
        {
            "number": 9,
            "status": "Waiting",
            "label": "I feel that my relationships are a source of joy and fulfillment in my life."
        },
        {
            "number": 10,
            "status": "Waiting",
            "label": "I am able to forgive and move past conflicts in my relationships."
        },
        {
            "number": 11,
            "status": "Waiting",
            "label": "I make an effort to celebrate the successes and milestones of those close to me."
        },
        {
            "number": 12,
            "status": "Waiting",
            "label": "I am patient and understanding with others' flaws and imperfections."
        },
        {
            "number": 13,
            "status": "Waiting",
            "label": "I believe that my efforts to understand myself will pay off in my relationships."
        },
        {
            "number": 14,
            "status": "Waiting",
            "label": "I am open to change and growth within my relationships."
        },
        {
            "number": 15,
            "status": "Waiting",
            "label": "I am committed to being open and honest in my relationships."
        },
        {
            "number": 16,
            "status": "Waiting",
            "label": "I trust in my ability to build meaningful connections with others."
        }
    ],
    "Drive to Finish": [
        {
            "number": 1,
            "status": "Waiting",
            "label": "I rarely procrastinate on important tasks."
        },
        {
            "number": 2,
            "status": "Waiting",
            "label": "I often visualize myself completing my projects with positive emotions."
        },
        {
            "number": 3,
            "status": "Waiting",
            "label": "I believe in my capacity to improve upon a project while I work on it."
        },
        {
            "number": 4,
            "status": "Waiting",
            "label": "I’m confident that, with enough time, I can create high-quality work."
        },
        {
            "number": 5,
            "status": "Waiting",
            "label": "I have more positive thoughts than negative thoughts about my goals and projects."
        },
        {
            "number": 6,
            "status": "Waiting",
            "label": "My motivation does not suffer too much when I have a challenge or setback."
        },
        {
            "number": 7,
            "status": "Waiting",
            "label": "Deadlines and schedules don’t stress me out too much."
        },
        {
            "number": 8,
            "status": "Waiting",
            "label": "I feel proud of the quality of work I produce."
        },
        {
            "number": 9,
            "status": "Waiting",
            "label": "At the end of most days, I feel proud of myself and how I spent my day."
        },
        {
            "number": 10,
            "status": "Waiting",
            "label": "I have at least one thing in my life that I'm excited to work on soon."
        },
        {
            "number": 11,
            "status": "Waiting",
            "label": "On days I don't feel motivated, I can still complete tasks even if I have to make adjustments."
        },
        {
            "number": 12,
            "status": "Waiting",
            "label": "I can channel a large amount of effort and energy into my work."
        },
        {
            "number": 13,
            "status": "Waiting",
            "label": "I don’t feel overwhelmed by my work, most days."
        },
        {
            "number": 14,
            "status": "Waiting",
            "label": "I would say that I believe in myself, when it comes to my projects and goals."
        },
        {
            "number": 15,
            "status": "Waiting",
            "label": "I use positive self-talk to overcome feelings of doubt when they arise."
        },
        {
            "number": 16,
            "status": "Waiting",
            "label": "I feel empowered and excited when I’m working on things I care about."
        }
    ],
    "Natural Energy": [
        {
            "number": 1,
            "status": "Waiting",
            "label": "I wake up feeling excited about the day most mornings."
        },
        {
            "number": 2,
            "status": "Waiting",
            "label": "I frequently try new things just for the fun of it."
        },
        {
            "number": 3,
            "status": "Waiting",
            "label": "I laugh and smile often throughout the day."
        },
        {
            "number": 4,
            "status": "Waiting",
            "label": "I have a strong understanding of what creates bad moods for myself."
        },
        {
            "number": 5,
            "status": "Waiting",
            "label": "I feel a sense of freedom when I break out of my routine."
        },
        {
            "number": 6,
            "status": "Waiting",
            "label": "I am open to changing my plans at the last minute."
        },
        {
            "number": 7,
            "status": "Waiting",
            "label": "I am willing to take risks to experience new joys."
        },
        {
            "number": 8,
            "status": "Waiting",
            "label": "At least once or twice a day, I feel a spontaneous burst of joy, energy, or confidence."
        },
        {
            "number": 9,
            "status": "Waiting",
            "label": "I regularly spend time with people who uplift and energize me."
        },
        {
            "number": 10,
            "status": "Waiting",
            "label": "I am good at finding joy in simple, everyday moments."
        },
        {
            "number": 11,
            "status": "Waiting",
            "label": "When I hit my lowest of lows, I feel bad, but not totally helpless and hopeless."
        },
        {
            "number": 12,
            "status": "Waiting",
            "label": "I have ways to recharge during the day that leave me feeling energized and positive."
        },
        {
            "number": 13,
            "status": "Waiting",
            "label": "When the situation is appropriate, I can relax and go with the flow."
        },
        {
            "number": 14,
            "status": "Waiting",
            "label": "When my energy is low, I have ways to recharge."
        },
        {
            "number": 15,
            "status": "Waiting",
            "label": "When I get into a slump, it usually doesn’t last all day."
        },
        {
            "number": 16,
            "status": "Waiting",
            "label": "A year from now, I see myself experiencing high energy and many joyful days."
        }
    ],
    "Balanced Self-Care": [
        {
            "number": 1,
            "status": "Waiting",
            "label": "I feel confident in my ability to handle life's challenges."
        },
        {
            "number": 2,
            "status": "Waiting",
            "label": "I often laugh at myself when I make a harmless mistake."
        },
        {
            "number": 3,
            "status": "Waiting",
            "label": "I create a comfortable and relaxing atmosphere in my home."
        },
        {
            "number": 4,
            "status": "Waiting",
            "label": "I can tell when I’m in need of self-care before I get too low or exhausted."
        },
        {
            "number": 5,
            "status": "Waiting",
            "label": "I have a strong understanding of the benefits of self-care."
        },
        {
            "number": 6,
            "status": "Waiting",
            "label": "I feel secure enough to make self-care a priority in my life."
        },
        {
            "number": 7,
            "status": "Waiting",
            "label": "I recognize when I am neglecting self-care and adjust my habits accordingly."
        },
        {
            "number": 8,
            "status": "Waiting",
            "label": "I recognize when I am engaging in negative thinking and adjust my perspective."
        },
        {
            "number": 9,
            "status": "Waiting",
            "label": "I can identify my emotions and understand their triggers."
        },
        {
            "number": 10,
            "status": "Waiting",
            "label": "I feel confident in expressing my true self to others."
        },
        {
            "number": 11,
            "status": "Waiting",
            "label": "I create systems that make self-care an easy and natural part of my daily routine."
        },
        {
            "number": 12,
            "status": "Waiting",
            "label": "I give myself permission to feel my emotions without judgment."
        },
        {
            "number": 13,
            "status": "Waiting",
            "label": "I engage in mindful activities that help me feel calm and centered."
        },
        {
            "number": 14,
            "status": "Waiting",
            "label": "I focus on self-care as a key component of my overall life strategy."
        },
        {
            "number": 15,
            "status": "Waiting",
            "label": "I feel worthy of love and respect, just as I am."
        },
        {
            "number": 16,
            "status": "Waiting",
            "label": "A year from now, I see myself caring for myself and flourishing because of it."
        }
    ],
    "Life Purpose": [
        {
            "number": 1,
            "status": "Waiting",
            "label": "I have a clear vision of what I want my life to look like in the future."
        },
        {
            "number": 2,
            "status": "Waiting",
            "label": "I regularly set and review long-term goals that align with my life purpose."
        },
        {
            "number": 3,
            "status": "Waiting",
            "label": "I feel connected to something larger than myself in my life."
        },
        {
            "number": 4,
            "status": "Waiting",
            "label": "I take time to reflect on what truly matters to me."
        },
        {
            "number": 5,
            "status": "Waiting",
            "label": "I am able to articulate my life purpose and share it with others."
        },
        {
            "number": 6,
            "status": "Waiting",
            "label": "I feel energized and motivated by my long-term vision for my life."
        },
        {
            "number": 7,
            "status": "Waiting",
            "label": "I am open to new experiences and ideas that can help me grow and evolve."
        },
        {
            "number": 8,
            "status": "Waiting",
            "label": "At the end of most days, I feel proud of myself and how I spent my day."
        },
        {
            "number": 9,
            "status": "Waiting",
            "label": "I feel a sense of gratitude for the journey I am on and the progress I’ve made."
        },
        {
            "number": 10,
            "status": "Waiting",
            "label": "I have a clear understanding of my strengths and how to utilize them."
        },
        {
            "number": 11,
            "status": "Waiting",
            "label": "I don’t shy away from making big decisions in my life."
        },
        {
            "number": 12,
            "status": "Waiting",
            "label": "I recognize when my thoughts are hindering my progress toward my life goals."
        },
        {
            "number": 13,
            "status": "Waiting",
            "label": "I challenge limiting beliefs that prevent me from pursuing my dreams."
        },
        {
            "number": 14,
            "status": "Waiting",
            "label": "I am able to, and often do, visualize myself achieving my life goals."
        },
        {
            "number": 15,
            "status": "Waiting",
            "label": "I know my personal values, and I reflect on them pretty often."
        },
        {
            "number": 16,
            "status": "Waiting",
            "label": "I believe in my ability to create and maintain a life I love."
        }
    ],
    "Creative Flow": [
        {
            "number": 1,
            "status": "Waiting",
            "label": "I feel energized and motivated when working on creative projects."
        },
        {
            "number": 2,
            "status": "Waiting",
            "label": "I feel a sense of accomplishment after completing creative work."
        },
        {
            "number": 3,
            "status": "Waiting",
            "label": "I am confident in my ability to generate new and useful ideas."
        },
        {
            "number": 4,
            "status": "Waiting",
            "label": "I may have high standards, but I don’t let perfectionism overwhelm me."
        },
        {
            "number": 5,
            "status": "Waiting",
            "label": "I organize my work and life in a way that promotes creativity and focus."
        },
        {
            "number": 6,
            "status": "Waiting",
            "label": "I make time for activities that rejuvenate and inspire my creative mind."
        },
        {
            "number": 7,
            "status": "Waiting",
            "label": "I easily let go of ideas that are not working and move on to new ones."
        },
        {
            "number": 8,
            "status": "Waiting",
            "label": "Creativity always involves uncertainty, but I don’t let it discourage me."
        },
        {
            "number": 9,
            "status": "Waiting",
            "label": "I believe in my ability to improve my work as I put more time into it."
        },
        {
            "number": 10,
            "status": "Waiting",
            "label": "I believe that I mostly have control over the quality of my finished work."
        },
        {
            "number": 11,
            "status": "Waiting",
            "label": "I believe that the more I use my creativity, the more creativity I will have."
        },
        {
            "number": 12,
            "status": "Waiting",
            "label": "I am patient with myself and give myself time to develop ideas fully."
        },
        {
            "number": 13,
            "status": "Waiting",
            "label": "I am able to clear my mind and focus on my work without too much trouble."
        },
        {
            "number": 14,
            "status": "Waiting",
            "label": "I can browse and enjoy the creative work of others without feeling inferior."
        },
        {
            "number": 15,
            "status": "Waiting",
            "label": "I enjoy the act of creating, even when the final product isn’t what I hoped for."
        },
        {
            "number": 16,
            "status": "Waiting",
            "label": "I’m able to focus long enough each day to finish the creative work that I commit to."
        }
    ]
};