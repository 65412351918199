import React, { useContext, useEffect, useState } from 'react';
import './ThoughtPostRating.scss';

import GridLoader from "react-spinners/GridLoader";
import ContentLoader from 'react-content-loader';
import SuggestionsSheet from './SuggestionsSheet';
import AiMessage from './AiMessage';
import Back from '../helpers/Back';
import EmotionMeter from './EmotionMeter';
import ReframingList from './ReframingList';
import ThoughtSetContent from './ThoughtSetContent';
import AnimationContext from '../AnimationContext';
import WizardPop from './WizardPop';
import UserContext from '../UserContext';
import UI from './UI';

function ThoughtPostRating({ thought, slam, onCompleted, sessionThoughts, preRating, reframingOptions, isReframingLoading, onReframingRefreshClick, onAnimatingCompletedThought }) {

    const { user, changeUser } = useContext(UserContext);
    const [sessionThought, setSessionThought] = useState(null);

    const [reframingThought, setReframingThought] = useState(null);
    const [reframingArea, setReframingArea] = useState(null);
    const [matchingThoughtSet, setMatchingThoughtSet] = useState(null);

    const [newRating, setNewRating] = useState(null);
    const [reframingRating, setReframingRating] = useState(null);

    const [showMeters, setShowMeters] = useState(false);
    const [showPickingContent, setShowPickingContent] = useState(true);

    const [sheetContent, setSheetContent] = useState("Meters");

    const [isPop1Open, setIsPop1Open] = useState(false);
    const [isPop2Open, setIsPop2Open] = useState(false);

    const [hideContent, setHideContent] = useState(true);
    const [improvementNumber, setImprovementNumber] = useState(null);

    const animations = useContext(AnimationContext);

    useEffect(() => {

        console.log('ThoughtPostRating useEffect', thought);

        if (thought.type === "Negative" || thought.status === "Exploring") {
        } else {
            setReframingThought(thought.reframing_thought);
            setReframingArea(UI.extractWordInBrackets(thought.reframing_thought)); // DEBT: Need to read from thought.area directly instead to avoid confusion?
            setShowMeters(true);
            setShowPickingContent(false);
        }

        if (thought.type === "Negative" || thought.status === "Exploring") {
            setTimeout(() => {
                setHideContent(false);
            }, 1000);
        } else {
            setTimeout(() => {
                setHideContent(false);
            }, 100);
        }

        if (thought.type === "Negative" || thought.status === "Exploring") {
            if (user.isNew && user.popRound === 1) {
                setTimeout(() => {
                    //setIsPop1Open(true);
                }, 2000);
            }
        }

        // setTimeout(() => {
        //     onAnimatingCompletedThought();
        // }, 1000);


    }, []);

    const onReframingOptionSelected = (option, match) => {
        setReframingThought(option);
        setReframingArea(UI.extractWordInBrackets(option));
        setMatchingThoughtSet(match);
        //setShowMeters(true);
        //setShowPickingContent(false);

        onCompleted(option);

        // setTimeout(() => {
        //     setIsPop2Open(user.isNew && user.popRound === 1);
        // }, 1000);
    };

    async function delay(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const completeThought = async (gauge) => {
        Back.recordEvent('CompletedThought');

        const isReinforce = thought.type === "Reinforce";

        if (reframingArea) {
            thought.area = reframingArea;
        }

        if (matchingThoughtSet) {
            thought.progress = matchingThoughtSet.progress;
        }

        if (!thought.progress) {
            thought.progress = 0;
        }

        const bonusAmount = getRandomIntegerBetween(10, 18);
        thought.original_progress = thought.progress;
        thought.progress_amount = bonusAmount;
        thought.progress += bonusAmount;
        if (thought.progress > 100) {
            thought.progress = 100;
        }

        if (!isReinforce) {
            fadeSheetContent();
        } else {
            shrinkSheet();
            setTimeout(() => {
                flingSheet();
            }, 600);
        }

        const newThought = { ...thought };
        newThought.slam_id = slam.id;
        newThought.pre_rating = preRating;
        newThought.rating = gauge;
        newThought.reframing_thought = reframingThought;
        newThought.reframing_rating = reframingRating;
        newThought.matching_thought_set_id = matchingThoughtSet ? matchingThoughtSet.id : null;
        newThought.is_neutral = thought.isNeutral;

        if (thought.thought_set_id) {
            newThought.thought_set_id = thought.thought_set_id;
        }
        else if (thought.thought_set && thought.thought_set.id) {
            newThought.thought_set_id = thought.thought_set.id;
        }

        console.log('recordThought', newThought, thought);

        const response = Back.recordCompletedThought(newThought);
        const wait = await delay(800);

        //newThought.context = response.completed_thought.context;
        //newThought.primary_skill = response.completed_thought.primary_skill;

        const currentSessionSlotNumber = sessionThoughts.find(i => i.status === "Active") ? sessionThoughts.find(i => i.status === "Active").number : 1;

        // We have to do a switch-eroo here.
        // We send the new progress to the BE above, 
        // but then we set it back so the FE can 
        // animate the bar increase in a second.
        // DEBT: Not ideal, BE should be calculating change then sending it back for FE.
        newThought.progress = thought.original_progress;

        const completedSlot = {
            id: currentSessionSlotNumber, number: currentSessionSlotNumber, status: "Completed", thought: { ...newThought }, primary_skill: newThought.primary_skill
        };

        if (newThought.type === "Reinforce") {
            completedSlot.slide_in = true;
        }

        if (newThought.status === "Completed") {
            let credits = user.context.reassessmentCredits;
            if (!credits) {
                credits = 0;
            }
            credits += 1;
            user.context.reassessmentCredits = credits;
            changeUser({ ...user });
        }

        completedSlot.is_hidden = false;
        completedSlot.hide_check = true;

        completedSlot.isNeutral = thought.isNeutral;

        setSessionThought(completedSlot);

        let completeDelay = 2100;
        if (isReinforce) {
            completeDelay = 2600; // Being overwritten below right now.
        }

        if (!isReinforce) {
            setTimeout(() => {
                animateSheetContent();
                animations.addPageEffect("FadeOutSessionGrid");
            }, 50);

            setTimeout(() => {
                animations.addPageEffect("ShowMySessionTitle");
                animations.removePageEffect("FadeOutSessionGrid");
            }, 550);

            setTimeout(() => {
                animateCardToLeft();
            }, 650 + 250);

            setTimeout(() => {
                onCompleted(completedSlot);
            }, completeDelay);

            return;
        }

        // Reinforce Card animations only from here on...

        //animations.addPageEffect("HoldNegativeGrid");

        console.log('set up Post timeouts', getCurrentTime());


        setTimeout(() => {
            fadeOutView();
        }, 600); // Was 800

        setTimeout(() => {
            console.log('start Post timeouts', getCurrentTime());
            slideAwayReinforcementCard();
        }, 800); // Was 800

        setTimeout(() => {
            console.log('call onCompleted!', getCurrentTime());
            onCompleted(completedSlot);
        }, 850);

        setTimeout(() => {
            console.log('remove Post pageEffects', getCurrentTime());
            fadeInSessionGrid(completedSlot); // Only fades in "My Session"!!
            animations.removePageEffect("FadeOutSessionGrid");
            animations.removePageEffect("FadeOutEntireView");
        }, 1800);
    };

    const handleMeterClick = async (gauge) => {
        Back.recordEvent("ClickedPostMeter");
        console.log('zxv handleMeterClick', thought);
        setNewRating(gauge);

        const improvement = preRating - gauge;
        setImprovementNumber(improvement);

        let completedDelay = 300;

        if (improvement > 5) {
            document.querySelector('.ImprovementMessage').classList.remove('Hidden');
            setTimeout(() => {
                document.querySelector('.ImprovementMessage').classList.add('Bounce');
            }, 400);

            completedDelay += 900;
        }

        // console.log('zxv complete check', reframingRating, thought.type === "Reinforce");
        // if (thought.type === "Reinforce" && reframingRating) {
        //     setTimeout(() => {
        //         completeThought(gauge);
        //     }, completedDelay);
        // }
        // if (thought.type === "Negative" && reframingRating) {
        //     setTimeout(() => {
        //         completeThought(gauge);
        //     }, completedDelay);
        // }

        console.log('negative check', thought.isNeutral, isReframingType, reframingRating);
        if (!isReframingType || thought.isNeutral) {
            if (reframingRating) {
                setTimeout(() => {
                    completeThought();
                }, completedDelay);
            }
        }
    };

    const fadeSheetContent = () => {
        document.querySelector('.reframe-meter-container').classList.add('Fade');

        setTimeout(() => {
            document.querySelector('.reframe-sheet').classList.add('SessionCardSize');
        }, 350);
    };

    const animateSheetContent = () => {

        setTimeout(() => {
            setSheetContent("SessionCard");
        }, 10);

        setTimeout(() => {
            document.querySelector('.ThisSessionThought').classList.add('Show');
        }, 300);

        setTimeout(() => {
            onAnimatingCompletedThought();
        }, 100);
    };

    const shrinkSheet = () => {
        document.querySelector('.reframe-sheet').classList.add('ReinforceCardShrink');
    };

    const flingSheet = () => {
        document.querySelector('.reframe-sheet').classList.add('ReinforceCardFling');

        setTimeout(() => {
            document.querySelector('.reframe-sheet').classList.add('ReinforceCardFade');
        }, 450);
    };

    const fadeReinforceCard = () => {
        return;
        const cardEl = document.querySelector('.SessionThoughtExpanded');

        const currentTopNumber = parseInt(cardEl.style.top);
        const newTopNumber = currentTopNumber + 60;

        cardEl.opacity = 0;
        cardEl.style.top = newTopNumber;
        cardEl.style.transition = ".3s";
    };

    const switchReinforceCardContent = () => {
        const el = document.querySelector('.ThoughtSetContent.IsIncomplete .thought-check.Pending')
        el.classList.remove('Pending');
        el.classList.add('Completed');
    };

    const slideAwayReinforcementCard = () => {
        console.log('slideAway', getCurrentTime());
        const el = document.querySelector('.ReinforceInProgress');
        console.log('slideAway', el);
        el.classList.add('SlideAway');
        el.style.top = "150px";
        el.opacity = 0;
        el.style.opacity = 0;

        setTimeout(() => {
            el.remove();
        }, 2000);
    };

    const fadeOutView = () => {
        console.log('fadeOutView', getCurrentTime());
        animations.addPageEffect("FadeOutEntireView");
    };

    const fadeInSessionGrid = (completedSlot) => {
        console.log('fadeInSessionGrid & onAnimating', getCurrentTime());
        onAnimatingCompletedThought(completedSlot);
        animations.addPageEffect("ShowMySessionTitle");
    };

    const handleReframingMeterClick = async (gauge) => {
        Back.recordEvent("ClickedReframingMeter");
        setReframingRating(gauge);

        if (isReframingType) {
            setNewRating(gauge);
        }

        if (isReframingType || thought.isNeutral) {
            setTimeout(() => {
                completeThought();
            }, 600);
        }
        if (!isReframingType) {
            if (newRating) {
                setTimeout(() => {
                    completeThought();
                }, 800);
            }
        }

        //completeThought(gauge);
    };

    const animateCardToLeft = () => {
        const el = document.querySelector('.ThisSessionThought');
        const destEl = document.querySelector('.drop-dest');

        const elRect = el.getBoundingClientRect();
        const destinationRect = destEl.getBoundingClientRect();

        const newEl = el.cloneNode(true);
        newEl.id = newEl.id + '-clone';
        newEl.style.position = "fixed";
        newEl.style.top = elRect.top + 12 + 'px';
        newEl.style.left = elRect.left + 18.5 + 'px';
        el.after(newEl);

        el.remove();

        const activeSlot = sessionThoughts.find(i => i.status === "Active");
        const currentSessionSlotNumber = activeSlot ? activeSlot.number : 1;

        let slotTop = 0;
        if (currentSessionSlotNumber === 1) {
            slotTop += -1.5;
        }
        if (currentSessionSlotNumber === 2) {
            slotTop += 161; // was 160.5
        }
        if (currentSessionSlotNumber === 3) {
            slotTop += 322;
        }

        //return;

        setTimeout(() => {
            newEl.style.transform = "scale(1.2)";
            newEl.style.zIndex = "2000"; // So it flys over exiting SessionGrid cards temporarily.
        }, 20);

        setTimeout(() => {
            newEl.style.top = destinationRect.top + 62 + slotTop + 'px';
            newEl.style.left = destinationRect.left + 20 + 'px';
        }, 350);

        setTimeout(() => {
            newEl.classList.add("ThisSessionThoughtFlat");
            newEl.style.transform = null;
            newEl.style.zIndex = null;
        }, 800);
    };

    const isReframingType = thought.type === "Reinforce";

    let className = "ThoughtPostRating";

    if (hideContent) {
        className += " HideContent";
    }

    if (thought.type === "Reinforce") {
        className += " IsReinforce";
    }
    if (thought.isNeutral) {
        className += " IsNeutral";
    }

    return (
        <div className={className}>

            {isPop1Open && <WizardPop name={'reframing'} positions={['right']} shift={[-626, 120]} isOpen={isPop1Open} onClose={() => { setIsPop1Open(false) }}></WizardPop>}
            {isPop2Open && <WizardPop name={'post-rating'} positions={['left']} shift={[-10, 160]} isOpen={isPop2Open} onClose={() => { setIsPop2Open(false) }}></WizardPop>}

            {/* <div className='debug'>
                {JSON.stringify(reframingOptions)} | {JSON.stringify(isReframingLoading)}
            </div> */}

            <div className={`picking-content ${showPickingContent ? 'Show' : 'Hidden'}`}>
                <div className='post-sheet-heading post-sheet-heading-reframing'>
                    <div className='category-heading category-heading-reframe'>Personal Statement</div>
                    <div className='clearfix'></div>
                </div>

                <div className='post-sheet Expanded'>
                    <ReframingList data={reframingOptions} onOptionSelected={onReframingOptionSelected} isLoading={isReframingLoading}></ReframingList>
                    <div className='clearfix'></div>
                    <div className='toolbar ReframingRefreshToolbar' style={{ opacity: isReframingLoading ? '0' : '1' }}>
                        <div className='refresh-container2'>
                            <div className='RefreshButton' onClick={onReframingRefreshClick}>
                                <div className='refresh-fill'>
                                    <img src="refresh.png" className='refresh-icon-img' />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className='clearfix'></div>

            <div className={`belief-content ${showMeters ? 'Show' : 'Hidden'}`}>

                <div className='sheet-heading sheet-heading-rerate'>
                    <div className='category-heading'>Personal Statement</div>
                    <div className='clearfix'></div>
                </div>

                {sheetContent === "Meters" && (
                    <div className='reframe-sheet'>
                        <div className='reframe-meter-container' id='reframeMeterContainer'>


                            <div className='meter-thought-heading meter-thought-heading-new'>
                                {<img src="checkmark-gold2.png" className='checkmark-img' />}
                                <div className='label'>{UI.removeBracketedWord(reframingThought)}</div>
                                {reframingArea && (<div className='area-icon'><img src={`skill-${reframingArea.toLowerCase()}.png`} className={`area-img area-${reframingArea}`} /></div>)}
                            </div>
                            <EmotionMeter onCompleted={handleReframingMeterClick} thought={{ thought: reframingThought, type: "Reframing" }} isFirst={false} fillColor={"Orange"} titleIcon={"Check"}></EmotionMeter>

                            {thought.type !== "Reinforce" && !thought.isNeutral && (
                                <React.Fragment>
                                    <div className='reframe-thought-content'>
                                        <div className='meter-thought-heading meter-thought-heading-original'>
                                            <img src="x-icon.png" className='checkmark-img' />
                                            <div className='label'>{thought.thought}</div>

                                        </div>
                                        <EmotionMeter onCompleted={handleMeterClick} thought={thought} isFirst={false} fillColor={"Purple"} titleIcon={"X"}></EmotionMeter>
                                    </div>

                                    <div className='ImprovementMessage Hidden'>
                                        <div className='improve-bubble'>{improvementNumber}% Improvement</div>
                                    </div>
                                </React.Fragment>
                            )}

                        </div>
                    </div>
                )}

                {sheetContent === "SessionCard" && sessionThought && (
                    <div className={`SessionThought SessionThoughtFlat SessionThoughtCompleted ThisSessionThought`}>
                        <ThoughtSetContent slot={sessionThought} isGrid={false} hideNumber={true}></ThoughtSetContent>
                    </div>
                )}

            </div>

        </div >
    );


}

export default ThoughtPostRating;

function getRandomIntegerBetween(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

function getCurrentTime() {
    const now = new Date();
    let hours = now.getHours();
    let minutes = now.getMinutes();
    let seconds = now.getSeconds();

    // Add leading zeros for single-digit values:
    if (hours < 10) {
        hours = "0" + hours;
    }
    if (minutes < 10) {
        minutes = "0" + minutes;
    }

    return hours + ":" + minutes + ':' + seconds;
}