import React, { useContext, useEffect, useRef, useState } from 'react';
import Back from '../helpers/Back';
import UserContext from '../UserContext';
import './ResponseGrid.scss';
import ResponseLoadingGrid from './ResponseLoadingGrid';
import UI from './UI';
import WizardPop from './WizardPop';



function ResponseGrid({ options, responseStage, isLoading, isFakeLoading, thought, voice, onResponseAdded, onRefreshRequested, onStageCompleted, onVoiceChanged }) {

    const { user, changeUser, handlePop } = useContext(UserContext);

    const [selectedOptions, setSelectedOptions] = useState([]);

    const [showDoneButton, setShowDoneButton] = useState(false);

    const [isPop1Open, setIsPop1Open] = useState(false);
    const [isPop2Open, setIsPop2Open] = useState(false);

    const [isPopAcknowledgeOpen, setIsPopAcknowledgeOpen] = useState(false);
    const [isPopReframeOpen, setIsPopReframeOpen] = useState(false);
    const [isPopMotivateOpen, setIsPopMotivateOpen] = useState(false);
    const [isPopManifestOpen, setIsPopManifestOpen] = useState(false);

    const [delaySecondPop, setDelaySecondPop] = useState(false);

    const [stageResponseCount, setStageResponseCount] = useState(0);

    const fillingOption = useRef();

    const minimumResponseCount = 5;

    useEffect(() => {

        if (user.pendingPops.includes("startResponses")) {
            setDelaySecondPop(true);
            handlePop("startResponses", setIsPop1Open);
            return;
        }

    }, []);

    useEffect(() => {
        console.log('wer watch responseStage', responseStage);
        if (responseStage === "Reframe") {
            handlePop("reframeStage", setIsPopReframeOpen);
        }
        if (responseStage === "Manifest") {
            handlePop("manifestStage", setIsPopManifestOpen);
        }
    }, [responseStage]);

    const handleFollowPops = () => {
        console.log('wer handleFollowPops');
        console.log('werhandle what', responseStage);
        if (responseStage === "Acknowledge") {
            console.log('handle acknowledge', user.pendingPops);
            handlePop("acknowledgeStage", setIsPopAcknowledgeOpen);
        }
        if (responseStage === "Reframe") {
            handlePop("reframeStage", setIsPopReframeOpen);
        }
        if (responseStage === "Motivate") {
            handlePop("motivateStage", setIsPopMotivateOpen);
        }
        if (responseStage === "Manifest") {
            handlePop("manifestStage", setIsPopManifestOpen);
        }
    };

    const flingCard = (option, index, isStrongPress) => {
        let optionId = 'response-option-' + index;
        const el = document.getElementById(optionId);

        const elRect = el.getBoundingClientRect();
        const destinationRect = document.getElementById('responsesDropContainer').getBoundingClientRect();

        const newEl = el.cloneNode(true);
        newEl.id = newEl.id + '-clone';
        newEl.style.position = "fixed";
        newEl.style.top = elRect.top - 4.5 + 'px';
        newEl.style.left = elRect.left + 13.5 + 'px';
        newEl.style.width = "280px";
        newEl.style.transform = "scale(1.1)";
        newEl.style.boxShadow = "1px 2px 3px 1px #e3e6f7";
        newEl.style.zIndex = 5000 + "";
        document.getElementById('cardClonesContainer').appendChild(newEl);

        el.classList.add("Selected");
        newEl.style.zIndex = 6000;

        // top: 376.106px; left: 612.662px;
        // top: 116.106px; left: -5.662px;
        newEl.style.top = elRect.top - 4.5 + 'px';
        newEl.style.left = elRect.left + 13.5 + 'px';

        //return;

        if (isStrongPress) {
            el.classList.add("IsStrongPress");
        }

        setTimeout(() => {
            newEl.style.transform = "scale(1.2)";
        }, 10);

        setTimeout(() => {
            newEl.style.top = destinationRect.top + 0 + 'px';
            newEl.style.left = destinationRect.left + 20 + 'px';
            newEl.style.transform = "scale(0.9)";
            newEl.style.boxShadow = "none";
        }, 130);

        setTimeout(() => {
            newEl.style.boxShadow = "none";
            newEl.style.transform = "scale(0.75)";
        }, 400);

        setTimeout(() => {
            newEl.style.top = destinationRect.top - 0 + 'px';
            newEl.style.opacity = "0";
        }, 600);
    };

    const handleDoneClicked = () => {
        setStageResponseCount(0);
        setShowDoneButton(false);
        onStageCompleted();
    };

    const handleOptionClick = (option, index, isStrongPress) => {
        Back.recordEvent("ClickedResponse", option);

        if (selectedOptions.indexOf(option) !== -1) {
            return;
        }

        setSelectedOptions(prev => {
            return [...prev, option];
        });
        setStageResponseCount(prev => {
            return prev += 1;
        });
        console.log('stageResponseCount', stageResponseCount);

        if (stageResponseCount >= minimumResponseCount - 1) {
            setShowDoneButton(true);
        }

        flingCard(option, index, isStrongPress);

        setTimeout(() => {
            onResponseAdded(option, index, isStrongPress);
        }, 200);
    };

    const handleVoiceClicked = (voice) => {
        //setSelectedVoice(voice);
        onVoiceChanged(voice);
    };

    const handleOptionPress = (option, index) => {
        return;
        fillingOption.current = index;
        setTimeout(() => {
            if (fillingOption.current) {
                return;
            }
            let optionId = 'response-option-' + fillingOption.current;
            let cardEl = document.getElementById(optionId);
            let fillEl = cardEl.querySelector('.filler-fill');
            cardEl.classList.add('Filling');
        }, 50);

        setTimeout(() => {
            if (!fillingOption.current) {
                return;
            }
            let optionId = 'response-option-' + fillingOption.current;
            let cardEl = document.getElementById(optionId);
            let fillEl = cardEl.querySelector('.filler-fill');
            cardEl.classList.remove('Filling');

            handleOptionClick(option, index, true);
        }, 700);
    };

    const handleOptionOff = (option, index) => {
        return;
        let optionId = 'response-option-' + fillingOption.current;
        let cardEl = document.getElementById(optionId);
        cardEl.classList.remove('Filling');
        fillingOption.current = null;
    };


    const voiceOptions = ["Explore", "Become", "Act", "Think big",];
    //const [selectedVoice, setSelectedVoice] = useState(voiceOptions[(Math.floor(Math.random() * voiceOptions.length))]);

    let className = "ResponseGrid";

    if (showDoneButton) {
        className += " ShowDoneButton";
    } else {
        className += " HideDoneButton";
    }

    if (isLoading || isFakeLoading) {
        return (
            <div className={className}>

                <div className='sheet-heading'>
                    <div className='category-heading'>Responses</div>
                </div>

                <div className='grid-layout'>
                    <div className='sheet'>

                        <div className='respond-heading'>&nbsp;</div>

                        <div className='sheet-grid'>

                            <div className='options LoadingOptions'>
                                <ResponseLoadingGrid count={8}></ResponseLoadingGrid>
                            </div>

                            <div className='voice-options-container'>

                                <div className='RefreshButton' onClick={onRefreshRequested}>
                                    <div className='refresh-fill'>
                                        <img src="refresh-yellow.png" className='refresh-icon-img' />
                                    </div>
                                </div>

                                <div className='DoneButton' onClick={handleDoneClicked}>
                                    <div className='refresh-fill'>
                                        <img src="checkmark-yellow.png" className='refresh-icon-img' />
                                    </div>
                                </div>



                            </div>

                        </div>

                    </div>

                </div>


            </div>
        );
    }

    return (
        <div className={className}>

            {isPop1Open && <WizardPop name={'respond-1'} positions={['left']} shift={[-12, 192]} isOpen={isPop1Open} onClose={() => { setIsPop1Open(false); setTimeout(() => handleFollowPops(), 100) }}></WizardPop>}
            {isPopAcknowledgeOpen && <WizardPop name={'respond-acknowledge'} positions={['left']} shift={[-12, 192]} isOpen={isPopAcknowledgeOpen} onClose={() => { setIsPopAcknowledgeOpen(false) }}></WizardPop>}
            {isPopReframeOpen && <WizardPop name={'respond-reframe'} positions={['left']} shift={[-12, 192]} isOpen={isPopReframeOpen} onClose={() => { setIsPopReframeOpen(false) }}></WizardPop>}
            {isPopMotivateOpen && <WizardPop name={'respond-motivate'} positions={['left']} shift={[-12, 192]} isOpen={isPopMotivateOpen} onClose={() => { setIsPopMotivateOpen(false) }}></WizardPop>}
            {isPopManifestOpen && <WizardPop name={'respond-manifest'} positions={['left']} shift={[-12, 192]} isOpen={isPopManifestOpen} onClose={() => { setIsPopManifestOpen(false) }}></WizardPop>}

            <div className='sheet-heading'>
                <div className='category-heading'>Responses</div>
            </div>



            <div className='grid-layout'>
                <div className='sheet'>

                    <div className='respond-heading'>&nbsp;</div>

                    <div className='sheet-grid'>



                        <div className='options'>



                            {options.map((option, index) => {
                                let className = 'option ';
                                const cardId = 'response-option-' + index;

                                let areaName = UI.extractWordInBrackets(option);
                                const splicedLabel = UI.removeBracketedWord(option);

                                const areaOptions = ["Mood", "Motivation", "Focus", "Ease", "Purpose", "Openness"];
                                if (areaName === "Possibility") {
                                    areaName = "Openness";
                                }
                                if (!areaOptions.includes(areaName)) {
                                    areaName = "Ease";
                                }

                                return (
                                    <div className={className} id={cardId} onMouseUp={() => handleOptionOff(option, index)} onMouseDown={() => handleOptionPress(option, index)} onClick={() => handleOptionClick(option, index)} key={cardId}>

                                        <div className='option-positioning'>
                                            <div className='filler'>
                                                <div className='filler-flex'>
                                                    <div className='filler-fill'></div>
                                                </div>
                                            </div>
                                            <div className='label'>
                                                {splicedLabel}
                                            </div>
                                            {/* <img src={`skill-${areaName.toLowerCase()}.png`} className='plant-img icon-img' /> */}
                                            <div className='label-good-find'>
                                                Good Find!
                                            </div>
                                        </div>

                                    </div>
                                )
                            })}
                            <div className='clearfix'></div>



                        </div>

                        <div className='voice-options-container'>

                            <div className='RefreshButton' onClick={onRefreshRequested}>
                                <div className='refresh-fill'>
                                    <img src="refresh-yellow.png" className='refresh-icon-img' />
                                </div>
                            </div>

                            <div className='DoneButton' onClick={handleDoneClicked}>
                                <div className='refresh-fill'>
                                    <img src="checkmark-yellow.png" className='refresh-icon-img' />
                                </div>
                            </div>



                        </div>

                    </div>

                </div>

            </div>
        </div >
    );
}

export default ResponseGrid;