import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import Back from '../helpers/Back';
import User from '../helpers/User';
import LogThought from './LogThought';
import './WelcomeScreen.scss';

import GridLoader from "react-spinners/GridLoader";
import ContentLoader from 'react-content-loader';
import EmotionQuestion from './EmotionQuestion';
import EmotionsGradeSheet from './EmotionsGradeSheet';
import EmotionsCompletedSheet from './EmotionsCompletedSheet';
import { ClipLoader } from 'react-spinners';
import AiMessage from './AiMessage';

function WelcomeScreen({ onCompleted }) {

    const [showLoadingText, setShowLoadingText] = useState(false);
    const [loadingText, setLoadingText] = useState("Settle in");
    const [showLoadingScreen, setShowLoadingScreen] = useState(true);

    const [greetingContent, setGreetingContent] = useState("Test");
    const [showGreeting, setShowGreeting] = useState(false);
    const [animateOut, setAnimateOut] = useState(false);

    useEffect(() => {

        const timeToShowTexts = 100;
        const timeToShowChat = 1450;
        const timeAfterChat = 1200;

        // setTimeout(() => {
        //     setShowLoadingText(true);
        //     setLoadingText("Settle in");
        // }, 1000);
        // setTimeout(() => {
        //     setShowLoadingText(false);
        // }, 1000 + timeToShowTexts);

        // setTimeout(() => {
        //     setShowLoadingText(true);
        //     setLoadingText("Get comfy");
        // }, 1000 + timeToShowTexts * 2);
        // setTimeout(() => {
        //     setShowLoadingText(false);
        // }, 1000 + timeToShowTexts * 3);

        // setTimeout(() => {
        //     setShowLoadingText(true);
        //     setLoadingText("Let's begin");
        // }, 1000 + timeToShowTexts * 4);
        // setTimeout(() => {
        //     setShowLoadingText(false);
        // }, 1000 + timeToShowTexts * 5);



        //const checkpoint = 1000 + timeToShowTexts * 6;
        let checkpoint = 1000;



        setTimeout(() => {
            setShowLoadingScreen(false);
        }, checkpoint);

        checkpoint = 3800;

        setTimeout(() => {
            setGreetingContent(<div className='first-greeting'>Hi, I'm Air!<br /></div>);
            setShowGreeting(true);
        }, checkpoint + 1000);



        setTimeout(() => {
            setShowGreeting(false);
        }, checkpoint + 1000 + timeToShowChat);

        setTimeout(() => {
            setGreetingContent(<div>I'm a trained AI<br />with one purpose &mdash;</div>);
            setShowGreeting(true);
        }, checkpoint + 1000 + timeToShowChat + timeAfterChat);

        setTimeout(() => {
            setShowGreeting(false);
        }, checkpoint + 1000 + timeToShowChat + timeAfterChat + timeToShowChat);

        setTimeout(() => {
            setGreetingContent(<div>To boost your mood<br />in 3 minutes!</div>);
            setShowGreeting(true);
        }, checkpoint + 1000 + timeToShowChat + timeAfterChat + timeToShowChat + timeAfterChat);

        setTimeout(() => {
            setShowGreeting(false);
        }, checkpoint + 1000 + timeToShowChat + timeAfterChat + timeToShowChat + timeAfterChat + timeToShowChat);

        setTimeout(() => {
            setGreetingContent(<div style={{ paddingTop: "14px" }}>So let's get into it!</div>);
            setShowGreeting(true);
        }, checkpoint + 1000 + timeToShowChat + timeAfterChat + timeToShowChat + timeAfterChat + timeToShowChat + timeAfterChat);

        setTimeout(() => {
            setShowGreeting(false);
        }, checkpoint + 1000 + timeToShowChat + timeAfterChat + timeToShowChat + timeAfterChat + timeToShowChat + timeAfterChat + timeToShowChat);

        setTimeout(() => {
            setAnimateOut(true);
        }, checkpoint + 1000 + timeToShowChat + timeAfterChat + timeToShowChat + timeAfterChat + timeToShowChat + timeAfterChat + timeToShowChat + timeAfterChat);

        setTimeout(() => {
            onCompleted();
        }, checkpoint + 1000 + timeToShowChat + timeAfterChat + timeToShowChat + timeAfterChat + timeToShowChat + timeAfterChat + timeToShowChat + 2100);

    }, []);

    let className = "WelcomeScreen";

    if (showLoadingText) {
        className += " ShowLoadingText";
    }
    if (showLoadingScreen) {
        className += " ShowLoadingScreen";
    } else {
        className += " ShowJuliaScreen";
    }
    if (animateOut) {
        className += " AnimateOut";
    }

    if (showGreeting) {
        className += " ShowGreeting";
    }

    return (
        <div className={className}>

            <div className='sidebar'>
                <div className='logo'>
                    <img src="air-logo2.png" className='logo-img' />
                </div>


            </div>

            <div className='main-layout'>

                {/* <div className='left-col'>
                    <div className='content'>
                        <div className='welcome-position'>
                            <div className='welcome-text'>
                                {loadingText}
                            </div>
                        </div>
                        <img src="welcome-chair.svg" className='chair-img' />
                    </div>

                </div> */}

                <div className='right-col'>

                    <div className='content'>
                        <div className='name-text'>
                            Welcome to
                        </div>
                        <div className='tag-text'>
                            Three minute therapy
                        </div>
                        <div className='julia-text'>
                            with Julia
                        </div>
                    </div>
                </div>


            </div>

            <div className='JuliaScreen'>
                <div className='julia-face'>
                    <img src="face-top-light.png" className='face-img face-top-img' />
                    <img src="face-bottom-light.png" className='face-img face-bottom-img' />
                </div>
                <div className='GreetingContent'>
                    {greetingContent}
                </div>
            </div>

            <img src="ai-wave.svg" className='wave-img' />


        </div>
    );


}

export default WelcomeScreen;
