import React, { useContext, useEffect, useState } from 'react';
import './ThoughtPreRating.scss';

import GridLoader from "react-spinners/GridLoader";
import ContentLoader from 'react-content-loader';
import SuggestionsSheet from './SuggestionsSheet';
import AiMessage from './AiMessage';
import Back from '../helpers/Back';
import EmotionMeter from './EmotionMeter';
import WizardPop from './WizardPop';
import UserContext from '../UserContext';

function ThoughtPreRating({ thought, sessionThoughts, onCompleted }) {

    const { user } = useContext(UserContext);

    const [showSheet, setShowSheet] = useState(false);
    const [showCheck, setShowCheck] = useState(false);

    //const [isPopOpen, setIsPopOpen] = useState(user.isNew && user.popRound === 1);
    const [isPopOpen, setIsPopOpen] = useState(false);

    const handleMeterClick = (rating) => {
        setTimeout(() => {
            onCompleted(rating);
        }, 0);

        setShowCheck(true);

        setTimeout(() => {
            setShowSheet(false);
        }, 200);
    };

    useEffect(() => {
        setTimeout(() => {
            setShowSheet(true);
        }, 10);
    }, []);

    console.log('thought', thought);

    const isReframingType = thought.type === "Reinforce";

    let className = "ThoughtPreRating";
    let headingText = "How much do you believe this thought?";

    const currentSessionSlotNumber = sessionThoughts.find(i => i.status === "Active").number;

    className += " SessionSlot" + currentSessionSlotNumber;
    className += showSheet ? " Show" : " Hide";


    if (thought.isNeutral) {
        console.log('isNeutral');
        className += " IsNeutral";
        headingText = "How important is this topic to you?";
    }

    let meterColor = isReframingType ? 'Orange' : 'Purple';
    if (thought.isNeutral) {
        meterColor = "Blue";
    }


    return (
        <div className={className}>

            {true && <WizardPop name={'pre-rating'} positions={['bottom']} shift={[-298, 160]} isOpen={isPopOpen} onClose={() => { setIsPopOpen(false) }}></WizardPop>}

            <div className='sheet-heading'>
                &nbsp;
            </div>

            <div className='sheet Expanded'>
                <div className='sheet-heading-in'>
                    {headingText}
                </div>
                <div className='meter-container'>
                    <EmotionMeter onCompleted={handleMeterClick} isShort={false} thought={thought} isFirst={false} fillColor={meterColor} showCheck={showCheck}></EmotionMeter>
                </div>
            </div>

        </div >
    );


}

export default ThoughtPreRating;
