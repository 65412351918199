import React, { useContext } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import './AssessmentsPeek.scss';
import ThoughtSetContent from './ThoughtSetContent';
import StackCard from './StackCard';

import UserContext from '../UserContext';
import AssessmentCard from './AssessmentCard';
import WizardPop from './WizardPop';


function AssessmentsPeek({ onStartAssessment }) {

    const { user, changeUser, handlePop } = useContext(UserContext);

    let className = "AssessmentsPeek";

    const [activeAssessments, setActiveAssessments] = useState(user.negative_rows);

    const [isPop1Open, setIsPop1Open] = useState(false);
    const [isPop2Open, setIsPop2Open] = useState(false);

    console.log('wer activeAssessments', activeAssessments);

    const handleStartAssessmentClick = (assessment) => {
        console.log('handleStartAssessmentClick', assessment);
        onStartAssessment(assessment);
    };

    useEffect(() => {
        setActiveAssessments(user.active_assessments);

        handlePop("assessmentsPeek", setIsPop1Open);
    }, [user]);

    return (
        <div className={className}>


            <div className='assessment-credits'>
                You have <span className='credits-phrase'>{user.context.assessmentCredits} Assessment Credit{user.context.assessmentCredits === 1 ? '' : 's'}</span>.<br />You can use a credit by clicking <span className='start-assessment-phrase'>Start Assessment</span>.
            </div>

            <div className='assessment-cards'>
                {isPop1Open && <WizardPop name={'assessments-peek'} positions={['bottom']} shift={[387, -42]} isOpen={isPop1Open} onClose={() => { setIsPop1Open(false); setTimeout(() => setIsPop2Open(true), 200) }}></WizardPop>}
                {isPop2Open && <WizardPop name={'assessments-peek-start'} positions={['bottom']} shift={[347, -42]} isOpen={isPop2Open} onClose={() => { setIsPop2Open(false) }}></WizardPop>}
                {activeAssessments.map(assessment => <AssessmentCard assessmentRound={assessment} key={assessment.id} onStartAssessment={handleStartAssessmentClick}></AssessmentCard>)}
            </div>



        </div>
    );


}

export default AssessmentsPeek;