import React, { useContext } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import AnimationContext from '../AnimationContext';
import Back from '../helpers/Back';
import './ReframingList.scss';
import ReframingLoadingGrid from './ReframingLoadingGrid';
import UI from './UI';


function ReframingList({ data, isLoading, onOptionSelected }) {

    const animations = useContext(AnimationContext);

    const [isCompleted, setIsCompleted] = useState(false);
    const [isGrown, setIsGrown] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);

    const options = data.options;
    const matches = data.matches;

    let className = "ReframingList";

    if (isLoading) {
        className += ' IsLoading';
    }
    // if (isCompleted) {
    //     className += ' IsCompleted';
    // }

    const handleOptionClick = (option, matchingThoughtSet) => {
        setIsCompleted(true);
        setSelectedOption(option);

        document.querySelector('.ReframingRefreshToolbar').style.opacity = 0;

        // setTimeout(() => {
        //     setIsGrown(true);
        // }, 400);

        animations.addPageEffect("HidePersonalStatementHeading");
        setTimeout(() => {
            animations.removePageEffect("HidePersonalStatementHeading");
        }, 3000);

        setTimeout(() => {
            onOptionSelected(option, matchingThoughtSet);
        }, 1000);

        Back.recordEvent("ClickedReframing", option);
    };

    if (isLoading || !Array.isArray(options) || !options.length) {
        return (
            <ReframingLoadingGrid count={4}></ReframingLoadingGrid>
        );
    }

    //const [options, setOptions] = useState([ "My efforts are commendable", "I sometimes take a break", "I have the ability to persevere", "I can ask for help when needed", "I learn from my mistakes", "I am capable of overcoming challenges" ]);

    return (
        <div className={className}>
            {/* <div className='instructions'>
                Choose a new thought that reframes your thought
            </div> */}
            <div className='list'>
                {options.map(
                    (option) => {
                        let className = 'item';

                        const areaName = UI.extractWordInBrackets(option);
                        const sentence = UI.removeBracketedWord(option);

                        const matchingThoughtSet = getOptionMatch(option, matches);
                        const isMatch = !!matchingThoughtSet;

                        let areaClassName = "AreaTag AreaTag" + areaName;

                        if (matchingThoughtSet && !isCompleted) {
                            className += " IsMatch";
                            areaClassName += " IsMatch";
                        }

                        // if (option === selectedOption) {
                        //     className += ' IsSelected';

                        //     if (isGrown) {
                        //         className += " IsGrown";
                        //     }

                        //     return (

                        //         <div className={className} onClick={() => handleOptionClick(option)} key={option}>
                        //             <div className='meter-thought-heading meter-thought-heading-new'>
                        //                 {<img src="checkmark-gold2.png" className='checkmark-img' />}
                        //                 <div className='label'>{sentence}</div>
                        //                 <div className='area-icon'><img src={`skill-${areaName.toLowerCase()}.png`} className={`area-img area-${areaName.toLowerCase()}`} /></div>
                        //             </div>
                        //         </div>
                        //     )
                        // }

                        if (isMatch) {
                            return (
                                <div className={className} onClick={() => handleOptionClick(option, matchingThoughtSet)} key={option}>
                                    {isCompleted && <img src="checkmark-orange.png" className='checkmark-img' />}
                                    <div className='label'>{sentence}</div>
                                    <div className='match-tag-container'>
                                        <div className='match-tag'>Match</div>
                                    </div>
                                    <div className='progress-content'>
                                        <div className='stage-progressbar'>
                                            <div className='stage-fillbar' style={{ width: matchingThoughtSet.progress + "%" }}></div>
                                        </div>

                                        <div className={areaClassName}>
                                            <div className='area-icon'><img src={`skill-${areaName.toLowerCase()}.png`} className={`area-img area-${areaName.toLowerCase()}`} /></div>
                                            <div className='area-name'>{areaName}</div>
                                        </div>
                                    </div>

                                </div>
                            )
                        }

                        if (selectedOption && option !== selectedOption) {
                            className += " IsSelectedHidden";
                            return (
                                <div className={className}></div>
                            )
                        }

                        if (selectedOption && option === selectedOption) {
                            className += " IsSelectedHighlight";
                        }

                        return (
                            <div>
                                {option === selectedOption && <div className='completion-tag'>Looks good!</div>}
                                <div className={className} onClick={() => handleOptionClick(option)} key={option}>

                                    <div className='label'>{sentence}</div>
                                    <div className={areaClassName}>
                                        <div className='area-icon'><img src={`skill-${areaName.toLowerCase()}.png`} className={`area-img area-${areaName.toLowerCase()}`} /></div>
                                        <div className='area-name'>{areaName}</div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                )}
            </div>
            <div className='refresh-position'>
                <div className='refresh-button'></div>
            </div>
        </div>
    );


}

function getOptionMatch(option, matches) {
    return matches.find(m => m.reframing_thought === option);
}

export default ReframingList;