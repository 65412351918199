import React, { useContext, useEffect, useState } from 'react';
import './ExploreSheet.scss';

import GridLoader from "react-spinners/GridLoader";
import ContentLoader from 'react-content-loader';
import SuggestionsSheet from './SuggestionsSheet';
import AiMessage from './AiMessage';
import Back from '../helpers/Back';
import EmotionMeter from './EmotionMeter';
import ReframingList from './ReframingList';
import ThoughtSetContent from './ThoughtSetContent';
import AnimationContext from '../AnimationContext';
import WizardPop from './WizardPop';
import UserContext from '../UserContext';
import UI from './UI';
import ThoughtPostRating from './ThoughtPostRating';

function ExploreSheet({ exploreItems, thought, slam, onCompleted, sessionThoughts, preRating, reframingOptions, isReframingLoading, onReframingRefreshClick, onAnimatingCompletedThought }) {

    const { user, changeUser, handlePop } = useContext(UserContext);
    const animations = useContext(AnimationContext);

    const [placeholderItems, setPlaceholderItems] = useState([]);

    const [firstPageItems, setFirstPageItems] = useState([]);
    const [secondPageItems, setSecondPageItems] = useState([]);

    const [isPop1Open, setIsPop1Open] = useState(false);
    const [isPop2Open, setIsPop2Open] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            handlePop("explore1", setIsPop1Open);
        }, 1000);
    }, []);

    let className = "ExploreSheet";

    // let exploreItems = [
    //     { number: 1, status: "Empty", label: "Maybe I need to step out of my comfort zone" },
    //     { number: 2, status: "Empty", label: "Perhaps I am too focused on perfection rather than innovation" },
    //     { number: 3, status: "Empty" },
    //     { number: 4, status: "Empty" },
    //     { number: 5, status: "Empty" },
    //     { number: 6, status: "Empty" }
    // ];

    useEffect(() => {
        console.log('exploreEffect', exploreItems);
        const totalCount = thought.isReinforce ? 8 : 6;
        const items = [];
        for (var i = exploreItems.length + 1; i <= totalCount; i++) {
            items.push({ number: i, status: "Empty" });
        }
        setPlaceholderItems(items);

        const itemsPerPage = 4;
        const newFirstPageItems = [];
        const newSecondPageItems = [];
        for (var i = 0; i < itemsPerPage; i++) {
            if (exploreItems[i]) {
                newFirstPageItems.push(exploreItems[i]);
            } else {
                newFirstPageItems.push({ number: i + 1, isPlaceholder: true, status: "Empty" })
            }
        }
        for (var i = 0; i < itemsPerPage; i++) {
            if (exploreItems[i + itemsPerPage]) {
                newSecondPageItems.push(exploreItems[i + itemsPerPage]);
            } else {
                newSecondPageItems.push({ number: i + 1 + itemsPerPage, isPlaceholder: true, status: "Empty" })
            }
        }

        setFirstPageItems(newFirstPageItems);
        setSecondPageItems(newSecondPageItems);

    }, [exploreItems]);

    const handleReframingCompleted = (reframingThought) => {

        animations.addPageEffect("FadeOutExploreCompletedMessage");

        onCompleted(null, reframingThought);
    }

    return (
        <div className={className}>



            <div className='sheet-heading'>
                My Notes
            </div>



            <div class="notebook">

                <div className='page-heading-container'>
                    <div className='page-heading'>{thought.thought}</div>
                </div>

                <div class="left">
                    <div class="page-inner">

                        <div className='explore-list'>
                            {firstPageItems.map(item => {
                                let thisClassName = 'explore-item';
                                if (item.isFading) {
                                    thisClassName += " IsFading";
                                }

                                if (item.isPlaceholder) {
                                    return (
                                        <div className='explore-item' key={item.number}>
                                            <div className='number'>{item.number}</div>
                                            <div className='label'></div>
                                        </div>
                                    )
                                }

                                return (
                                    <div className={thisClassName} key={item.number}>
                                        <div className='number'>{item.number}</div>
                                        <div className='label'>{item.label}</div>
                                    </div>
                                )
                            })}
                        </div>

                    </div>
                </div>
                <div class="right">
                    <div class="page-inner">

                        <div className='explore-list'>
                            {secondPageItems.map(item => {
                                let thisClassName = 'explore-item';
                                if (item.isFading) {
                                    thisClassName += " IsFading";
                                }

                                if (item.isPlaceholder) {
                                    return (
                                        <div className='explore-item' key={item.number}>
                                            <div className='number'>{item.number}</div>
                                            <div className='label'></div>
                                        </div>
                                    )
                                }

                                return (
                                    <div className={thisClassName} key={item.number}>
                                        <div className='number'>{item.number}</div>
                                        <div className='label'>{item.label}</div>
                                    </div>
                                )
                            })}
                        </div>

                    </div>
                </div>
            </div>


            <div className='sheet'>
                <div className='sheet-binding'>
                    <div className='sheet-page-effect'>
                        <div className='explore-list'>
                            {exploreItems.map(item => {
                                let thisClassName = 'explore-item';
                                if (item.isFading) {
                                    thisClassName += " IsFading";
                                }
                                return (
                                    <div className={thisClassName} key={item.number}>
                                        <div className='number'>{item.number}</div>
                                        <div className='label'>{item.label}</div>
                                    </div>
                                )
                            })}
                            {placeholderItems.map(item => {
                                return (
                                    <div className='explore-item' key={item.number}>
                                        <div className='number'>{item.number}</div>
                                        <div className='label'></div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>

            {isPop1Open && <WizardPop name={'explore-1'} positions={['right']} shift={[-948, 332]} isOpen={isPop1Open} onClose={() => { setIsPop1Open(false); setTimeout(() => setIsPop2Open(true), 100) }}></WizardPop>}
            {isPop2Open && <WizardPop name={'explore-2'} positions={['right']} shift={[-534, 168]} isOpen={isPop2Open && false} onClose={() => { setIsPop2Open(false) }}></WizardPop>}

            <div className='post-thought-content'>
                <ThoughtPostRating thought={thought} slam={slam} onCompleted={handleReframingCompleted} reframingOptions={reframingOptions} isReframingLoading={isReframingLoading} sessionThoughts={sessionThoughts} preRating={preRating} onReframingRefreshClick={onReframingRefreshClick} onAnimatingCompletedThought={onAnimatingCompletedThought}></ThoughtPostRating>
            </div>

        </div>
    );


}

export default ExploreSheet;
