import { useRef, useState } from 'react';
import { Popover, ArrowContainer } from 'react-tiny-popover'

function WizardPop({ name, isOpen, onClose, shift, positions }) {

    const clickMeButtonRef = useRef();
    const [isPopoverOpen, setIsPopoverOpen] = useState(true);

    const placeholderStyle = {};

    if (shift) {
        placeholderStyle.position = 'relative';
        placeholderStyle.left = shift[0] + 'px';
        placeholderStyle.top = shift[1] + 'px';

        //placeholderStyle.transform = ""
    }

    if (!positions) {
        positions = ['right', 'bottom'];
    }
    //positions = ['right', 'bottom'];

    return (
        <div>
            {/* <div ref={clickMeButtonRef}>Click Me</div> */}
            <Popover
                isOpen={isOpen}
                positions={positions}
                padding={10}
                onClickOutside={() => {
                    onClose();
                }}
                ref={clickMeButtonRef} // if you'd like a ref to your popover's child, you can grab one here
                content={({ position, childRect, popoverRect }) => (
                    <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                        position={position}
                        childRect={childRect}
                        popoverRect={popoverRect}
                        arrowColor={'#ffffff'}
                        arrowSize={12}
                        arrowStyle={{ opacity: 1 }}
                        className='popover-arrow-container'
                        arrowClassName='popover-arrow'
                    >
                        <div
                            style={{ backgroundColor: '#ffffff', border: '0px solid #fff', borderRadius: 12, boxShadow: '1px 1px 4px 1px rgba(230, 219, 203, 0.7)' }}
                            onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                        >
                            <div className='WizardContent'>
                                <div className='ai-face'>
                                    <img src="face-top-light.png" className='face-img face-top-img' />
                                    <img src="face-bottom-light.png" className='face-img face-bottom-img' />
                                </div>
                                {content[name]}
                                <div className='next-button' onClick={() => onClose()}>
                                    Got it!
                                </div>
                            </div>
                        </div>
                    </ArrowContainer>
                )}
            >
                <div className='Wizard-placeholder' style={placeholderStyle}></div>
            </Popover>
        </div>
    )
}

const content = {
    'welcome-to-session': (
        <div className='intro'>
            <p><b>OK,</b> we're ready to start!</p>
            {/* <p>
                Completing a <em>Session</em> with me takes about <u>3 minutes</u>. Once you fill the 3 spots here, you’re done!
            </p> */}
            <p>
                I'll <b>suggest</b> <em>Thoughts</em> here that we can explore.
            </p>
            <p>
                As I learn more about you, these suggestions will change and deepen.
            </p>



            {/* LANDSLIDE */}
            {/* <p>
                I'll suggest <em>Thoughts</em> here that I can help you <b>Explore</b> and <em className='reinforceWord'>Challenge</em>.
            </p> */}
        </div>
    ),
    'welcome-to-session-2': (
        <div className='intro'>
            {/* LANDSLIDE */}
            {/* <p>
                I use <em>CBT</em> <i style={{ fontSize: "8.5pt" }}>Cognitive Behavioral Therapy</i> to help you do this.&nbsp;
                <em>CBT</em> is proven to improve
                mood, focus, and wellbeing simply by working with your thoughts.

            </p> */}
            <p>
                Thoughts under <b>Reframe</b> are negative thoughts that may
                be holding you back right now.
            </p>
            <p>By challenging and reframing these thoughts with me, you'll
                start to think more clearly and feel better!
            </p>
        </div>
    ),
    'welcome-to-session-2b': (
        <div className='intro'>
            <p>
                And thoughts under <b>Manifest</b> are positive thoughts that
                we can explore and strengthen.
            </p>
            <p>
                These are great for a motivating, empowering boost in an area of your life.
            </p>
        </div>
    ),
    'welcome-to-session-3': (
        <div className='intro'>
            <p>
                So now, click any <b>thought</b> here that <em>feels</em> at least a little <b>true</b> to you. Go with your gut here!
            </p>
            {/* <p>Don't worry if they feel a little negative for now.</p> */}
            {/* <p>
                These <em>Thoughts</em> may seem a little <b>over the top</b> in their negativity,
                but that's intentional. It helps me find the <em>Thoughts</em> that may be holding you back.
            </p>
            <p>
                And, it helps me suggest deeper thoughts
                in your mind as we work together more.
            </p>
            <p>
                So listen to your gut, and click a <em>Thought</em> that <u>feels true</u> at first glance.
            </p> */}
        </div>
    ),
    'mood-meter': (
        <div className='intro'>
            <p>As you find new thoughts that resonate with you, your <em>Mood Meter</em> will fill up.</p>
            <p>This gives you a good idea of how much of a boost you should feel from us working together each day.</p>
            <p>But your meter will empty over time, so keep an eye on it during your week!</p>
        </div>
    ),
    'donation-meter': (
        <div className='intro'>
            <p>Hey, you found a <em>Charity Contribution</em>!</p>
            <p>As you explore your thoughts, you'll find funds that you
                can donate to a charity of your choice.
            </p>
            <p>
                80% of all our profits are committed to charity.
            </p>
            <p>
                I'll show you how to choose your charity soon!
            </p>
        </div>
    ),
    'assessments-peek': (
        <div className='intro'>
            <p>
                Here you can assess yourself in the <em>Goals</em> and <em>Areas</em> you're focused on right now.
            </p>
            <p>
                I'll ask you some questions, and I'll score you out of 100. Then we can <em>reassess</em> you later to see your growth!
            </p>
        </div>
    ),
    'assessments-peek-start': (
        <div className='intro'>
            <p>
                To assess yourself now, click <b>Start Assessment</b>.
                Or you can return later if you'd like.
            </p>
            <p>
                You currently have <b>2</b> <em>Assessment Credits</em>.
            </p>
            <p>
                Each time you complete a <em>Thought Board</em>,
                you'll receive 2 more credits!
            </p>
        </div>
    ),
    'take-assessment': (
        <div className='intro'>
            <p>
                <b>Great, let's start!</b>
            </p>
            <p>
                Rate each <em>Statement</em> between <em>0 and 100</em> based on how <b>true it feels to you</b> right now.
            </p>
            <p>
                Don't overthink it! Go with your gut, and have fun!
            </p>
        </div>
    ),
    'pre-rating': (
        <div className='intro'>
            <p>
                <b>Good!</b> Now ask yourself how much you believe this <em>Thought</em> to be true <b>in this moment</b>. Click on the bar wherever that is.
            </p>
            <p>
                Again, go with your gut here!
            </p>
        </div>
    ),
    'respond-1': (
        <div className='intro'>
            <p>
                Now let's <b>explore</b> your <em>Thought</em>!
            </p>
            <p>
                I'll write <em>Responses</em> to help you explore here.
            </p>
            <p>
                If any <em>Response</em> feels
                at least<br /><b>50% true</b>, click it!
            </p>
            <p>
                Your goal is to get to <b>at least 5</b>!
            </p>
        </div>
    ),
    'respond-acknowledge': (
        <div className='intro'>
            <p>In your exploring, we'll first <em>Acknowledge</em> some of the difficulties you may be facing right now.</p>
        </div>
    ),
    'respond-reframe': (
        <div className='intro'>
            <p>Now, we'll <em>Reframe</em> your negative thought in new ways.</p>
            <p>How might we reframe this thought to unlock new ways of thinking?
            </p>
        </div>
    ),
    'respond-motivate': (
        <div className='intro'>
            <p>In your exploring, we'll first <em>Motivate</em> ourselves by thinking in an expansive and empowering way.</p>
        </div>
    ),
    'respond-manifest': (
        <div className='intro'>
            <p>Now, we'll <em>Manifest</em> this thought in ourselves by imagining new paths and possibilities.</p>
        </div>
    ),
    'respond-2': (
        <div className='intro'>
            <p>
                Here you can change your <em className='voiceWord'>Exploration Path</em> at any time.
            </p>
            <p>
                As we explore more,
                you'll unlock additional <em className='voiceWord'>Paths</em>.
            </p>
        </div>
    ),
    'completed-first-thought': (
        <div className='intro'>
            <p><b>Nice work!</b></p>
            <p>
                By the way... we just did a little <em>Cognitive Behavioral Therapy</em> (CBT) together!
            </p>
            <p>
                CBT is proven to significantly improve your emotional wellbeing quickly.
            </p>
            <p> Exploring just a few thoughts a day is enough for most people to
                experience direct benefits.</p>
        </div>
    ),
    'completed-first-thought-2': (
        <div className='intro'>
            <p>
                Here I'll track your <em>Thought Board</em> as we explore more.
            </p>
            <p>
                Your goal is to work with <b>10</b> <em>Thoughts</em>.
            </p>
            <p>
                Each time you complete a <em>Board</em>, you'll then give your <em>Charity Contributions</em> to a charity of your choice.
            </p>
            {/* <p>You'll also received <b>2</b> <em>Assessment Credits</em> so that you can
                continuing assessing yourself with me.</p> */}
        </div>
    ),
    'completed-first-thought-3': (
        <div className='intro'>
            <p>When you complete a <em>Board</em>, you'll also received <b>2</b> <em>Assessment Credits</em> so that you can
                continuing assessing yourself with me.</p>
            <p>
                You can click <b>My Assessments</b> to assess
                yourself with me at any time.
            </p>
        </div>
    ),
    'reframing': (
        <div className='intro'>
            <p>
                Now choose a <em className='reinforceWord'>Personal Statement</em> that represents
                how you want to view this area of your life.

            </p>
            <p>
                Click a <em className='reinforceWord'>Statement</em> that resonates with you right now. You'll be able to tweak it as we explore more later.
            </p>

        </div>
    ),
    'explore-1': (
        <div className='intro'>
            <p>
                <b>Last step!</b>
            </p>
            <p>
                Choose <b>2</b> of your <em>Responses</em> that <b>most resonate</b> with you.
            </p>
            <p>
                We'll finish exploring this <em>Thought</em> later,
                and I'll remember what you choose to help guide us to deeper thoughts and ideas.
            </p>
        </div>
    ),
    'explore-2': (
        <div className='intro'>
            <p>
                Later, we'll use your <b>Notes</b> to create a new <em className='reinforceWord'>Personal Statement</em>.
            </p>
            <p>
                But for now, click <b>2</b> <em>Responses</em> that
                most resonate with you.
            </p>
        </div>
    ),
    'post-rating': (
        <div className='intro'>
            <p>
                Now rate how true your <em className='reinforceWord'>Personal Statement</em> feels.
            </p>
            <p>
                Don't fret if you don't fully believe or resonate with your <em className='reinforceWord'>Personal Statement</em> yet!
            </p>
            <p>
                As we explore more, we'll dive deeper into this area of your thinking.
            </p>
        </div>
    ),
    'back-to-start-1': (
        <div className='intro'>
            <p><b>Nicely done!</b></p>
            <p>
                I'll remember your progress with this <em>Thought</em> for you.

            </p>
            <p>
                Since we haven't finished fully <b>exploring</b> it yet,
                you can revisit it later at any time.
            </p>
            {/* LANDSLIDE */}
            {/* <p>
                <b>Whoa, you just learned <em className='reinforceWord'>CBT</em>!</b>
            </p>
            <p>
                If it felt like
                a lot of work, that's probably just my yacking!
            </p>
            <p>
                <b>Try one</b> on your own now. You'll probably find that you can do it in less than <i>a minute</i>!
            </p>
            <p>

                There's more I want to show you, but it can wait!
            </p> */}
        </div>
    ),
    'back-to-start-2': (
        <div className='intro'>
            <p>
                I'll track your <em>Thoughts</em> and <em className='reinforceWord'>Personal Statements</em> here.
            </p>
            <p>
                You can click a <em>Thought</em> here to continue exploring it
                at any time.
            </p>
            <p>
                Or, you can come back to it later and
                pick a new <em>Thought</em> instead.
            </p>
        </div>
    ),
    'back-to-start-3': (
        <div className='intro'>
            <p>
                Fun fact: users who explore<br /><em>5 Thoughts</em> report a <b>32%</b> improvement in work productivity, and
                a <b>40%</b> improvement in life satisfaction!
            </p>
            {/* <p>
                That's because our work here is based on <b>Cognitive Behavioral Therapy</b> (CBT),
                which is clinically proven to improve emotional wellbeing!
            </p> */}
        </div>
    ),
    'back-to-start-4': (
        <div className='intro'>
            <p>
                You'll be able to track your own improvement using <em className='voiceWord'>Assessments</em>.
            </p>
            <p>
                Once you fully explore <em>3 Thoughts</em>, you'll unlock your first <em className='voiceWord'>Assessments</em>.
            </p>
            {/* <p>
                That's because our work here is based on <b>Cognitive Behavioral Therapy</b> (CBT),
                which is clinically proven to improve emotional wellbeing!
            </p> */}
        </div>
    ),
    'back-to-start-5': (
        <div className='intro'>
            <p>
                There's more I want to show you, but it can wait!
            </p>
            <p>
                Let's finish by exploring <b>2</b> more <em>Thoughts</em>.
            </p>
            <p>
                I'll leave you be so you can do it quickly!
            </p>
            {/* <p>
                That's because our work here is based on <b>Cognitive Behavioral Therapy</b> (CBT),
                which is clinically proven to improve emotional wellbeing!
            </p> */}
        </div>
    ),
    'welcome': (
        <div className='intro'>
            <p>
                <b>Welcome!</b>
            </p>
            <p>
                Here's a <u>practice project</u> we created to
                show you how everything works.
            </p>
        </div>
    ),
    'projects': (
        <div className='intro'>
            <p>
                You probably <i>already</i> organize your work elsewhere.
            </p>
            <p>
                So think of here as your <u>own space</u> to chunk out <b>important</b> work to focus on.
            </p>
            <p>
                Creating projects that will take <u>2 to 10 hours</u> to complete is recommended.
            </p>
        </div>
    ),
    'sessions': (
        <div className='intro'>
            <p>
                Each day you'll complete focused sessions
                on your projects.
            </p>
            <p>
                There are 3 types of sessions: <b>Impact</b>, <b>Growth</b>, and <b>Ease</b>.
            </p>
        </div>
    ),
    'impact-sessions': (
        <div className='intro'>
            <p>
                <b>Impact sessions</b> are lengthy sessions of work
                for when you feel confident or energized about diving into a project
                and really moving it forward.
            </p>
            <p>
                These sessions are in <b style={{ color: "#f47129" }}>orange</b> on the first line.
            </p>
        </div>
    ),
    'growth-sessions': (
        <div className='intro'>
            <p>
                <b>Growth sessions</b>  are shorter sessions
                that are great for making progress on a project
                even if you're a little low on energy or focus.
            </p>
            <p>
                They're also great for stretching your ability
                to focus over time.
            </p>
            <p>
                They're in <b style={{ color: "#0bac1e" }}>green</b> on the second line.
            </p>
        </div>
    ),
    'ease-sessions': (
        <div className='intro'>
            <p>
                <b>Ease sessions</b> are very short sessions.
                Use these sessions when you need small wins
                on a project to gain confidence or ease into it.
            </p>
            <p>
                These sessions also help ease your
                anxiety or negative thinking towards work over time.
            </p>
            <p>
                They're in <b style={{ color: "#8a14a2" }}>purple</b> on the last line.
            </p>
        </div>
    ),
    'first-session': (
        <div className='intro'>
            <p>
                Go ahead and start a session by hovering over a line and choosing an option.
            </p>
            <p>
                <u>Don't worry about doing actual work</u>, it's just for practice. We'll end it early.
            </p>
        </div>
    ),
    'started-session': (
        <div className='intro'>
            <p>
                <u>Your browser may ask if you want notifications enabled above</u>. We only use this to let you know when your sessions end.
            </p>
            <p>
                Once this practice session finishes, you can click <b>Done</b>!
            </p>
        </div>
    ),
    'started-session-2': (
        <div className='intro'>
            <p>
                As you work, you'll gain experience in <u>three areas</u>.
            </p>
            <p>
                Each time you fill a circle here, you'll earn a <b>coin</b> below.
            </p>
            <p>
                For every 15 coins you earn, you can choose a charity to earn a&nbsp;
                <u>$20 donation</u> for!
            </p>
        </div>
    ),
    'thoughts': (
        <div className='intro'>
            <p>
                After each session, you'll take a second to <u>choose a positive thought</u> that <b>feels true</b> about the work you've just done.
            </p>
            <p>
                This may feel a little <i>funny</i> <u>at first</u>,
                but you'll notice a subtle shift in how
                you view work over time.
            </p>
        </div>
    ),
    'start-first-assessment': (
        <div className='intro'>
            <p><b>Sounds good!</b></p>
            <p>
                Here's a short assessment to help me understand what your current
                strengths and struggles may be in this area.
            </p>
            <p>Don't worry, we'll begin exploring your mind together soon!</p>
        </div>
    ),
    'finish-first-assessment': (
        <div className='intro'>
            <p>
                Great, that helps!
                You've received an initial score in this area,
                but we'll dive more into this later.
            </p>
            <p>
                Next, I'd like to assess you in the second area
                that you chose to explore. Then we'll begin!
            </p>
        </div>
    ),
    'force-registration': (
        <div className='intro'>
            <p>
                <b>Great work!</b>
            </p>
            <p>
                Before we explore more,
                you'll need to create a free account
                so that I can remember you in the future.
            </p>
        </div>
    ),
    'recommend-registration': (
        <div className='intro'>
            <p>
                Before you go, would you like me
                to remember you and our progress next time?
            </p>
            <p>
                A free account allows us to pick
                up where we left off!
            </p>
        </div>
    ),
    'explore-goals': (
        <div className='intro'>
            <p>
                <b>Hi!</b> <em className='responseWord'>I'm Air</em>, your AI guide for exploring your thoughts quickly!
            </p>
            {/* <p>
                You'll need about <em className='responseWord'>3 minutes</em> to explore your mind with me.
            </p> */}
            <p>
                Which specific areas would you
                like to explore first?
            </p>
            {/* <p>If not, we can start and see where it goes!</p> */}
        </div>
    ),
    'starting-goals': (
        <div className='intro'>
            <p>
                <b>Sounds good!</b>
            </p>
            <p>One more question... what are two goals you have in your life right now?</p>
            {/* <p>
                You'll need about <em className='responseWord'>3 minutes</em> to explore your mind with me.
            </p> */}
            {/* <p>If not, we can start and see where it goes!</p> */}
        </div>
    ),
    'work-life': (
        <div className='intro'>
            <p>
                I'll have some <em className='responseWord'>good ideas</em> about where we can start
                exploring if you
                can write a sentence or two about yourself
                and your current <em>work life</em>.
            </p>
            <p>Hey, you could be a dog with a keyboard for all I know! </p>
            <p>
                (You can leave this section blank for now if you'd like.)
            </p>
        </div>
    ),
    'personal-life': (
        <div className='intro'>
            <p>
                Last step before we start!
            </p>
            <p>
                Write a sentence or two about your <em style={{ color: "rgb(55, 148, 36)" }}>personal life</em> if you'd like to explore that part of your life too.
            </p>
            <p>
                (You can leave this section blank for now if you'd like.)
            </p>
        </div>
    ),
}

export default WizardPop;