import React, { useContext } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import Back from '../helpers/Back';
import User from '../helpers/User';
import LogThought from './LogThought';
import './AboutMeScreen.scss';

import GridLoader from "react-spinners/GridLoader";
import ContentLoader from 'react-content-loader';
import EmotionQuestion from './EmotionQuestion';
import EmotionsGradeSheet from './EmotionsGradeSheet';
import EmotionsCompletedSheet from './EmotionsCompletedSheet';
import { ClipLoader } from 'react-spinners';
import AiMessage from './AiMessage';
import UserContext from '../UserContext';
import WizardPop from './WizardPop';

function AboutMeScreen({ onCompleted, editSection }) {

    const { user, changeUser } = useContext(UserContext);

    const [category, setCategory] = useState(null);
    const [isGraded, setIsGraded] = useState(false);

    const [showDoneButton, setShowDoneButton] = useState(false);

    const [isExplore, setIsExplore] = useState(true);
    const [selectedGoals, setSelectedGoals] = useState([]);

    const [showFinishingWorkMessage, setShowFinishingWorkMessage] = useState(false);
    const [showFinishingPersonalMessage, setShowFinishingPersonalMessage] = useState(false);
    const [showFinishingFinalMessage, setShowFinishingFinalMessage] = useState(false);

    const [isPop1Open, setIsPop1Open] = useState(false);
    const [isPop2Open, setIsPop2Open] = useState(false);
    const [isPop3Open, setIsPop3Open] = useState(false);

    useEffect(() => {
        setIsPop1Open(true);
    }, []);

    useEffect(() => {
        console.log('editSection effect', user);
        if (editSection === "Work" && user.about_work_answer && user.about_work_answer.length > 1) {
            setAnswerText(user.about_work_answer);
        }
        if (editSection === "Personal" && user.about_personal_answer && user.about_personal_answer.length > 1) {
            setAnswerText(user.about_personal_answer);
        }

        setCategory(editSection);
    }, [editSection]);

    useEffect(() => {
        setShowDoneButton(selectedGoals.length === 2);
    }, [selectedGoals]);

    const defaultAnswer = () => {
        if (category === "Work" && user.about_work_answer && user.about_work_answer.length > 1) {
            return user.about_work_answer;
        }
        if (category === "Personal" && user.about_personal_answer && user.about_personal_answer.length > 1) {
            return user.about_personal_answer;
        }
        return "";
    };
    const defaultHasStarted = () => {
        if (category === "Work" && user.about_work_answer && user.about_work_answer.length > 1) {
            return true;
        }
        if (category === "Personal" && user.about_personal_answer && user.about_personal_answer.length > 1) {
            return true;
        }
        return false;
    };
    const [answerText, setAnswerText] = useState(defaultAnswer());
    const [hasStartedAnswer, setHasStartedAnswer] = useState(defaultHasStarted());


    const handleGradeSheetCompleted = async (questions) => {




        //setShowStartingMessage(true);

        // setTimeout(() => {
        //     setShowStartingMessage(false);
        // }, 1500);

    };

    const handleChange = (e) => {
        let value = e.target.value;

        // TODO: TESTING ONLY.
        if (value === "sdf") {
            value = "I am a project manager at a startup. I started 6 months ago and I work remotely, which has made it hard to get to know my colleagues and get up to speed. I often find myself procrastinating during the workday.";
        }

        setAnswerText(value);
        setHasStartedAnswer(true);

        if (value.length > 36 && !showDoneButton) {
            setShowDoneButton(true);
        }
    };

    const handleBackClick = () => {
        onCompleted();
    };

    const handleDoneClick = async () => {

        document.querySelector('.sheet-content').style.opacity = "0.3";

        const response = await Back.saveAbout(answerText, category);

        console.log('saveAbout', response);

        if (category === "Work") {
            user.about_work_answer = answerText;
        }
        if (category === "Personal") {
            user.about_personal_answer = answerText;
        }

        changeUser({ ...user });

        if (editSection) {
            setTimeout(() => {
                onCompleted();
            }, 1000);
            return;
        }

        if (category === "Work") {
            setTimeout(() => {
                setCategory("Personal");
                setIsPop3Open(true);
                setAnswerText("");
            }, 800);
        } else {
            setTimeout(() => {
                onCompleted();
            }, 1000);
        }


    };

    const animatePageLoading = async (time) => {
        if (!time) {
            time = 900;
        }

        document.querySelector('.sheet-content').classList.add('IsPageLoading');
        await delay(time);
        document.querySelector('.sheet-content').classList.remove('IsPageLoading');
    };
    async function delay(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const handleGoalsDoneClick = async (e, areaName) => {

        Back.recordEvent("ChoseStartingAreas", null, null, selectedGoals);

        const activeAreas = [...selectedGoals];
        Back.saveContext("activeAreas", activeAreas);

        await animatePageLoading(500);

        onCompleted();

        return;

        setIsExplore(false);
        setCategory("Work");
        setIsPop2Open(true);


    };

    const handleGoalClick = async (goal) => {

        if (selectedGoals.includes(goal.name)) {
            setSelectedGoals((prev) => {
                const newGoals = prev.filter(g => g !== goal.name)
                return [...newGoals];
            });
            return;
        }

        if (selectedGoals.length === 2) {
            setSelectedGoals((prev) => {
                const newGoals = [];
                newGoals.push(prev[0]);
                newGoals.push(goal.name);
                return [...newGoals];
            });
            return;
        }

        setSelectedGoals((prev) => {
            const newGoals = [...prev, goal.name];
            return [...newGoals];
        });
    };

    setTimeout(() => {
        //setShowStartingMessage(true);
    }, 500);

    // Duplicated in Assessment Card currently.
    const goals = [

        {
            id: 1,
            title: "Natural Energy",
            name: "Natural Energy",
            icon: "Mood",
            description: "Discover new energy through joy and spontaneity"
        },
        {
            id: 2,
            title: "Drive to Finish",
            name: "Drive to Finish",
            icon: "Motivation",
            description: "Unblock beliefs holding you back from finishing things"
        },
        {
            id: 3,
            title: "Creative Flow",
            name: "Creative Flow",
            icon: "Focus",
            description: "Reduce mental friction and understand your resistances"
        },
        {
            id: 5,
            title: "Life Purpose",
            name: "Life Purpose",
            icon: "Purpose",
            description: "Envision and clarify a more expansive, purposeful life"
        },
        {
            id: 4,
            title: "Balanced Self-Care",
            name: "Balanced Self-Care",
            icon: "Ease",
            description: "Find balance and ease that renews your energy and flow"
        },
        {
            id: 6,
            title: "Relational Openness",
            name: "Relational Openness",
            icon: "Openness",
            description: "Open up to deeper and more authentic relationships"
        }
    ];

    let className = "AboutMeScreen";

    if (isGraded) {
        className += " IsGraded";
    }
    if (showDoneButton) {
        className += " ShowDoneButton";
    }
    if (hasStartedAnswer) {
        className += " HasStartedAnswer";
    }

    // if (showStartingMessage) {
    //     className += ' ShowStartingMessage';
    // }

    const exploreSheet = (
        <div>
            <div className='sheet-heading'>
                What are <span className='two-areas-phrase'>two areas</span> of yourself that<br /> you would like to explore?
            </div>
            <div className='sheet ExplorePage'>
                <div className='sheet-content'>
                    {isPop1Open && <WizardPop name={'explore-goals'} positions={['right']} shift={[-12, 160]} isOpen={isPop1Open} onClose={() => { setIsPop1Open(false); }}></WizardPop>}

                    <div className='goals-instructions'>
                        Choose <span className='two-areas-phrase'>two areas</span> you'd like to explore first.
                    </div>

                    <div className='goals-content'>

                        <div className='goals'>
                            {goals.map(goal => {

                                const isSelected = selectedGoals.includes(goal.name);
                                //const isSelected = false;

                                let goalClassName = 'GoalExploreCard';
                                if (isSelected) {
                                    goalClassName += " IsSelected";
                                }

                                return (
                                    <div className={goalClassName} onClick={() => handleGoalClick(goal)}>

                                        <div className='title-content'>
                                            <img src={`skill-${goal.icon.toLowerCase()}.png`} className='plant-img icon-img' />
                                            <div className='title'>{goal.title}</div>
                                        </div>
                                        <div className='goal-description'>
                                            {goal.description}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>

                        <div className='goals-done-button' onClick={handleGoalsDoneClick} tabIndex="0" onKeyDown={handleGoalsDoneClick}>Continue</div>

                    </div>

                    {/* <div className='goals-subheading'>
                Choose whichever appeals to you most right now. You can switch
                between areas of focus in the future.
            </div> */}
                </div>
            </div>
        </div>
    )

    const workSheet = (
        <div>
            <div className='sheet-heading'>
                {/* <img src="julia-face.png" className="julia-face-img" /> */}
                <img src="work-about-head.png" className='work-about-head-img' />
                <div className='label'>
                    <span className="life-title">My Work Life</span>
                </div>

            </div>
            <div className='sheet WorkSheet'>
                <div className='sheet-content FadeInDelay FadeOut'>

                    {isPop2Open && <WizardPop name={'work-life'} positions={['right']} shift={[-12, 160]} isOpen={isPop2Open} onClose={() => { setIsPop2Open(false); }}></WizardPop>}

                    <div className='goals-instructions'>
                        Write a sentence or two about yourself and your current work life.
                    </div>

                    <div className='answer-fill'>
                        <textarea onChange={handleChange} className="about-answer" autoFocus={true} value={answerText} placeholder="I'm a project manager at a startup. I started 6 months ago and I work remotely, which has made it hard to get to know my colleagues and get up to speed. I often find myself procrastinating during the workday."></textarea>
                    </div>

                    {/* <div className='compose-icon-position'>
                    <img src="about-compose.png" className='compose-icon-img' />
                </div> */}

                    <div className='work-scene-position'>
                        <img src="work-scene.svg" className='scene-svg' />
                    </div>

                    {/* <div className='help-content'>
                        <div className='AiMention'>
                            <div className='julia-face'>
                                <img src="aiface-top.png" className='face-img face-top-img' />
                                <img src="aiface-bottom.png" className='face-img face-bottom-img' />
                            </div>
                            <div className='GreetingContent'>

                                What do you want to share about<br />your work life before we begin?
                            </div>
                        </div>
                        <img src="about-arrow.png" className='ai-arrow-img' />
                        <div className='skip-instructions'>
                            &nbsp;
                        </div>
                    </div> */}

                    {/* <div className='done-button-position'>
                        <div className='done-button skip-button' onClick={handleBackClick}>Back</div>
                    </div> */}

                    {(true) && (
                        <div className='done-button-position'>
                            <div className='done-button' onClick={handleDoneClick} tabIndex="0" onKeyDown={handleDoneClick}>{answerText.length > 0 ? 'Continue' : 'Skip'}</div>
                        </div>
                    )}

                    <div className='questions-position'>
                        <div className='questions-sheet'>
                            <div className='heading-content'>
                                <div className='pen-area'>
                                    <img src="about-notebook.png" className='pen-img' />
                                </div>
                                <div className='questions-heading examples-heading'>
                                    Mention anything!
                                    {/* Mention anything!<br /><span className="like-word">Like...</span> */}
                                </div>
                            </div>
                            <div className='questions-list'>
                                <div className='question'>
                                    &bull; What you do
                                </div>
                                <div className='question'>
                                    &bull; Your biggest goal right now
                                </div>
                                {/* <div className='question'>
                                        &bull; What are you goals over the next year or two?
                                    </div> */}
                                <div className='question'>
                                    &bull; What you're struggling with
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className='examples-layout'>
                        <div className='questions-col'>

                        </div>
                        <div className='examples-col'>
                            <div className='examples-heading'>Example</div>
                            <div className='about-examples'>

                                <div className='example-name'>Sara</div>
                                <div className='example'>
                                    I am a project manager at a startup.
                                    I started 6 months ago and I work remotely,
                                    which has made it hard to get to know my colleagues
                                    and get up to speed. I often find myself procrastinating during the workday.
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    const personalSheet = (
        <div>
            <div className='sheet-heading'>
                {/* <img src="julia-face.png" className="julia-face-img" /> */}
                <img src="shoes.png" className='work-about-head-img' />
                <div className='label'>
                    <span className="life-title life-title-personal">My Personal Life</span>
                </div>

            </div>
            <div className='sheet WorkSheet PersonalSheetChanges'>
                <div className='sheet-content FadeInDelay FadeOut'>

                    {isPop3Open && <WizardPop name={'personal-life'} positions={['right']} shift={[-12, 160]} isOpen={isPop3Open} onClose={() => { setIsPop3Open(false); }}></WizardPop>}

                    <div className='goals-instructions'>
                        Write a sentence or two about yourself and your life outside of work.
                    </div>

                    <div className='answer-fill'>
                        <textarea onChange={handleChange} className="about-answer" autoFocus={true} value={answerText} placeholder="I'm 28 and am currently single but looking for a long-term relationship.
                            I moved to a new city last year and am still struggling to find new friends here.
                            I've been trying to go to the gym but have
                            struggled to go consistently."></textarea>
                    </div>

                    {/* <div className='compose-icon-position'>
                    <img src="about-compose.png" className='compose-icon-img' />
                </div> */}

                    <div className='work-scene-position'>
                        <img src="personal-scene.svg" className='scene-svg' />
                    </div>


                    {true && (
                        <div className='done-button-position'>
                            <div className='done-button' onClick={handleDoneClick} tabIndex="0" onKeyDown={handleDoneClick}>{answerText.length > 0 ? 'Finish' : 'Skip'}</div>
                        </div>
                    )}

                    <div className='questions-position'>
                        <div className='questions-sheet'>
                            <div className='heading-content'>
                                <div className='pen-area'>
                                    <img src="about-notebook.png" className='pen-img' />
                                </div>
                                <div className='questions-heading examples-heading'>
                                    Mention anything!
                                    {/* Mention anything!<br /><span className="like-word">Like...</span> */}
                                </div>
                            </div>
                            <div className='questions-list'>
                                <div className='question'>
                                    &bull; In a relationship?
                                </div>
                                <div className='question'>
                                    &bull; Your social life & goals
                                </div>
                                {/* <div className='question'>
                                        &bull; What are you goals over the next year or two?
                                    </div> */}
                                <div className='question'>
                                    &bull; What you're struggling with
                                </div>


                            </div>
                        </div>
                    </div>

                    <div className='examples-layout'>
                        <div className='questions-col'>

                        </div>
                        <div className='examples-col'>
                            <div className='examples-heading'>Example</div>
                            <div className='about-examples'>

                                <div className='example-name'>Sara</div>
                                <div className='example'>
                                    I am a project manager at a startup.
                                    I started 6 months ago and I work remotely,
                                    which has made it hard to get to know my colleagues
                                    and get up to speed. I often find myself procrastinating during the workday.
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );


    return (
        <div className={className}>

            <div className='sidebar'>
                <div className='logo'>
                    <img src="air-logo2.png" className='logo-img' />
                </div>


            </div>

            <div className='main-layout'>

                <div className='ai-col'>
                    {/* <div className='AiGreeting'>

                        <div className='steps-content'>
                            <div className='steps'><span className="step-phrase">Step 1</span> of 2</div>
                            <div className='step-title'>
                                My Work Life
                            </div>

                            <div className='step-instructions'>
                                <p>
                                    Write a couple of sentences about how your
                                    job<br />and work life are going.
                                </p>
                                <p>
                                    <b>This is optional.</b><br />It helps Air identify
                                    your thought patterns faster.
                                </p>
                            </div>
                        </div>



                    </div> */}
                </div>

                <div className='grade-col'>
                    {!editSection && isExplore && exploreSheet}
                    {(editSection === "Work" || category === "Work") && workSheet}
                    {(editSection === "Personal" || category === "Personal") && personalSheet}
                </div>

            </div>
        </div>
    );


}

function areRatingsCompleted(questions) {

    for (var q in questions) {
        console.log('are', questions[q].rating, questions[q]);
        if (!questions[q].rating) {
            return false;
        }
    }

    return true;
}

export default AboutMeScreen;
