import React, { useContext, useState, useEffect, useRef } from 'react';
import Back from '../helpers/Back';
import UserContext from '../UserContext';
import Cookies from 'js-cookie';

import './CreateAccountDrawer.scss';
import WizardPop from './WizardPop';

function CreateAccountDrawer({ }) {

    const { user, changeUser } = useContext(UserContext);
    const ref = useRef();

    const [opacity, setOpacity] = useState('0.5');
    const [lockStyle, setLockStyle] = useState(false);

    // Form
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
    const [isFormCompleted, setIsFormCompleted] = useState(false);

    const [formError, setFormError] = useState(null);
    const [showFormError, setShowFormError] = useState(false);

    const [isPop1Open, setIsPop1Open] = useState(false);
    const [isPop2Open, setIsPop2Open] = useState(false);

    useEffect(() => {
        const isValid = validateForm();
        console.log('isValid', isValid);
        setIsFormCompleted(isValid);
    }, [email, password]);

    const handleEmailChange = (e) => {
        if (e.target.value === "sdf") {
            setEmail("thisisatest" + getRandomInt(1000000, 9999999) + "@gmail.com");
            setPassword("thisisatest.123");
            return;
        }

        setEmail(e.target.value);
    }
    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    }

    const handleCreateAccountClick = async () => {
        const response = await Back.registerTemporaryUser(email, password);

        const newUser = { ...response };
        newUser.is_temporary = false;
        newUser.is_account_gated = false;
        newUser.CreateAccountDrawer = false;
        newUser.CreateAccountForceRegistration = false;

        console.log('wer created user', newUser);

        changeUser({ ...newUser });

        window.rdt('track', 'SignUp', {
            "currency": "USD",
            "value": 25
        });
        window.gtag("event", "sign_up", {
            currency: "USD",
            value: 25,
            source: 'app_form',
            method: 'email'
        });
        window.pintrk('track', 'signup', {
            event_id: generateUniqueId(),
            value: 25,
            currency: 'USD'
        });

        window.Intercom('update', { email: email });
    }

    const shouldSubmitBeEnabled = () => {
        if (!email || email.length < 4 || email.length > 48) {
            return false;
        }

        if (!password || password.length < 4 || email.length > 48) {
            return false;
        }

        return true;
    };

    const validateForm = () => {
        if (!checkEmail(email)) {
            return false;
        }

        if (!checkPassword(password)) {
            return false;
        }

        return true;
    };


    function checkEmail(email) {
        const res = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
        const isValid = res.test(String(email).toLowerCase());

        if (!isValid) {
            setFormError("Please include a valid email address");
        }

        return isValid;
    }

    function checkPassword(password) {
        const isValid = password && password.length > 7;

        if (!isValid) {
            setFormError("Your password should be at least 8 characters in length");
        }

        return isValid;
    }


    function handleCloseClick() {

        if (user.CreateAccountForceRegistration) {
            return;
        }
        if (user.CreateAccountRecommendRegistration) {
            return;
        }

        setOpacity('0.5');
        setLockStyle(true);

        setTimeout(() => {
            user.CreateAccountDrawer = null;
            changeUser({ ...user });
            setLockStyle(false);
        }, 100);
    };

    const handleSignOutClick = () => {
        Cookies.remove("auth-token");
        window.location.replace("https://start.airhug.app?sign_out=1");
    };

    const handleDeleteMyAccountClick = async () => {
        const response = await Back.markDeleteMyAccount();

        Cookies.remove("auth-token");
        window.location.replace("https://start.airhug.app?sign_out=1");
    };

    useEffect(() => {
        if (user.CreateAccountForceRegistration) {
            setIsPop1Open(true);
        }
        if (user.CreateAccountRecommendRegistration) {
            setIsPop2Open(true);
        }
    }, [user]);

    useEffect(() => {
        function handleClickOutside(event) {

            // if (user.CreateAccountForceRegistration) {
            //     console.log('event', event, event.preventDefault);

            //     // This isn't working yet for some reason.
            //     event.preventDefault();
            //     event.stopPropagation();
            //     return;
            // }

            if (ref.current && !ref.current.contains(event.target)) {
                handleCloseClick();
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, user, changeUser]);

    let className = 'CreateAccountDrawer drawer';

    if (!user) {
        return (<div></div>);
    }
    if (!user.CreateAccountDrawer) {
        return (<div></div>);
    }

    if (!lockStyle && opacity === "0.5") {
        setTimeout(() => {
            console.log('set opacity 1');
            setOpacity("1");
        }, 50);
    }

    const parentStyles = {};
    //parentStyles.right = '0px';
    parentStyles.opacity = opacity;
    //parentStyles.left = opacity === "0.5" ? "0px" : "0px"

    //console.log('parentStyles', parentStyles);

    //user.completeProjectDrawer = user.projects[0];

    if (isFormCompleted) {
        className += " IsFormCompleted";
    }

    if (user.CreateAccountForceRegistration) {
        className += " IsForceRegistration";
    }

    const closeButtonContent = (
        <div className='close-button' onClick={handleCloseClick}>
            <img src="close.svg" className="icon-img" />
        </div>
    );

    const createAccountContent = (
        <div className="form">
            <img src="account-plant.jpg" className='account-plant-img' />
            <div className="form-heading">Create Account</div>
            <div className='form-why'>

                <div className='why-p'>
                    A free account saves
                    your progress and allows you
                    to do multiple sessions with Air.
                </div>
            </div>

            <div className='form-subheading'>
                <span className='step-phase'>Free Plan</span> &bull; <span className='payment-phase'>No credit card required</span>
            </div>

            <input type="text" autoFocus={true} className="input-text" placeholder='Email' value={email} onChange={handleEmailChange} style={{ background: 'url(email2.png) #ffffff no-repeat scroll 17px 18px' }} />
            <input type="password" className="input-text" placeholder='Password' value={password} onChange={handlePasswordChange} style={{ backgroundSize: '17px 17px', background: 'url(password2.png) #ffffff no-repeat scroll 17px 17px' }} />
            <input type="submit" className="submit-button gtm-trial" value="Create Account" onClick={handleCreateAccountClick} />
        </div>
    );

    const manageAccountContent = (
        <div className='manage-account-content'>
            <div className='heading'>My Account</div>
            <div className='links'>
                <div className='account-button' onClick={handleSignOutClick}>Sign out</div>
                <div className='account-button' onClick={handleDeleteMyAccountClick}>Delete my account and activity</div>
            </div>
        </div>
    );

    return (
        <div className={className} ref={ref} style={parentStyles}>

            {isPop1Open && <WizardPop name={'force-registration'} positions={['right']} shift={[-20, 366]} isOpen={isPop1Open} onClose={() => { setIsPop1Open(false); }}></WizardPop>}
            {isPop2Open && <WizardPop name={'recommend-registration'} positions={['right']} shift={[-20, 366]} isOpen={isPop2Open} onClose={() => { setIsPop2Open(false); }}></WizardPop>}

            <div className='drawer-content'>

                {closeButtonContent}

                {user.is_temporary && (
                    createAccountContent
                )}

                {!user.is_temporary && (
                    manageAccountContent
                )}

            </div>

        </div>
    )
};

export default CreateAccountDrawer;

function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

function generateUniqueId() {
    const timestamp = Date.now().toString(); // Current timestamp in milliseconds
    const randomPart = Math.random().toString(36).substr(2, 8); // Random alphanumeric string (8 characters)

    const event_id = `${timestamp}_${randomPart}`;
    return event_id;
}