
import React, { useContext, useRef } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import UserContext from '../UserContext';
import './AreaFilters.scss';


function AreaFilters({ onChange, increaseName, increaseAmount }) {

    const { user, changeUser } = useContext(UserContext);

    let className = "AreaFilters";

    const [selectedArea, setSelectedArea] = useState({ name: "I want to make more money" });

    const [increases, setIncreases] = useState([]);
    const increasesRef = useRef();
    increasesRef.current = increases;

    //const areas = user.areas;

    // const areas = [
    //     { name: "Explore my mind" },
    //     { name: "Accomplish more" },
    //     { name: "Reframe my negativity" },
    //     { name: "Feel better" },
    //     { name: "Consider new ideas" },
    //     { name: "Discover myself" },
    // ];

    const areas = [
        { name: "I want to make more money" },
        { name: "I want to feel more confident" },
        { name: "I want to meet new people" },
        // { name: "My Air" },
        // { name: "My CBT" },
        // { name: "Trending" },
        // { name: "Growth" },
    ];

    const handleFilterClick = (area) => {

        const newArea = { ...area };

        if (selectedArea && selectedArea.name === newArea.name) {
            setSelectedArea(null);
            return;
        }

        setSelectedArea(newArea);
        onChange(newArea);
    }

    let isFirstMinor = true;

    return (
        <div className={className}>
            {/* <div className='areas-subheading'>Your areas</div> */}

            <div className='category-content'>
                <div className='category-heading'>
                    My Goals
                </div>
            </div>
            <div className='clearfix'></div>

            <div className='areas'>
                {areas.map(area => {

                    let areaClassName = 'area-item';

                    if (area.name === "Focus") {
                        area.name = "Effort";
                    }
                    // if (area.name === "Motivation") {
                    //     area.name = "Goals";
                    // }
                    if (area.name === "Mood") {
                        area.name = "Joy";
                    }

                    areaClassName += " " + area.name;

                    if (selectedArea && selectedArea.name === area.name) {
                        areaClassName += " IsSelected";
                    }

                    return (
                        <div className={areaClassName} key={area.name} onClick={() => handleFilterClick(area)} >
                            <div className='stage-head'>
                                {/* <div className='icon'><img src={`skill-focus.png`} className='plant-img icon-img' /></div> */}
                                <div className='stage-title'>{area.name}</div>
                                <div className='stats'>

                                    <div className='explore-count'>
                                        <div className='number'>4</div>
                                        {/* <div className='label'>Explored</div> */}
                                    </div>

                                    <div className='goal-grade'>
                                        <div className='score'>54%</div>
                                        <div className='label label-low'>Low</div>
                                    </div>



                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    );


}

export default AreaFilters;