import React, { useContext, useEffect, useState } from 'react';
import './TurnInStackScreen.scss';

import GridLoader from "react-spinners/GridLoader";
import ContentLoader from 'react-content-loader';
import SuggestionsSheet from './SuggestionsSheet';
import AiMessage from './AiMessage';
import Back from '../helpers/Back';
import EmotionMeter from './EmotionMeter';
import ReframingList from './ReframingList';
import ThoughtSetContent from './ThoughtSetContent';
import AnimationContext from '../AnimationContext';
import WizardPop from './WizardPop';
import UserContext from '../UserContext';
import UI from './UI';
import ThoughtPostRating from './ThoughtPostRating';
import QuestionCard from './QuestionCard';
import AssessmentSidebar from './AssessmentSidebar';
import AssessmentReport from './AssessmentReport';

function TurnInStackScreen({ initialAssessment, onAssessmentCompleted, donationsValue }) {

    const { user, changeUser } = useContext(UserContext);
    const animations = useContext(AnimationContext);

    const [selectedCharity, setSelectedCharity] = useState(null);
    const [showConfirmMessage, setShowConfirmMessage] = useState(false);

    let className = "TurnInStackScreen";

    const charities = [
        { id: 1, name: "redcross", title: "American Red Cross", description: " prevents and alleviates human suffering in the face of emergencies by mobilizing the power of volunteers" },
        { id: 2, name: "activeminds", title: "Active Minds", description: " is the nation’s leading nonprofit organization promoting mental health awareness and education for young adults" },
        { id: 3, name: "feedingamerica", title: "Feeding America", description: "  helped provide 5.2 billion meals to millions of people in need last year" },
        { id: 4, name: "makeawish", title: "Make-A-Wish", description: " creates life-changing wishes for children with critical illnesses, helping over 360,000 American children since 1980" },
    ];

    const handleCharityClick = (charity) => {
        setSelectedCharity(charity);
    };

    const handleFinishClick = async () => {

        user.context.donationTotals[selectedCharity.name] += donationsValue;

        changeUser({ ...user });
        setShowConfirmMessage(true);

        const response = await Back.turnInStack(selectedCharity.name);

        setTimeout(() => {
            location.reload();
        }, 1500);

    };

    if (selectedCharity) {
        className += " ShowDoneButton";
    }


    return (
        <div className={className}>
            <div className='screen-subheading'>Great job!</div>
            <div className='screen-heading'>You Finished Your <span className='number-phrase'>1st</span> Board!</div>
            <div className='donations-sheet'>
                <div className='donations-heading'>

                    <div className='h-text'>You earned</div>
                    <div className='h-dollars'>${formatToDollarCents(donationsValue)}</div>
                    <div className='h-text'>to contribute to a charity of your choice!</div>
                </div>

                <div className='choose-heading'>
                    Which charity would you like your contributions
                    to be donated to on your behalf?
                </div>

                <div className='charity-cards'>
                    {charities.map(charity => {

                        let className = 'CharityCard';

                        if (selectedCharity && charity.name === selectedCharity.name) {
                            className += " IsSelected";
                        }

                        return (
                            <div className={className} onClick={() => handleCharityClick(charity)}>
                                <div className='main-content'>
                                    <div className='charity-content'>
                                        <img src={`charity-${charity.name}.png`} className='charity-logo-img' />
                                    </div>
                                    <div className='photo-content'>
                                        <img src={`charity-photo-${charity.name}.jpg`} className='charity-photo-img' />
                                    </div>

                                </div>
                                <div className='details-content'>
                                    <div className='total-donations'>
                                        You've earned <span className='dollars-phrase'>{user.context.donationTotals[charity.name]}</span> for <span className='charity-phrase'>{charity.title}</span>.<br />

                                    </div>
                                    <div className='charity-about'>
                                        <span className='charity-phrase'>{charity.title}</span> {charity.description}
                                    </div>
                                </div>
                                <div className='reach-content'>


                                    <div className='reach-amount'>Goal: &nbsp;<span className='current-amount'>${formatToDollarCents(user.context.donationTotals[charity.name])}</span> / $20.00</div>
                                    <div className='reach-progress-bar'>
                                        <div className='reach-progress-fill' style={{ width: max100(user.context.donationTotals[charity.name] / 20 * 100) + "%" }}></div>
                                    </div>

                                    <span className='reach-phrase'>Reach $20.00 to have your donation paid out</span>
                                </div>
                            </div>
                        )
                    })}
                </div>

                <div className='charity-done-button' onClick={handleFinishClick}>
                    {showConfirmMessage && (
                        <span>Thank you! Your donation has been added.</span>
                    )}
                    {!showConfirmMessage && (
                        <span>Contribute ${formatToDollarCents(donationsValue)} to {selectedCharity ? selectedCharity.title : ""}</span>
                    )}

                </div>
            </div>
        </div>
    );


}

export default TurnInStackScreen;


function formatToDollarCents(number) {
    // Ensure the input is a number
    if (typeof number !== 'number' || isNaN(number)) {
        return "0.00";
    }

    // Convert the number to a fixed 2 decimal string
    let dollarCents = number.toFixed(2);

    // Return the formatted string
    return dollarCents;
}

function max100(number) {
    if (number > 100) {
        return 100;
    }
    return number;
}