
import React, { useContext, useRef } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import UserContext from '../UserContext';
import './AreaGrid.scss';


function AreaGrid({ increaseName, increaseAmount }) {

    const { user, changeUser } = useContext(UserContext);

    let className = "AreaGrid";

    const [increases, setIncreases] = useState([]);
    const increasesRef = useRef();
    increasesRef.current = increases;

    const areas = user.areas;

    useEffect(() => {
        if (!increaseName) {
            return;
        }

        const newIncrease = {
            name: increaseName,
            amount: increaseAmount,
            isFading: false
        };
        const newIncreases = [...increases, newIncrease];
        setIncreases(newIncreases);
        console.log('iz setIncreases1', newIncreases, newIncrease);

        setTimeout(() => {
            const updatedIncrease = increasesRef.current.find(i => i.name === increaseName);
            const updatedIncreaseIndex = increasesRef.current.findIndex(i => i.name === increaseName);
            updatedIncrease.isFading = true;

            const updatedIncreases = [...increasesRef.current];
            updatedIncreases[updatedIncreaseIndex] = updatedIncrease;

            console.log('iz updatedIncreases', increaseName, increasesRef.current, updatedIncreases);
            setIncreases([...updatedIncreases]);
        }, 2000);

        setTimeout(() => {
            const updatedIncreases = increasesRef.current.filter(i => i.name !== increaseName);
            console.log('iz updatedIncreases', increaseName, increasesRef.current, updatedIncreases);
            setIncreases([...updatedIncreases]);
        }, 3000);

    }, [increaseName]);

    let isFirstMinor = true;

    return (
        <div className={className}>
            {/* <div className='json'>{JSON.stringify(increases)}</div> */}
            <div className='areas'>
                {areas.map(area => {

                    let areaClassName = 'area-item';

                    areaClassName += " " + area.name;

                    if (area.isFocused) {
                        return (
                            <div className={areaClassName} key={area.name} >
                                <div className='stage-head'>
                                    <div className='icon'><img src={`skill-${area.name.toLowerCase()}.png`} className='plant-img icon-img' /></div>
                                    <div className='stage-title'>{area.name}</div>
                                    <div className='progress-number'>{area.score ? area.score : '-'} <span className="outof-phrase">/ 5</span></div>
                                </div>
                                <div className='stage-progress'>
                                    <div className='stage-progressbar'>
                                        <div className='stage-fillbar' style={{ width: area.progress + '%' }}></div>
                                    </div>
                                </div>
                            </div>
                        )
                    }

                    if (isFirstMinor) {
                        areaClassName += " IsFirstMinor";
                        isFirstMinor = false;
                    }

                    return (
                        <div className={areaClassName} key={area.name} >
                            <div className='stage-head waiting-head'>
                                <div className='icon'><img src={`skill-${area.name.toLowerCase()}.png`} className='plant-img icon-img' /></div>
                                <div className='stage-title'>{area.name}</div>
                                <div className='progress-number'>{area.score ? area.score : '-'}</div>
                            </div>
                            <div className='stage-progress waiting-progress'>
                                <div className='stage-progressbar'>
                                    <div className='stage-fillbar' style={{ width: area.progress + '%' }}></div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    );


}

export default AreaGrid;