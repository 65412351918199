import React, { useContext } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import Back from '../helpers/Back';
import User from '../helpers/User';
import LogThought from './LogThought';
import './StartingGoalsScreen.scss';

import GridLoader from "react-spinners/GridLoader";
import ContentLoader from 'react-content-loader';
import EmotionQuestion from './EmotionQuestion';
import EmotionsGradeSheet from './EmotionsGradeSheet';
import EmotionsCompletedSheet from './EmotionsCompletedSheet';
import { ClipLoader } from 'react-spinners';
import AiMessage from './AiMessage';
import UserContext from '../UserContext';
import WizardPop from './WizardPop';

function StartingGoalsScreen({ onCompleted }) {

    const { user, changeUser } = useContext(UserContext);

    const [showDoneButton, setShowDoneButton] = useState(false);

    const [isPop1Open, setIsPop1Open] = useState(true);
    const [isPop2Open, setIsPop2Open] = useState(false);
    const [isPop3Open, setIsPop3Open] = useState(false);

    const allGoalIdeas = [
        "Reduce social media time",
        "Start a garden",
        "Believe in myself more",
        'Launch a podcast',
        'Feel less anxious',
        "Get a raise",
        "Make 2 new friends",
        'Start dating again',
        "Have more confidence",
        'Take a coding course',
        'Be able to do 20 push-ups',
        "Meditate everyday",
        "Read 5 books",
        "Start a side hustle",
        "Do a 30-day yoga challenge",
        "Cook 10 new recipes",
        "Invest $10K in stocks",
        "Learn to draw",
        "Walk 10K steps everyday",
        "Do 10 acts of kindness",
        "Journal every morning",
        "Create a passive income stream",
        "Read every night"
    ];


    const ideasPerPage = 7;

    const [goals, setGoals] = useState([
        "", ""
    ]);

    const [goalIdeas, setGoalIdeas] = useState(allGoalIdeas.slice(0, ideasPerPage));
    const [ideaPage, setIdeaPage] = useState(0);


    useEffect(() => {
        if (goals[0].length > 5 &&
            goals[1].length > 5) {
            setShowDoneButton(true);
        } else {
            setShowDoneButton(false);
        }
    }, [goals]);

    const handleGoalsDoneClick = async () => {

        document.querySelector('.sheet-content').classList.add('IsPageLoading');
        const newUser = await Back.saveGoals(goals);
        await delay(900);
        document.querySelector('.sheet-content').classList.remove('IsPageLoading');

        console.log('goals response', newUser);
        newUser.popRound = 1;
        newUser.isStartingFirstThought = true;
        changeUser({ ...newUser });

        onCompleted();


    };


    const handleGoalChange = (e, goalIndex) => {
        console.log('handleGoalChange', e, goalIndex);

        const newGoals = [...goals];
        newGoals[goalIndex] = e.target.value;

        setGoals(newGoals);
    };

    const handleIdeaClick = (idea) => {
        const newGoals = [...goals];
        if (goals[0] === "") {
            newGoals[0] = idea;
            const newIdeas = removeStringFromArray(goalIdeas, idea);
            setGoalIdeas([...newIdeas]);

        } else {
            newGoals[1] = idea;
            const newIdeas = removeStringFromArray(goalIdeas, idea);
            setGoalIdeas([...newIdeas]);
        }
        setGoals(newGoals);
    };

    const handleRefreshClick = () => {
        let newPage = ideaPage + 1;

        let newIdeas = allGoalIdeas.slice(ideasPerPage * newPage, ideasPerPage * newPage + ideasPerPage);

        if (newIdeas.length < 1) {
            newPage = 0;
            newIdeas = allGoalIdeas.slice(ideasPerPage * newPage, ideasPerPage * newPage + ideasPerPage);
        }

        setIdeaPage(newPage);
        setGoalIdeas([...newIdeas]);
    };

    const animatePageLoading = async (time) => {
        if (!time) {
            time = 900;
        }

        document.querySelector('.sheet-content').classList.add('IsPageLoading');
        await delay(time);
        document.querySelector('.sheet-content').classList.remove('IsPageLoading');
    };
    async function delay(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    let className = "StartingGoalsScreen";

    if (showDoneButton) {
        className += " ShowDoneButton";
    }

    // if (showStartingMessage) {
    //     className += ' ShowStartingMessage';
    // }

    const exploreSheet = (
        <div>
            <div className='sheet-heading'>
                What are <span className='two-areas-phrase'>two goals</span> you have right now?
            </div>
            <div className='sheet ExplorePage'>
                <div className='sheet-content'>
                    {isPop1Open && <WizardPop name={'starting-goals'} positions={['right']} shift={[-12, 160]} isOpen={isPop1Open} onClose={() => { setIsPop1Open(false); }}></WizardPop>}

                    <div className='goals-instructions'>

                    </div>

                    <div className='goals-content'>

                        <div className='goals'>
                            {goals.map((goal, index) => {
                                const number = index + 1;
                                return (
                                    <div className='goal-row'>
                                        <div className='label'>Goal {number}</div>
                                        <input type="text" className="answer" placeholder='I want to...' value={goal} onChange={(e) => handleGoalChange(e, index)} />
                                    </div>
                                );
                            })}

                        </div>

                    </div>

                    <div className='suggest-content'>
                        <div className='suggest-heading'>
                            Suggestions
                        </div>
                        <div className='refresh-container3'>
                            <div className='RefreshIcon3' onClick={handleRefreshClick}>
                                <div className='refresh-fill3'>
                                    <img src="refresh-yellow.png" className='refresh-icon-img3' />
                                </div>
                            </div>
                        </div>
                        <div className='suggest-list'>
                            {goalIdeas.map(idea => {
                                return (
                                    <div className='idea-row' onClick={() => handleIdeaClick(idea)}>
                                        <div className='label'>{idea}</div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>

                    <div className='goals-done-button' onClick={handleGoalsDoneClick} tabIndex="0" onKeyDown={handleGoalsDoneClick}>Continue</div>

                    {/* <div className='goals-subheading'>
                Choose whichever appeals to you most right now. You can switch
                between areas of focus in the future.
            </div> */}
                </div>
            </div>
        </div>
    )

    return (
        <div className={className}>

            <div className='sidebar'>
                <div className='logo'>
                    <img src="air-logo2.png" className='logo-img' />
                </div>


            </div>

            <div className='main-layout'>

                <div className='ai-col'>
                    {/* <div className='AiGreeting'>

                        <div className='steps-content'>
                            <div className='steps'><span className="step-phrase">Step 1</span> of 2</div>
                            <div className='step-title'>
                                My Work Life
                            </div>

                            <div className='step-instructions'>
                                <p>
                                    Write a couple of sentences about how your
                                    job<br />and work life are going.
                                </p>
                                <p>
                                    <b>This is optional.</b><br />It helps Air identify
                                    your thought patterns faster.
                                </p>
                            </div>
                        </div>



                    </div> */}
                </div>

                <div className='grade-col'>
                    {exploreSheet}
                </div>

            </div>
        </div>
    );


}

function areRatingsCompleted(questions) {

    for (var q in questions) {
        console.log('are', questions[q].rating, questions[q]);
        if (!questions[q].rating) {
            return false;
        }
    }

    return true;
}

function removeStringFromArray(arr, str) {
    const index = arr.indexOf(str);
    if (index > -1) {
        arr.splice(index, 1);
    }
    return arr;
}

export default StartingGoalsScreen;
