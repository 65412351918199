import React, { useContext } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import Back from '../helpers/Back';
import User from '../helpers/User';
import LogThought from './LogThought';
import './StartMembershipScreen.scss';

import GridLoader from "react-spinners/GridLoader";
import ContentLoader from 'react-content-loader';
import EmotionQuestion from './EmotionQuestion';
import EmotionsGradeSheet from './EmotionsGradeSheet';
import EmotionsCompletedSheet from './EmotionsCompletedSheet';
import AiMessage from './AiMessage';
import SkillList from './SkillList';
import UserContext from '../UserContext';
import Confetti from 'react-dom-confetti';
import Select from 'react-select'

function StartMembershipScreen({ slam }) {

    const [monthlyCheckoutUrl, setMonthlyCheckoutUrl] = useState(null);
    const [yearlyCheckoutUrl, setYearlyCheckoutUrl] = useState(null);

    useEffect(async () => {

        const response = await Back.createCheckoutSession('Monthly');

        console.log('CHH response', response);

    }, []);


    return (
        <div className='StartMembership'>
            <div className='page-heading'>
                <span className='service-name'>Air Premium</span>  &bull;  Feel Better, Do Better.
            </div>
            <div className='sheet'>
                <div className='plans'>
                    <div className='plan-col free-plan-col'>
                        <div className='plan-summary'>
                            <img src="free-plan.png" className='free-plan-img' />
                            <div className='plan-title'>
                                Free
                            </div>
                        </div>
                        <div className='features'>
                            <div className='item'>Up to 3 sessions per week</div>
                            <div className='item'>Standard Air response speed</div>
                            <div className='item'>Air remembers last 30 days</div>
                            {/* <div className='item'>&nbsp;</div> */}
                        </div>
                    </div>
                    <div className='plan-col monthly-plan-col'>
                        <div className='plan-summary'>
                            <img src="lotus-flower.png" className='premium-img' />
                            <div className='plan-title'>
                                Monthly<br />Premium
                            </div>
                            <div className='plan-price plan-price-nudge'>
                                $20<span className='decimal'>.00</span>
                            </div>
                            <div className='plan-interval'>
                                billed monthly
                            </div>
                            <div className='plan-savings'>
                                &nbsp;
                            </div>
                            <div className='plan-cta'>
                                Try free for 7 days
                            </div>
                        </div>
                        <div className='features'>
                            <div className='item'><img src="checkmark-gold2.png" className='checkmark-img' /> Unlimited sessions</div>
                            <div className='item'><img src="checkmark-gold2.png" className='checkmark-img' /> Fastest Air response speed</div>
                            <div className='item'><img src="checkmark-gold2.png" className='checkmark-img' /> Air keeps learning about you</div>
                            {/* <div className='item'><img src="checkmark-gold2.png" className='checkmark-img' /> Message with your CBT coach</div> */}
                            {/* <div className='item'><img src="checkmark-gold2.png" className='checkmark-img' /> Type to Air</div> */}
                            {/* <div className='item'><img src="checkmark-gold2.png" className='checkmark-img' /> Try free for 7 days</div> */}
                        </div>
                    </div>
                    <div className='plan-col yearly-plan-col'>
                        <div className='plan-summary'>
                            <img src="lotus-flower-yearly.png" className='premium-img' />
                            <div className='plan-title'>
                                Yearly<br />Premium
                            </div>
                            <div className='plan-price'>
                                $199
                            </div>
                            <div className='plan-interval'>
                                billed annually
                            </div>
                            <div className='plan-savings'>
                                &nbsp;
                            </div>
                            <div className='plan-cta'>
                                Try free for 7 days
                            </div>
                        </div>
                        <div className='features'>
                            <div className='item'><img src="checkmark-gold2.png" className='checkmark-img' /> Unlimited sessions</div>
                            <div className='item'><img src="checkmark-gold2.png" className='checkmark-img' /> Fastest Air response speed</div>
                            <div className='item'><img src="checkmark-gold2.png" className='checkmark-img' /> Air keeps learning about you</div>
                            {/* <div className='item'><img src="checkmark-gold2.png" className='checkmark-img' /> Message with your CBT coach</div> */}
                            {/* <div className='item'><img src="checkmark-gold2.png" className='checkmark-img' /> Type to Air</div> */}
                            {/* <div className='item'><img src="checkmark-gold2.png" className='checkmark-img' /> Try free for 7 days</div> */}
                            {/* <div className='item'>Save 12%</div> */}
                        </div>
                    </div>
                    <div className='coach-col'>

                        {/* <div className='coach-heading'>
                            Your coach
                        </div>

                        <div className='CoachCard'>

                            <img src="coach-brad.png" className='coach-profile-img' />
                            <div className='name-label'>Brad</div>
                        </div>

                        <div className='coach-intro'>
                            <p>
                                I'm passionate about helping people transform
                                their lives using CBT, because I've seen
                                it work in my own life.
                            </p>
                            <p>
                                Before finding CBT, I was working a desk job I wasn't passionate for,
                                and experiencing anxiety and panic attacks
                                that restricted my personal life.
                            </p>
                            <p className='coach-read-more'>Read more</p>
                        </div> */}

                        {/* <div className='coach-intro'>
                            <p>
                                Before starting CBT, I was working a desk job I wasn't passionate for,
                                and experiencing anxiety and panic attacks
                                that restricted my personal life.
                            </p>
                            <p>
                                With CBT, I was able to quit my job and embrace the risks and rewards of entrepreneurship, achieving the financial
                                freedom and personal independence I always dreamed of.
                            </p>
                            <p>
                                Now I'm engaging fully with my desire to
                                help people transform their own lives by designing new technologies and
                                creating Air.
                                I'm excited to help you apply CBT to your own life
                                to radically expand it!
                            </p>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StartMembershipScreen;
