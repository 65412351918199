import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import Back from '../helpers/Back';
import './QuestionMeter.scss';

import User from '../helpers/User';

function QuestionMeter({ question, status, answer, onCompleted }) {

    const [isCompleted, setIsCompleted] = useState(false);
    //const [selectedOption, setSelectedOption] = useState(false);
    //const [status, setStatus] = useState(question.status);

    let options = [
        {
            value: 1,
            label: "Disagree"
        },
        {
            value: 2,
            label: "Somewhat disagree"
        },
        {
            value: 3,
            label: "It depends"
        },
        {
            value: 4,
            label: "Somewhat agree"
        },
        {
            value: 5,
            label: "Agree"
        }
    ];
    // options = [
    //     {
    //         value: 1,
    //         label: "Not at all"
    //     },
    //     {
    //         value: 2,
    //         label: "Not really"
    //     },
    //     {
    //         value: 3,
    //         label: "It depends"
    //     },
    //     {
    //         value: 4,
    //         label: "Mostly true"
    //     },
    //     {
    //         value: 5,
    //         label: "Very true"
    //     }
    // ];

    const handleOptionClick = (option) => {
        if (answer) {
            return;
        }

        //setSelectedOption(option);
        console.log('newPage onCompleted', question, option);
        onCompleted(question, option);
    };


    let className = "QuestionMeter ";

    // if (isCompleted) {
    //     className += " IsCompleted ";
    // }

    className += status;

    return (
        <div className={className}>
            <div className='question-label'>
                <div className='question-number'>{question.number}.</div>
                <div>
                    {question.label}
                </div>
            </div>
            <div className='options'>
                {options.map((option, index) => {

                    let thisClassName = "QuestionOption";

                    if (answer && answer.value === option.value) {
                        thisClassName += " Selected";
                    }

                    return (
                        <div className={thisClassName} onClick={() => handleOptionClick(option)}>
                            <div className='shapes'>
                                <div className={`track track-left-${index}`}></div>
                                <div className='circle'><div className='dot'></div></div>
                                <div className={`track track-right-${index}`}></div>
                            </div>
                            <div className='label'>{option.label}</div>
                        </div>
                    )
                })}
            </div>
        </div>
    )

}

export default QuestionMeter;