import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import Back from '../helpers/Back';
import User from '../helpers/User';
import LogThought from './LogThought';
import './ReframingLoadingGrid.scss';

import BeatLoader from "react-spinners/BeatLoader";
import ContentLoader from 'react-content-loader';

function ReframingLoadingGrid({ count }) {

    const items = [1, 2, 3, 4];

    return (
        <div className="ReframingList ReframingLoadingGrid">

            <div className='list'>
                {items.map(item => {
                    return (
                        <div className='item' key={item}>
                            <div className='option-content'>
                                <ContentLoader viewBox="0 0 400 260"
                                    height={180} width={240}
                                    backgroundColor="#f5c189"
                                    foregroundColor="#e4820e"
                                >
                                    <rect x="8" y="2" rx="4" ry="4" width="284" height="5" />
                                    <rect x="8" y="26" rx="3" ry="3" width="165" height="5" />




                                </ContentLoader>
                            </div>
                        </div>
                    )

                })}
            </div>
        </div>
    );


}

export default ReframingLoadingGrid;
