
import './App.scss';
import { useEffect, useReducer, useState } from 'react';
import Back from './helpers/Back';
import Cookies from 'js-cookie';
import ResponseComposer from './components/ResponseComposer';
import ThoughtGrid2 from './components/ThoughtGrid2';
import PostSessionScreen from './components/PostSessionScreen';
import AboutMeScreen from './components/AboutMeScreen';
import WelcomeScreen from './components/WelcomeScreen';
import UserContext from './UserContext';
import StartingGoalsScreen from './components/StartingGoalsScreen';

function App() {

  const [selectedThought, setSelectedThought] = useState(null)
  const [selectedTarget, setSelectedTarget] = useState(null);
  const [slam, setSlam] = useState(null);
  const [user, setUser] = useState(null);
  const [isSlamStarting, setIsSlamStarting] = useState(false);
  const [isSlamFinishing, setIsSlamFinishing] = useState(false);

  const [isSessionCardForceExpanded, setIsSessionCardForceExpanded] = useState(false);

  const [showAboutMeScreen, setShowAboutMeScreen] = useState(false);
  const [showStartingGoalsScreen, setShowStartingGoalsScreen] = useState(false);
  const [showWelcomeScreen, setShowWelcomeScreen] = useState(false);
  const [preQuestions, setPreQuestions] = useState(null);

  const [aboutSection, setAboutSection] = useState("Work");
  const [aboutEditSection, setAboutEditSection] = useState(null);

  const [isNewUser, setIsNewUser] = useState(null);

  const [isCookiePolicy, setIsCookiePolicy] = useState(true);

  useEffect(
    () => {

      const searchParams = new URLSearchParams(window.location.search.slice(1));
      if (searchParams.get("gate")) {
        Cookies.set("auth-token", searchParams.get("gate"), { expires: 365 });
        window.location.replace(window.location.origin);
      }

      if (window.location.href.toLowerCase().includes("terms")) {
        // The URL contains "terms" (case-insensitive)
        window.location.replace('https://app.termly.io/document/terms-of-service/a9d6d028-f823-47a6-b8a9-3cf3f9828316');

        // Do something here, like execute specific code for pages with "terms"  
      }
      if (window.location.href.toLowerCase().includes("privacy")) {
        // The URL contains "terms" (case-insensitive)
        window.location.replace('https://app.termly.io/document/privacy-policy/a271e8c4-eecf-47e2-8c76-516f27e2c09f');

        // Do something here, like execute specific code for pages with "terms"  
      }

      if (Back.isSignedIn()) {
        console.log('is signed in');
        loadUser();
        setIsNewUser(false);
        setShowAboutMeScreen(false);
        Back.recordEvent("ContinuedJourney");
      } else {
        setIsNewUser(true);


        // Create a new user async here.
        loadTemporaryUser();
        setShowAboutMeScreen(true);

        Back.recordEvent("BeganJourney");
      }

      // window.Intercom("boot", {
      //   api_base: "https://api-iam.intercom.io",
      //   app_id: "ecb5j1xr",
      //   //email: user.email, // the email for your user
      //   //created_at: , // Signup date as a Unix timestamp
      // });



    }, []
  );

  const handleGoToAbout = (section) => {
    Back.recordEvent("ClickedAboutWidget", section);
    setAboutSection(section);
    setAboutEditSection(section);
    setShowAboutMeScreen(true);
  };

  const handleDoneAbout = () => {
    setShowAboutMeScreen(false);
    setShowStartingGoalsScreen(true);
  };

  const handleDoneGoals = () => {
    setShowStartingGoalsScreen(false);
  };

  const loadTemporaryUser = async () => {
    const response = await Back.createTemporaryUser();

    if (!response) {
      return;
    }

    response.user.isNew = true;
    response.user.popRound = 1;
    response.user.isStartingFirstThought = true;

    console.log('temp response', response, response.user, response.token);

    setUser(response.user);
    Cookies.set("auth-token", response.token, { expires: 365 });
    Back.refreshToken();
  };

  const handleThoughtSelected = async (thought) => {
    setSelectedThought(thought);
  };

  const handleWelcomeCompleted = () => {
    setShowWelcomeScreen(false);
  };

  // const handleAboutMeCompleted = async () => {

  //   setIsSlamStarting(true);

  //   const newSlam = await Back.startSlam(preQuestions);

  //   setTimeout(() => {
  //     setShowAboutMeScreen(false);
  //     setIsSlamStarting(false);
  //     setSlam(newSlam);
  //     setSelectedTarget("Procrastination");
  //   }, 2500);
  // };

  const onThoughtCompleted = async () => {

    if (slam.completed_thoughts.length === 3) {
      setIsSessionCardForceExpanded(true);
    } else {
      setIsSessionCardForceExpanded(true);
    }

  };

  const handleStartThoughtClicked = () => {
    setSelectedThought(null);
    setIsSessionCardForceExpanded(false);
  };
  const handleFinishSessionClicked = () => {
    setSelectedThought(null);
    setIsSessionCardForceExpanded(false);

    setIsSlamFinishing(true);
  };

  const handlePostEmotionsCompleted = () => {

  };


  const loadUser = async () => {
    const response = await Back.user();

    const user = response;

    console.log('user', user);

    user.isNew = false;
    user.popRound = 0;

    setUser(user);

    if (user.slam) {
      setSlam(user.slam);
    } else {

    }

    // window.Beacon('identity', {
    //   email: user.email
    // });
    // window.Beacon('prefill', {
    //   email: user.email
    // });

    // setIsSessionCardForceExpanded(true);
    // UI.toastWriting();
  }

  function changeUser(user) {
    setUser(user);
  }

  function handlePop(popName, setter) {
    console.log('wer handle pop', popName);
    if (!user.pendingPops.includes(popName)) {
      console.log('wer pop not included');
      return;
    }

    user.pendingPops = user.pendingPops.filter(p => p !== popName);
    console.log('wer change user for pop', user);
    changeUser({ ...user });


    Back.saveContext("pendingPops", user.pendingPops);
    setter(true);
  }

  if (!user) {
    return (
      <div className="App"></div>
    );
  }

  return (
    <div className="App">
      <UserContext.Provider value={{ user, changeUser, handlePop }}>
        <div className="Mast">
          <div className='logo'>
            <img src="air-logo.png" className='logo-img' />
          </div>
        </div>

        <img src="logo-face-dimmed.png" className='logo-dimmed' />

        {(() => {
          if (isSlamFinishing) {
            return <PostSessionScreen slam={slam} onCompleted={handlePostEmotionsCompleted}></PostSessionScreen>
          }

          if (showWelcomeScreen) {
            return <WelcomeScreen onCompleted={handleWelcomeCompleted}></WelcomeScreen>
          }

          if (showStartingGoalsScreen) {
            return <StartingGoalsScreen onCompleted={handleDoneGoals}></StartingGoalsScreen>
          }

          if (showAboutMeScreen) {
            return <AboutMeScreen editSection={aboutEditSection} onCompleted={handleDoneAbout}></AboutMeScreen>
          }

          // if (!slam) {
          //   return <PreSessionScreen onCompleted={handleEmotionsCompleted} showStartingMessage={isSlamStarting}></PreSessionScreen>
          // }

          if (!selectedThought && !isSlamFinishing) {
            return <ThoughtGrid2 slam={user.slam} user={user} changeUser={changeUser} target={selectedTarget} onThoughtSelected={handleThoughtSelected} onAboutClick={handleGoToAbout}></ThoughtGrid2>
          }


        })()}

      </UserContext.Provider>
    </div >
  );
}

export default App;
