import React, { useContext } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import './SessionGrid.scss';
import ThoughtSetContent from './ThoughtSetContent';
import StackCard from './StackCard';
import UserContext from '../UserContext';
import UI from './UI';


function SessionGrid({ sessionThoughts, options, category, onSelection, isAnimatingCompletedThought, isPostSession }) {

    const { user, changeUser } = useContext(UserContext);

    const [rows, setRows] = useState(user.negative_rows);
    const [windowDimensions, setWindowDimensions] = useState(null);

    const completedThoughts = sessionThoughts.filter(item => item.status === "Completed");

    useEffect(() => {
        const handleResize = () => {
            setWindowDimensions({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    let className = "SessionGrid";

    className += " " + category;

    if (isPostSession) {
        className += " IsPostSession";
    }

    const totalColorCounts = completedThoughts.reduce(
        (values, slot) => {
            let row = slot.row;
            if (!row && slot.thought) {
                row = slot.thought.row;
            }
            let color = row.color;

            if (!values[color]) {
                values[color] = 1;
            } else {
                values[color] += 1;
            }

            console.log('values1', values);

            return values;
        },
        { 'Teal': 0, 'Yellow': 0, 'Pink': 0, 'Purple': 0 }
    );
    console.log('values2', totalColorCounts);


    let thoughtsClassName = 'thoughts';
    thoughtsClassName += ' TotalSlots' + completedThoughts.length;
    //thoughtsClassName += ' TotalSlots5 TotalSlots10';

    thoughtsClassName += ` TotalTeal${totalColorCounts['Teal']} TotalYellow${totalColorCounts['Yellow']} TotalPink${totalColorCounts['Pink']} TotalPurple${totalColorCounts['Purple']}`;

    const colorCounts = {};

    return (
        <div className={className} id="customThoughtContainer" style={{ height: UI.getDropSpaceHeight() }}>

            <div className='group-headings'>
                <div className='group-heading-row-bk'></div>
                <div className='group-heading-row-bk group-heading-row-bk-bottom'></div>
                {rows.map(row => {
                    let rowClassName = 'group-heading group-heading-' + row.color;
                    return (
                        <div className={rowClassName}>
                            {row.type === "Area" && (
                                <div className='widget-heading'>

                                    <div className='label'>{row.area.name}</div>
                                    <img src={`skill-${row.icon}.png`} className='icon'></img>



                                </div>
                            )}
                            {row.type === "Goal" && (
                                <div className='label'>{row.goal.label}</div>
                            )}
                        </div>
                    )
                })}

            </div>

            <div className={thoughtsClassName}>

                {sessionThoughts.map(slot => {

                    let className = "SessionThought ";

                    let row = slot.row;
                    if (!row && slot.thought) {
                        row = slot.thought.row;
                    }

                    if (slot.slide_in) {
                        console.log('qwe add slide in class', slot);
                        className += " SlideIn";
                    }
                    if (slot.slide_in_number) {
                        className += " SlideInNumber";
                    }

                    if (slot.number > 5) {
                        //className += " IsBonus";
                    }

                    let color = null;

                    if (slot.status === "Completed") {
                        color = row.color;

                        if (!colorCounts[color]) {
                            colorCounts[color] = 1;
                        } else {
                            colorCounts[color]++;
                        }
                    }

                    className += " Group" + slot.group + " ";
                    className += " Color" + color + " ";
                    className += " ColorCount" + colorCounts[color];

                    //className += " SlotNumber" + slot.number;


                    if (slot.status === "Completed") {
                        className += " SessionThoughtCompleted";
                        if (slot.thought.isNeutral || slot.thought.is_neutral) {
                            className += " IsNeutralCompleted";
                        }
                        return (
                            <div className={className} key={slot.number}>
                                <ThoughtSetContent slot={slot} isGrid={true}></ThoughtSetContent>
                            </div>
                        );
                    }


                    if (slot.thought && !slot.is_hidden && !isAnimatingCompletedThought) {
                        className += slot.thought.type;
                        className += " SessionThoughtActive";

                        if (slot.thought.isNeutral || slot.thought.is_neutral) {
                            className += " IsNeutral";
                        }



                        return (
                            <div className={className} key={slot.number}>
                                <div className='drop-space'>
                                    <div className='drop-card'>
                                        <div className='thought-content-active'>
                                            <div className='thought-label calc-lines-title'>
                                                {slot.thought.thought}
                                            </div>
                                            <div className='thought-label calc-lines-tester2' id="fontSizeTester"></div>

                                            {exploreItemCount && (
                                                <div className='pickup-progress'>

                                                    <div className='pickup-progressbar'>
                                                        <div className='pickup-fillbar' style={{ width: '50%' }}></div>
                                                    </div>
                                                    <div className='pickup-outof'>{option.exploreItems.length} <span className='end'>/ 6</span></div>

                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    }

                    className += " " + slot.status;

                    if (slot.is_hidden) {
                        className += " IsHidden";
                    }


                    if (slot.status === "Pending") {
                        return (
                            <div className={className} key={slot.number}>
                                <div className='pending-border'></div>
                            </div>
                        )
                    }

                    return (
                        <div className={className} key={slot.number}>
                            <div className='label'>{slot.number === 3 ? "" : ""}</div>
                        </div>
                    );
                })}

            </div>

            <div>

            </div>

        </div>
    );


}

export default SessionGrid;