
import Cookies from 'js-cookie';

export default {

    get: async () => {
        // return {
        //     id: 1,
        //     completedThoughts: [
        //         {
        //             thought: "I'm not creative enough",
        //             beliefRating: 50,
        //             responses: [
        //                 "I shouldn't discount my own creativity",
        //                 "I don't have to be perfect to have good ideas",
        //                 "I am not defined by a few bad ideas",
        //                 "Others have valued my ideas in the past"
        //             ]
        //         }
        //     ]
        // }

        let data = Cookies.get("user");

        try {
            data = JSON.parse(data);
        } catch (e) {
            data = null;
        }

        if (!data) {
            return {
                id: 1,
                progressLog: [

                ],
                completedThoughts: [
                    {
                        thought: "I'm not creative enough",
                        beliefRating: 50,
                        responses: [
                            "I shouldn't discount my own creativity",
                            "I don't have to be perfect to have good ideas",
                            "I am not defined by a few bad ideas",
                            "Others have valued my ideas in the past"
                        ]
                    }
                ]
            }
        }

        return data;
    },

    set: async (user) => {
        //console.log('user set', JSON.stringify(user));
        //console.log(user);

        const setResult = await Cookies.set("user", JSON.stringify(user));
        //console.log('setResult');
        //console.log(setResult);
    }
}