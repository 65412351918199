import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import Back from '../helpers/Back';
import './EmotionMeter.scss';

import User from '../helpers/User';

function EmotionMeter({ thought, onCompleted, isShort, isFirst, isReframing, isDual, isEmotion, newThought, fillColor, forceCheck }) {

    const [options, setOptions] = useState([]);
    const [currentResponse, setCurrentResponse] = useState('');
    const [responses, setResponses] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const [isLoadingInhaled, setIsLoadingInhaled] = useState(false);

    const [strikeWidth, setStrikeWidth] = useState(0);
    const [responseZoom, setResponseZoom] = useState(1);

    const [showSheet, setShowSheet] = useState(false);
    const [showCheck, setShowCheck] = useState(false);

    const [isHoverActive, setIsHoverActive] = useState(false);
    const [hoverGauge, setHoverGauge] = useState(null);
    const [completionMessageStatus, setCompletionMessageStatus] = useState("Closed");

    const [isCompleted, setIsCompleted] = useState(false);
    const [completedRating, setCompletedRating] = useState(null);

    var meterGauges = [
        100, 95, 90, 85, 80, 75, 70, 65, 60, 55, 50, 45, 40, 35, 30, 25, 20, 15, 10, 5, 0
    ];

    const loadOptions = async () => {
        console.log('loadOptions');
        const response = await Back.respondToThought(thought.thought, currentResponse);
        const items = response.items;

        setOptions(items);
        console.log('set options', items);

        setIsLoading(false);
    };

    const handleRefreshClick = async () => {
        setIsLoading(true);

        setCurrentResponse('none');
        const response = await Back.respondToThought(thought.thought, currentResponse);
        const items = response.items;

        setOptions(items);
        console.log('set options', items);

        setIsLoading(false);
    }

    const handleDoneClick = async () => {
        setShowSheet(true);
    };

    const handleMeterClick = async (gauge) => {
        const user = await User.get();

        console.log('handleMeter user get', user, gauge);

        const newThought = {
            id: Math.random(),
            thought: thought,
            beliefRating: gauge,
            responses: responses,
            completedAt: new Date()
        };

        user.completedThoughts.unshift(newThought);

        const result = await User.set(user);
        console.log('user set result', result);

        setCompletionMessageStatus("Opened");

        setShowCheck(true);

        setTimeout(() => {
            setCompletionMessageStatus("Opened");
            //onThoughtCompleted();
        }, 1000);
        setTimeout(() => {
            setCompletionMessageStatus("Closed");
            //onThoughtCompleted();
        }, 2000);

        setTimeout(() => {
            setIsCompleted(true);
            setCompletedRating(gauge);
            onCompleted(gauge);
        }, 50);

    };

    const onEnterGauge = (gauge) => {
        if (isCompleted) {
            return;
        }

        let numberStr = gauge.toString();
        if (numberStr.charAt(numberStr.length - 1) === '5') {
            return;
        }

        setHoverGauge(gauge);
    };

    const handleEnterMeter = () => {
        if (isCompleted) {
            return;
        }
        setIsHoverActive(true);
    };
    const handleLeaveMeter = () => {
        //return;
        if (isCompleted) {
            return;
        }
        setIsHoverActive(false);
        setHoverGauge(null);
    };

    const handleSentenceClick = (option) => {
        console.log('handleSentenceClick');
        // setCurrentResponse(
        //     (prev) => {
        //         return prev + ' ' + option;
        //     }
        // );
        //responses.push(option);

        setResponses(
            (prev) => {
                return [...prev, option];
            }
        );
        setStrikeWidth(
            (prev) => {
                var next = prev + 25;
                if (next > 100) {
                    next = 100;
                }
                return next;
            }
        );

        if (responses.length <= 3) {
            setResponseZoom(1);
        } else {
            setResponseZoom(0.95);
        }
        if (responses.length > 7) {
            setResponseZoom(0.925);
        }

        //setResponseZoom(1 - (responses.length * .01));



        // setTimeout(() => {
        //     loadOptions();
        // }, 200);
        console.log('handleSentenceClick2');
    };

    let className = 'EmotionMeter';
    if (showSheet) {
        className += ' ShowSheet';
    }
    if (isReframing) {
        className += ' IsReframing';
    }
    if (isShort) {
        className += ' IsShort';
    }
    if (isFirst) {
        className += ' IsFirst';
    }
    if (isHoverActive) {
        className += ' IsHoverActive';
    } else {
        className += ' IsHoverNotActive';
    }
    if (isCompleted) {
        className += ' IsCompleted';
    }
    if (fillColor) {
        className += " Fill" + fillColor;
    }
    if (showCheck) {
        className += " ShowCheck";
    }

    let multiplier = isShort ? 0.84583 : 1;
    let baseLeft = isShort ? 0 : 10;
    const floatingThoughtTop = baseLeft + (hoverGauge / 5 * (19.6 * multiplier));
    //const floatingThoughtTop = 50;
    //const floatingThoughtTopNew = -142 - (hoverGauge / 5 * 16.6) + 35;
    //const floatingThoughtTopNew = -66 - ((100 - hoverGauge) / 5 * 16.6);
    const floatingThoughtTopNew = -370;
    //const floatingThoughtTop = -400;

    const topGaugeAddClass = hoverGauge < 100 ? 'HoverGauge' : '';
    const bottomGaugeAddClass = hoverGauge > 0 ? 'HoverGauge' : '';

    const ratingPhrases = {
        "0": "Not at all",
        "10": "Very wrong",
        "20": "Mostly wrong",
        "30": "Not really",
        "40": "Not quite",
        "50": "It depends",
        "60": "Somewhat true",
        "70": "Mostly true",
        "80": "Mostly true",
        "90": "Very true",
        "100": "Completely true"
    };
    const ratingPhrase = ratingPhrases[hoverGauge + ""];

    return (
        <div className={className} onMouseLeave={handleLeaveMeter}>

            {/* <div className='meter-question'>
                {thought.thought}
            </div> */}

            <div className='meter-thought-layout'>
                <div className='meter-thought-col'>
                    <div className='meter-thought-repeat'>
                        {thought.thought}
                    </div>
                    <div className={`meter-thought-repeat-number rating-${hoverGauge}`}>{hoverGauge}%</div>
                </div>
                <div className='meter-thought-col'>
                    <div className='meter-thought-repeat meter-thought-repeat-new'>
                        {/* {newThought} */}
                        TEMPI'm making progress daily
                    </div>
                    <div style={{ opacity: (100 - hoverGauge) / 100 + 1 }} className='meter-thought-repeat-number meter-thought-repeat-number-new'>{100 - hoverGauge}%</div>
                </div>
            </div>


            <div className='meter-fill' onMouseOver={handleEnterMeter} >
                <div className={`meter-gauge meter-gauge-top ${topGaugeAddClass}`}></div>
                {meterGauges.map(
                    (g) => {
                        let className = 'meter-gauge';
                        if (g <= hoverGauge) {
                            className += ' HoverGauge';
                            //className += ' HoverGauge' + g;
                        } else {
                            className += ' OffGauge';
                        }
                        if (g === hoverGauge) {
                            className += ' CurrentHoverGauge';
                        }
                        if (g === 0 && hoverGauge === 0) {
                            className = 'meter-gauge ZeroHoverGauge';
                        }
                        if (newThought &&
                            g > hoverGauge
                            //&& g <= (100 - hoverGauge)
                        ) {
                            className += ' NewThoughtHoverGauge';
                        }
                        if (g === 100) {
                            className += ' HundredGauge';
                        }

                        return (
                            <div className={className} onClick={() => handleMeterClick(g)} onMouseOver={() => onEnterGauge(g)} key={g}>
                                {showMeterLabel(g) && (
                                    <div className='label'>{g}</div>
                                )}

                            </div>
                        )
                    }
                )}
                <div className={`meter-gauge meter-gauge-bottom ${bottomGaugeAddClass}`}></div>
            </div>

            <div className='floating-thoughts-container'>
                {/* <div className={`floating-thought floating-thought-new`} style={{top:floatingThoughtTopNew + 'px'}}>{100 - hoverGauge}%</div> */}
                <div className={`floating-thought floating-thought-old rating-${hoverGauge}`} style={{ left: floatingThoughtTop + 'px' }}>

                    <div className='bubble number-bubble'>
                        {showCheck && <img src="checkmark-white.png" className='floating-checkmark-img' />}
                        <div className='floating-label'>{hoverGauge}%</div>
                    </div>

                    <div className='bubble more-bubble'>
                        {hoverGauge}%
                    </div>

                </div>
            </div>

            <div className='labels-content' style={{ opacity: 0 }}>
                <div className='label'>Not at all</div>
                <div className='label label-end'>Completely</div>
            </div>

            {/* <div className='floating-thoughts-container'>
                            <div className={`floating-thought-bubble floating-thought-bubble-new`} style={{top:floatingThoughtTopNew - 6 + 'px'}}><span className='thought-text'>{newThought}</span></div>
                            <div className={`floating-thought-bubble floating-thought-bubble-old`} style={{top:floatingThoughtTop - 14 + 'px'}}><span className='thought-text'>{thought.thought}</span></div>
                        </div> */}




            {/* <div className='meter-reading'>{hoverGauge}</div> */}

        </div >
    );
}

function showMeterLabel(g) {
    var values = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
    var values = [0, 20, 40, 60, 80, 100];

    var values = [0, 20, 40, 60, 80, 100];

    return values.indexOf(g) >= 0;
}

export default EmotionMeter;