import React, { useContext, useEffect, useState } from 'react';
import './NegativeGrid.scss';

import GridLoader from "react-spinners/GridLoader";
import ContentLoader from 'react-content-loader';
import SuggestionsSheet from './SuggestionsSheet';
import AiMessage from './AiMessage';
import Back from '../helpers/Back';
import WizardPop from './WizardPop';
import UserContext from '../UserContext';
import AreaLog from './AreaLog';
import AreaFilters from './AreaFilters';
import QuestionsSheet from './QuestionsSheet';
import GoalSheet from './GoalSheet';

function NegativeGrid({ onThoughtSelected, sessionThoughts, onAboutClick }) {

    const { user, changeUser } = useContext(UserContext);

    const defaultRows = (user) => {
        return [...user.negative_rows];
    };

    const [options, setOptions] = useState([]);
    const [optionsGroup1, setOptionsGroup1] = useState([]);
    const [optionsGroup2, setOptionsGroup2] = useState([]);
    const [optionsGroup3, setOptionsGroup3] = useState([]);

    const [rows, setRows] = useState(defaultRows(user));

    const [isLoading, setIsLoading] = useState(false);
    const [reframingOptions, setReframingOptions] = useState([]);

    const [selectedGoal, setSelectedGoal] = useState(user.goals[0]);

    const [isPop1Open, setIsPop1Open] = useState(false); // Set in useEffect after intro ends
    const [isPop2Open, setIsPop2Open] = useState(false);
    const [isPop2bOpen, setIsPop2bOpen] = useState(false);
    const [isPop3Open, setIsPop3Open] = useState(false);

    const [isPop4Open, setIsPop4Open] = useState(false);
    const [isPop5Open, setIsPop5Open] = useState(false);
    const [isPop6Open, setIsPop6Open] = useState(false);
    const [isPop7Open, setIsPop7Open] = useState(false);
    const [isPop8Open, setIsPop8Open] = useState(false);

    const questions = user.slam_questions;

    const handleThoughtClick = async (thought) => {
        onThoughtSelected(thought);
    };

    const areasLog = [
        { name: "Motivation", isFocused: true, score: 3.9, progress: 60 },
        { name: "Focus", isFocused: true, score: 3.1, progress: 24 },
        { name: "Mood", isFocused: false, score: null, progress: 52 },
        { name: "Ease", isFocused: false, score: null, progress: 12 },
        { name: "Purpose", isFocused: false, score: 2.7, progress: 22 },
        { name: "Openness", isFocused: false, score: 1.9, progress: 82 }
    ];

    useEffect(() => {
        loadOptions();

        // Not being used anymore.
        // setTimeout(() => {
        //     if (user.popRound === 1) {
        //         setIsPop1Open(true); // TODO: TESTING DISABLED
        //     }
        // }, 11500 + 1250);
    }, []);

    useEffect(() => {
        console.log('wer pop check', user.popRound === 1 && user.isStartingFirstThought, user);
        if (user.popRound === 1 && user.isStartingFirstThought) {
            setIsPop1Open(true);

            user.isStartingFirstThought = false;
            changeUser({ ...user });
        }
    }, [user]);

    useEffect(() => {
        if (user.popRound === 2) {
            setIsPop4Open(true);
            user.popRound = 3;
        }
        // if (user.popRound === 3) {
        //     setIsPop3Open(true);
        // }
    }, [user]);

    const handleRefreshRequested = () => {
        for (var i = 0; i < rows.length; i++) {
            const row = rows[i];
            rows[i].items = [];
        }
        setRows(rows);

        loadOptions();
    };

    const loadOptions = async () => {
        setIsLoading(true);

        for (var i = 0; i < rows.length; i++) {
            const row = rows[i];

            setTimeout(
                async () => {
                    const response = await Back.getNegativeThoughts(row);

                    row.items = response.items;

                    const rowIndex = rows.findIndex(r => r.number === row.number);

                    const newRows = [...rows];
                    newRows[rowIndex] = { ...row };

                    setRows(newRows);
                    setIsLoading(false);

                    //const flatThoughts = response.items.map(obj => obj.thought);
                    //Back.recordEvent("SawNegativeThoughts", "Work", null, flatThoughts);
                }, 0
            );
        }

        return;

        setTimeout(
            async () => {
                const response = await Back.getNegativeThoughts("Work", selectedGoal.id);

                setOptionsGroup1(response.items);
                setIsLoading(false);

                const flatThoughts = response.items.map(obj => obj.thought);
                Back.recordEvent("SawNegativeThoughts", "Work", null, flatThoughts);
            }, 0
        );

        setTimeout(
            async () => {
                const response = await Back.getNegativeThoughts("Personal", selectedGoal.id);

                setOptionsGroup2(response.items);
                setIsLoading(false);

                const flatThoughts = response.items.map(obj => obj.thought);
                Back.recordEvent("SawNegativeThoughts", "Personal", null, flatThoughts);
            }, 0
        );

        setTimeout(
            async () => {
                const response = await Back.getReinforcingThoughts();

                setOptionsGroup3(response.items);
            }, 0
        );
    };

    const handleRefresh = async (category) => {
        Back.recordEvent("ClickedNegativeRefresh");

        setIsLoading(true);

        if (category === "Work") {
            const previousResponses = optionsGroup1;
            setOptionsGroup1([]);
            const response = await Back.getNegativeThoughts("Work", selectedGoal.id, previousResponses);
            setOptionsGroup1(response.items);
        }
        if (category === "Personal") {
            const previousResponses = optionsGroup2;
            setOptionsGroup2([]);
            const response = await Back.getNegativeThoughts("Personal", selectedGoal.id, previousResponses);
            setOptionsGroup2(response.items);
        }
        if (category === "Reinforce") {
            const previousResponses = optionsGroup3;
            setOptionsGroup3([]);
            const response = await Back.getReinforcingThoughts();
            setOptionsGroup3(response.items);
        }

        setIsLoading(false);

        Back.recordEvent("FinishedNegativeRefresh");
    };

    const handleGoalChange = (goal) => {
        Back.recordEvent("ChangedGoal", goal.name);

        setOptionsGroup1([]);
        setOptionsGroup2([]);
        setIsLoading(true);
        setSelectedGoal(goal);

        setTimeout(
            async () => {
                const response = await Back.getNegativeThoughts("Work", goal.id);

                setOptionsGroup1(response.items);
                setIsLoading(false);
            }, 10
        );

        setTimeout(
            async () => {
                const response = await Back.getNegativeThoughts("Personal", goal.id);

                setOptionsGroup2(response.items);
                setIsLoading(false);
            }, 10
        );
    };

    let className = "NegativeGrid";

    return (
        <div className={className}>

            {isPop1Open && <WizardPop name={'welcome-to-session'} positions={['left']} shift={[8, 200]} isOpen={isPop1Open} onClose={() => { setIsPop1Open(false); setTimeout(() => setIsPop2Open(true), 100) }}></WizardPop>}
            {isPop2Open && <WizardPop name={'welcome-to-session-2'} positions={['left']} shift={[12, 140]} isOpen={isPop2Open} onClose={() => { setIsPop2Open(false); setTimeout(() => setIsPop2bOpen(true), 100) }}></WizardPop>}
            {isPop2bOpen && <WizardPop name={'welcome-to-session-2b'} positions={['left']} shift={[376, 170]} isOpen={isPop2bOpen} onClose={() => { setIsPop2bOpen(false); setTimeout(() => setIsPop3Open(true), 100) }}></WizardPop>}
            {isPop3Open && <WizardPop name={'welcome-to-session-3'} positions={['left']} shift={[8, 300]} isOpen={isPop3Open} onClose={() => { setIsPop3Open(false); }}></WizardPop>}
            {isPop4Open && <WizardPop name={'back-to-start-1'} positions={['right']} shift={[-830, 140]} isOpen={isPop4Open} onClose={() => { setIsPop4Open(false); setTimeout(() => setIsPop5Open(true), 100) }}></WizardPop>}
            {isPop5Open && <WizardPop name={'back-to-start-2'} positions={['right']} shift={[-1232, 120]} isOpen={isPop5Open} onClose={() => { setIsPop5Open(false); setTimeout(() => setIsPop6Open(true), 100) }}></WizardPop>}
            {isPop6Open && <WizardPop name={'back-to-start-3'} positions={['right']} shift={[-1232, 120]} isOpen={isPop6Open} onClose={() => { setIsPop6Open(false); setTimeout(() => setIsPop7Open(true), 100) }}></WizardPop>}
            {isPop7Open && <WizardPop name={'back-to-start-4'} positions={['right']} shift={[-1232, 560]} isOpen={isPop7Open} onClose={() => { setIsPop7Open(false); setTimeout(() => setIsPop8Open(true), 100) }}></WizardPop>}
            {isPop8Open && <WizardPop name={'back-to-start-5'} positions={['left']} shift={[-0, 140]} isOpen={isPop8Open} onClose={() => { setIsPop8Open(false) }}></WizardPop>}

            <div className='greeting-content'>
                <div className='greeting-bubble'>
                    <img src="work-about-head.png" className='plant-img' />
                    <div className='greeting-label'>Day 1 <span className='date-phrase'> &bull; June 28</span></div>
                </div>
            </div>

            <div className='suggestions-content'>

                <div className='headings'>
                    <div className='heading-column heading-column-1'>
                        Reframe
                    </div>
                    <div className='heading-column heading-column-2'>
                        Manifest
                    </div>
                </div>

                <div className='group-col'>
                    {/* <SuggestionsSheet options={optionsGroup1} color={"1"} goal={"I want to make $100K"} category={"Work"} sessionThoughts={sessionThoughts} onSelection={handleThoughtClick} onRefreshRequested={handleRefresh} onAboutClick={onAboutClick}></SuggestionsSheet>
                    <SuggestionsSheet options={optionsGroup2} color={"2"} goal={"I want to make more friends"} category={"Work"} sessionThoughts={sessionThoughts} onSelection={handleThoughtClick} onRefreshRequested={handleRefresh} onAboutClick={onAboutClick}></SuggestionsSheet>
                    <SuggestionsSheet options={optionsGroup1} color={"3"} assessmentRound={user.active_assessments[0]} category={"Work"} sessionThoughts={sessionThoughts} onSelection={handleThoughtClick} onRefreshRequested={handleRefresh} onAboutClick={onAboutClick}></SuggestionsSheet>
                    <SuggestionsSheet options={optionsGroup2} color={"4"} assessmentRound={user.active_assessments[1]} category={"Work"} sessionThoughts={sessionThoughts} onSelection={handleThoughtClick} onRefreshRequested={handleRefresh} onAboutClick={onAboutClick}></SuggestionsSheet> */}
                    {/* <SuggestionsSheet options={optionsGroup2} color={"2"} category={"Work"} sessionThoughts={sessionThoughts} onSelection={handleThoughtClick} onRefreshRequested={handleRefresh} onAboutClick={onAboutClick}></SuggestionsSheet> */}

                    {rows.map(row => <SuggestionsSheet row={row} sessionThoughts={sessionThoughts} onSelection={handleThoughtClick} onRefreshRequested={handleRefresh} onAboutClick={onAboutClick} key={row.number}></SuggestionsSheet>)}

                </div>

                <div className='refresh-container2'>
                    <div className='RefreshButton' onClick={handleRefreshRequested}>
                        <div className='refresh-fill'>
                            <img src="refresh-yellow.png" className='refresh-icon-img' />
                        </div>
                    </div>
                </div>

            </div>

            <div className='suggestions-sheet'>

                <div className="options">

                    <div className='category-headings'>
                        <div className='category-col'>
                            <div className='category-heading'>Work</div>
                        </div>
                        <div className='category-col'>
                            <div className='category-heading category-heading-personal'>Personal</div>
                        </div>
                    </div>

                    {options.map(option => {
                        let className = 'option ';

                        className += option.type;

                        return (
                            <div className={className} onClick={() => handleThoughtClick(option)} key={option.thought}>
                                <div className='icon-content'></div>
                                <div className='label-content'>{option.thought}</div>
                            </div>
                        )
                    })}
                    <div className='clearfix'></div>

                    {isLoading && (
                        <div className='loading-grid'>
                            <div className='negative-loading-placeholders'>
                                {[1, 2, 3, 4, 5, 6, 7, 8, 9].map(
                                    p => {
                                        return (
                                            <div className='negative-loading-placeholder' key={p}>
                                                <ContentLoader viewBox="0 0 400 160"
                                                    height={120} width={180}
                                                    backgroundColor="#babcf9"
                                                    foregroundColor="#575ac2"
                                                >
                                                    <rect x="110" y="21" rx="4" ry="4" width="254" height="7" />
                                                    <rect x="145" y="41" rx="3" ry="3" width="185" height="7" />
                                                </ContentLoader>
                                            </div>
                                        )
                                    }
                                )}

                            </div>
                        </div>
                    )}
                </div>


            </div>


        </div >
    );


}

export default NegativeGrid;
