import React, { useContext } from 'react';
import { useState, useEffect } from 'react';
import './AssessmentsBadge.scss';
import UserContext from '../UserContext';

function AssessmentsBadge({ }) {

    const { user, changeUser } = useContext(UserContext);

    let credits = user.context.reassessmentCredits;
    if (!credits) {
        credits = 0;
    }

    let className = 'AssessmentsBadge';

    return (
        <div className={className}>
            {credits}
        </div>
    );
}

export default AssessmentsBadge;
