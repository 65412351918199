import { wait } from '@testing-library/user-event/dist/utils';
import React, { useContext, useState, useEffect, useRef } from 'react';
import { act } from 'react-dom/test-utils';
import Back from '../helpers/Back';
import UserContext from '../UserContext';
import EmotionMeter from './EmotionMeter';

import './PickGoalsDrawer.scss';
import QuestionMeter from './QuestionMeter';
import WizardPop from './WizardPop';

function PickGoalsDrawer({ onStartingAssessmentsCompleted }) {

    const { user, changeUser } = useContext(UserContext);
    const ref = useRef();

    const [opacity, setOpacity] = useState('0.5');
    const [lockStyle, setLockStyle] = useState(false);

    const [step, setStep] = useState("Goals");
    const [selectedGoals, setSelectedGoals] = useState([]);

    const [activeAssessment, setActiveAssessment] = useState(null);

    const [nextAssessmentArea, setNextAssessmentArea] = useState(null);

    const [isPop1Open, setIsPop1Open] = useState(false);
    const [isPop2Open, setIsPop2Open] = useState(false);
    const [isPop3Open, setIsPop3Open] = useState(false);

    const isReassess = user.PickGoalsDrawer === "Reassess";
    const isStart = !isReassess;

    const [isFirstAssessment, setIsFirstAssessment] = useState(isStart);

    //console.log('isFirstAssessment', isFirstAssessment);

    function handleCloseClick() {
        if (isStart) {
            return;
        }

        setOpacity('0.5');
        setLockStyle(true);

        setTimeout(() => {
            user.PickGoalsDrawer = null;
            changeUser({ ...user });
            setLockStyle(false);
        }, 100);
    };

    const handleGoalClick = async (goal) => {

        if (isReassess) {
            console.log('reassess', goal);
            setSelectedGoals([goal.name, goal.name]);
            await handleGoalsDoneClick(null, goal.name);
            setStep("Assessment");
            return;
        }

        if (selectedGoals.includes(goal.name)) {
            setSelectedGoals((prev) => {
                const newGoals = prev.filter(g => g !== goal.name)
                return [...newGoals];
            });
            return;
        }

        if (selectedGoals.length === 2) {
            // if (selectedGoals.includes(goal.name)) {
            //     setSelectedGoals((prev) => {
            //         const newGoals = prev.filter(g => g !== goal.name)
            //         return [...newGoals];
            //     });
            // }
            return;
        }

        setSelectedGoals((prev) => {
            const newGoals = [...prev, goal.name];
            return [...newGoals];
        });
    };

    const handleGoalsDoneClick = async (e, areaName) => {
        console.log('handleGoalsDoneClick', areaName);
        await animatePageLoading(500);

        if (!areaName) {
            areaName = selectedGoals[0];
        }
        if (isStart) {
            setNextAssessmentArea(selectedGoals[1]);
        }

        let newAssessment = {
            areaName: areaName,
            name: areaName,
            page: 0,
            pageQuestions: [],
            completedQuestions: [],
            questions: [...areaQuestions[areaName]]
        };
        newAssessment = await goToNextPage(newAssessment);

        setStep("Assessment");
        setActiveAssessment(newAssessment);

        if (isStart && isFirstAssessment) {
            setIsPop1Open(true);
        }
    };

    const handleAssessmentDoneClick = async (e) => {
        const response = await Back.saveAssessment(
            activeAssessment.areaName,
            activeAssessment.gradedScore,
            isStart
        );

        console.log('handleAssessmentDoneClick', activeAssessment);

        if (isStart) {

            // If user just completed their first assessment 
            // but still needs to do their second one now...
            if (activeAssessment.areaName === selectedGoals[0]) {
                console.log('isStart1');
                const areaName = selectedGoals[1];
                let newAssessment = {
                    areaName: areaName,
                    name: areaName,
                    page: 0,
                    pageQuestions: [],
                    completedQuestions: [],
                    questions: [...areaQuestions[areaName]]
                };
                console.log('newAssessment', newAssessment);
                newAssessment = await goToNextPage(newAssessment);
                console.log('newAssessmentAfter', newAssessment);
                setActiveAssessment(newAssessment);
                setStep("Assessment");
            }

            // Or if the user just completed their second assessment 
            // is totally done now.
            else {
                console.log('isStart2');
                user.PickGoalsDrawer = false;
                user.hasCompletedIntro = true;
                changeUser({ ...user });
                onStartingAssessmentsCompleted();

                setTimeout(() => {
                    setStep("Goals");
                    setActiveAssessment(null);
                    setIsFirstAssessment(false);
                }, 1000);
            }
        }
    };

    const goToNextPage = async (assessment) => {
        await animatePageLoading(500);

        const newAssessment = { ...assessment };

        const waitingQuestions = newAssessment.questions.filter(q => q.status === "Waiting");
        const pageQuestions = waitingQuestions.slice(0, 4);

        const firstQuestionIndex = pageQuestions.findIndex(q => q.status === "Waiting");

        if (firstQuestionIndex < 0) {
            newAssessment.isCompleted = true;
            return newAssessment;
        }

        pageQuestions[firstQuestionIndex].status = "Active";

        newAssessment.page += 1;
        newAssessment.pageQuestions = pageQuestions;

        return newAssessment;
    };

    const handleAssessmentCompleted = (assessment) => {

    };

    const handleQuestionCompleted = async (question, option) => {
        question.status = 'Completed';
        question.answer = option;

        const newPageQuestions = [...activeAssessment.pageQuestions];

        const questionIndex = newPageQuestions.findIndex(q => q.number === question.number);
        newPageQuestions[questionIndex] = { ...question };

        const newQuestionIndex = newPageQuestions.findIndex(q => q.status === "Waiting");

        if (newQuestionIndex < 0) {
            console.log('setIsFirstAssessment top', isFirstAssessment);
            activeAssessment.pageQuestions = newPageQuestions;
            activeAssessment.completedQuestions.push(question);
            setActiveAssessment({ ...activeAssessment }); // Redundant but needed for visual style change of last question before turning question.

            const newAssessment = await goToNextPage(activeAssessment);

            if (newAssessment.isCompleted) {
                gradeAssessment(newAssessment);
                console.log('gradeAssessment done', newAssessment);
                setStep("Score");

                console.log('pop2', isStart, isFirstAssessment);
                if (isStart && isFirstAssessment) {
                    setIsPop2Open(true);
                }

                console.log('setIsFirstAssessment false');
                setIsFirstAssessment(false);
            }

            setActiveAssessment({ ...newAssessment });
            return;
        }

        newPageQuestions[newQuestionIndex].status = 'Active';

        activeAssessment.pageQuestions = newPageQuestions;
        activeAssessment.completedQuestions.push(question);
        setActiveAssessment({ ...activeAssessment });
    };

    const gradeAssessment = (assessment) => {

        const totalScore = assessment.completedQuestions.reduce(
            (aggregate, q) => {
                return parseInt(aggregate) + parseInt(q.answer.value);
            }, 0
        );

        const gradedScore = (totalScore / (12)).toFixed(1) - .3;

        assessment.totalScore = totalScore;
        assessment.gradedScore = gradedScore;

        return assessment;
    };

    const animatePageLoading = async (time) => {
        if (!time) {
            time = 900;
        }

        document.querySelector('.PickGoalsDrawer').classList.add('IsPageLoading');
        await delay(time);
        document.querySelector('.PickGoalsDrawer').classList.remove('IsPageLoading');
    };

    async function delay(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                handleCloseClick();
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, user, changeUser]);

    useEffect(() => {

    }, []);

    let className = 'PickGoalsDrawer drawer';

    if (selectedGoals.length === 2 && !isReassess) {
        className += " HasSelectedGoals";
    }
    if (isStart) {
        className += " IsStart";
    }
    if (isReassess) {
        className += " IsReassess";
    }

    if (!user) {
        return (<div></div>);
    }
    if (!user.PickGoalsDrawer) {
        return (<div></div>);
    }

    if (!lockStyle && opacity === "0.5") {
        setTimeout(() => {
            console.log('set opacity 1');
            setOpacity("1");
        }, 50);
    }

    const goals = [
        {
            id: 3,
            title: "Focus",
            name: "Focus",
            description: "Reignite your focus and reduce mental friction"
        },

        {
            id: 2,
            title: "Motivation",
            name: "Motivation",
            description: "Boost your natural source of creative, productive energy"
        },
        {
            id: 1,
            title: "Mood",
            name: "Mood",
            description: "Experience more spontaneous joy and less slumps"
        },
        {
            id: 4,
            title: "Ease",
            name: "Ease",
            description: "Ease anxiety and nourish a natural way of being"
        },
        {
            id: 5,
            title: "Purpose",
            name: "Purpose",
            description: "Unlock authenticity, purpose, and direction for yourself"
        },
        {
            id: 6,
            title: "Openness",
            name: "Openness",
            description: "Open yourself to deeper relationships and experiences"
        }
    ]

    const parentStyles = {};
    //parentStyles.right = '0px';
    parentStyles.opacity = opacity;
    //parentStyles.left = opacity === "0.5" ? "0px" : "0px"

    //console.log('parentStyles', parentStyles);

    //user.completeProjectDrawer = user.projects[0];

    const areaQuestions = {
        "Focus": [
            {
                number: 1,
                status: "Waiting",
                label: "I’m able to limit distractions during most of my workday."
            },
            {
                number: 2,
                status: "Waiting",
                label: "When I do get distracted during the day, I can usually get back on track without too much trouble."
            },
            {
                number: 3,
                status: "Waiting",
                label: "When I have a difficult project that requires a lot of mental effort, I have ways to apply myself and make progress."
            },
            {
                number: 4,
                status: "Waiting",
                label: "I have good ways to recharge throughout the day that leave me feeling energized and ready to focus again."
            },
            {
                number: 5,
                status: "Waiting",
                label: "Getting myself to stay focused during the day doesn’t feel like a constant battle with myself."
            },
            {
                number: 6,
                status: "Waiting",
                label: "When I need to focus on a work task, I typically don't experience \"brain fog\" or difficulty doing so."
            },
            {
                number: 7,
                status: "Waiting",
                label: "When I'm trying to enjoy something, I can usually focus on the experience and soak it in."
            },
            {
                number: 8,
                status: "Waiting",
                label: "For the most part, I feel like I'm \"in control\" of my mind and not the other way around."
            },
            {
                number: 9,
                status: "Waiting",
                label: "While I’m not perfect, I have healthy habits regarding how I use my phone and devices."
            },
            {
                number: 10,
                status: "Waiting",
                label: "Even when things don't go expected."
            },
            {
                number: 11,
                status: "Waiting",
                label: "Relationships, emotions."
            },
            {
                number: 12,
                status: "Waiting",
                label: "Future-oriented."
            }
        ],
        "Mood": [
            {
                number: 1,
                status: "Waiting",
                label: "I wake up feeling excited about the day most mornings."
            },
            {
                number: 2,
                status: "Waiting",
                label: "At least once or twice a day, I feel a spontaneous burst of joy, energy, or confidence."
            },
            {
                number: 3,
                status: "Waiting",
                label: "I have a strong understanding of what creates bad moods for myself."
            },
            {
                number: 4,
                status: "Waiting",
                label: "When I hit my lowest of lows, I feel bad, but not totally helpless and hopeless."
            },
            {
                number: 5,
                status: "Waiting",
                label: "For most of the hours in the day, I feel like I have enough energy to do the things I want to do."
            },
            {
                number: 6,
                status: "Waiting",
                label: "When I get into a slump, it usually doesn’t last all day."
            },
            {
                number: 7,
                status: "Waiting",
                label: "I’m aware of the behaviors that often create bad moods for myself, and am able to avoid or manage those behaviors most days."
            },
            {
                number: 8,
                status: "Waiting",
                label: "I have good ways to take a break and recharge throughout the day that leave me feeling energized and positive."
            },
            {
                number: 9,
                status: "Waiting",
                label: "I’m optimistic that in the future, I'll be able to manage my mood and feel good most days."
            },
            {
                number: 10,
                status: "Waiting",
                label: "A year from now, I see myself experiencing high energy and many joyful days."
            },
            {
                number: 11,
                status: "Waiting",
                label: "I know that, overall, I have a lot of control over how I feel by working with my thoughts and managing my behavior."
            },
            {
                number: 12,
                status: "Waiting",
                label: "Even if I experience setbacks or have to make adjustments, nothing will prevent me from experiencing lots of joy in the future."
            }
        ],
        "CreativeFlow": [
            {
                "number": 1,
                "status": "Waiting",
                "label": "I feel energized and motivated when working on creative projects."
            },
            {
                "number": 2,
                "status": "Waiting",
                "label": "I feel a sense of accomplishment after completing creative work."
            },
            {
                "number": 3,
                "status": "Waiting",
                "label": "I am confident in my ability to generate new and useful ideas."
            },
            {
                "number": 4,
                "status": "Waiting",
                "label": "I may have high standards, but I don’t let perfectionism overwhelm me."
            },
            {
                "number": 5,
                "status": "Waiting",
                "label": "I organize my work and life in a way that promotes creativity and focus."
            },
            {
                "number": 6,
                "status": "Waiting",
                "label": "I make time for activities that rejuvenate and inspire my creative mind."
            },
            {
                "number": 7,
                "status": "Waiting",
                "label": "I easily let go of ideas that are not working and move on to new ones."
            },
            {
                "number": 8,
                "status": "Waiting",
                "label": "Creativity always involves uncertainty, but I don’t let it discourage me."
            },
            {
                "number": 9,
                "status": "Waiting",
                "label": "I believe in my ability to improve my work as I put more time into it."
            },
            {
                "number": 10,
                "status": "Waiting",
                "label": "I believe that I mostly have control over the quality of my finished work."
            },
            {
                "number": 11,
                "status": "Waiting",
                "label": "I believe that the more I use my creativity, the more creativity I will have."
            },
            {
                "number": 12,
                "status": "Waiting",
                "label": "I am patient with myself and give myself time to develop ideas fully."
            },
            {
                "number": 13,
                "status": "Waiting",
                "label": "I am able to clear my mind and focus on my work without too much trouble."
            },
            {
                "number": 14,
                "status": "Waiting",
                "label": "I can browse and enjoy the creative work of others without feeling inferior."
            },
            {
                "number": 15,
                "status": "Waiting",
                "label": "I enjoy the act of creating, even when the final product isn’t what I hoped for."
            },
            {
                "number": 16,
                "status": "Waiting",
                "label": "I’m able to focus long enough each day to finish the creative work that I committed to."
            }
        ],
        "Motivation": [
            {
                number: 1,
                status: "Waiting",
                label: "At the end of most days, I feel proud of myself and how I spent my day."
            },
            {
                number: 2,
                status: "Waiting",
                label: "I feel a natural urge to create or produce something at least a few times most days."
            },
            {
                number: 3,
                status: "Waiting",
                label: "When I have a lot on my plate, I can manage myself well and stay productive without procrastinating."
            },
            {
                number: 4,
                status: "Waiting",
                label: "I have at least one thing in my life that I'm very excited to work on soon."
            },
            {
                number: 5,
                status: "Waiting",
                label: "On days I don't feel motivated, I have ways to still complete tasks even if I have to make adjustments."
            },
            {
                number: 6,
                status: "Waiting",
                label: "When I take short breaks from work, I can recharge and feel ready to do more."
            },
            {
                number: 7,
                status: "Waiting",
                label: "I can apply myself to projects in my life without needing stressors (nagging bosses, deadlines, etc.) to get me motivated."
            },
            {
                number: 8,
                status: "Waiting",
                label: "I feel a strong sense of pride or ownership over my work."
            },
            {
                number: 9,
                status: "Waiting",
                label: "A year from now, I see myself working hard, completing my goals, and experiencing satisfaction through effort."
            },
            {
                number: 10,
                status: "Waiting",
                label: ""
            },
            {
                number: 11,
                status: "Waiting",
                label: ""
            },
            {
                number: 12,
                status: "Waiting",
                label: "Even if I experience setbacks or have to make adjustments, nothing will prevent me from doing amazing things in the future."
            }
        ]
    };

    let assessmentContent = (
        <div></div>
    );
    if (activeAssessment) {
        assessmentContent = (
            <div>
                <div className='goals-heading'>
                    <img src={`skill-${activeAssessment.name.toLowerCase()}.png`} className='plant-img icon-img' /> <span className='skill-phrase skill-phrase-focus'>{activeAssessment.name}</span> Assessment
                </div>

                {isPop1Open && <WizardPop name={'start-first-assessment'} positions={['right']} shift={[-80, 140]} isOpen={isPop1Open} onClose={() => { setIsPop1Open(false); }}></WizardPop>}

                <div className='goals-pagehead'>
                    {activeAssessment.completedQuestions ? activeAssessment.completedQuestions.length : 0} of 12 questions answered
                </div>

                <div className='questions'>
                    {activeAssessment && activeAssessment.pageQuestions.map(question => {
                        return (
                            <QuestionMeter question={question} status={question.status} answer={question.answer} thought={question} isShort={true} onCompleted={handleQuestionCompleted} isEmotion={true} fillColor={"Purple"}></QuestionMeter>
                        )
                    })}
                </div>

            </div >
        );
    }

    let scoreContent = (
        <div></div>
    );
    if (activeAssessment && activeAssessment.gradedScore) {
        scoreContent = (
            <div className='ScorePage'>
                <div className='goals-heading'>
                    <img src={`skill-${activeAssessment.name.toLowerCase()}.png`} className='plant-img icon-img' /> <span className='skill-phrase skill-phrase-focus'>{activeAssessment.name}</span> Assessment
                </div>

                {isPop2Open && <WizardPop name={'finish-first-assessment'} positions={['right']} shift={[-80, 240]} isOpen={isPop2Open} onClose={() => { setIsPop2Open(false); }}></WizardPop>}

                <div className='goals-pagehead'></div>

                <div className='score-subheading'>
                    {isReassess ? "Reassessment completed" : "Assessment completed"}
                </div>
                <div className='score-heading'>
                    Nicely done!
                </div>


                <div className='scorecard'>
                    <div className='scorecard-score'>
                        {activeAssessment.gradedScore}
                    </div>

                    {isStart && (
                        <div className='scorecard-subscore'>
                            Initial Assessment
                        </div>
                    )}
                    {isReassess && (
                        <div className='scorecard-subscore'>
                            Previous Score: 2.8
                        </div>
                    )}

                    <div className='scorecard-scale'>
                        On a 1 to 5 scale
                    </div>
                </div>

                <div className='assessment-done-button goals-done-button' onClick={handleAssessmentDoneClick}>Continue</div>

                {/* <div className='belowcard'>
                    <p>
                        While you have room for improvement,
                        you have a foundational ability to focus
                        when needed.
                    </p>
                    <p>
                        By improving your Focus skill from here,
                        you'll strengthen your ability to
                        apply yourself to tasks and goals
                        with more energy and less stress or a
                        sense of having to force yourself to focus.
                    </p>
                </div> */}


            </div>
        );
    }


    const goalsContent = (
        <div className='goals-content'>
            <div className='goals-heading'>
                {isReassess ? "Choose areas of your mind to explore" : "Choose your first two areas to explore"}
            </div>

            <div className='goals'>
                {goals.map(goal => {

                    const isSelected = selectedGoals.includes(goal.name);
                    //const isSelected = false;

                    let goalClassName = 'GoalCard';
                    if (isSelected) {
                        goalClassName += " IsSelected";
                    }

                    return (
                        <div className={goalClassName} onClick={() => handleGoalClick(goal)}>

                            <div className='title-content'>
                                <img src={`skill-${goal.name.toLowerCase()}.png`} className='plant-img icon-img' />
                                <div className='title'>{goal.title}</div>
                            </div>
                            <div className='goal-description'>
                                {goal.description}
                            </div>
                        </div>
                    )
                })}
            </div>

            <div className='goals-done-button' onClick={handleGoalsDoneClick}>Continue</div>

            {/* <div className='goals-subheading'>
                Choose whichever appeals to you most right now. You can switch
                between areas of focus in the future.
            </div> */}
        </div>
    );

    return (
        <div className={className} ref={ref} style={parentStyles}>
            <div className='close-button fade-in-content' onClick={handleCloseClick}>
                <img src="close.svg" className="icon-img" />
            </div>

            <div className='sheet-content drawer-content'>
                {step === "Goals" && goalsContent}
                {step === "Assessment" && activeAssessment && assessmentContent}
                {step === "Score" && activeAssessment && scoreContent}
            </div>

        </div>
    )
};

export default PickGoalsDrawer;
