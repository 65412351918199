
import React, { useContext, useRef } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import UserContext from '../UserContext';
import './AssessmentCard.scss';
import EmotionMeter from './EmotionMeter';
import Back from '../helpers/Back';
import UI from './UI';


function AssessmentCard({ assessmentRound, onStartAssessment }) {

    const { user, changeUser } = useContext(UserContext);

    console.log('assessment card', user.active_assessments, assessmentRound);

    const [isExpanded, setIsExpanded] = useState(false);
    const hoverTimeout = useRef(null);

    const assessmentRow = user.negative_rows.find(
        r => r.name === assessmentRound.name
    );
    const assessmentStats = assessmentRow.assessment_stats;
    const assessmentHistory = assessmentStats.history;

    let assessmentArea = null;
    if (assessmentRow.type === "Area") {
        assessmentArea = goals.find(g => g.title === assessmentRow.name);
    }

    let className = "AssessmentCard";

    if (isExpanded && assessmentRound.assessment_stats.best) {
        className += " IsExpanded";
    }

    const handleStartAssessmentClick = () => {
        onStartAssessment(assessmentRound);
    };

    const handleMouseEnter = () => {
        hoverTimeout.current = setTimeout(() => {
            setIsExpanded(true);
        }, 450);
    };

    const handleMouseLeave = () => {
        clearTimeout(hoverTimeout.current);
        setIsExpanded(false);
    };

    className += " ThemeColor" + assessmentRow.color;

    return (
        <div className='AssessmentCard-position' style={{ zIndex: 1000 - assessmentRound.number }}>
            <div className={className} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>

                <div className='widget-heading'>

                    <div className={`label ThemeMediumText${assessmentRound.color}`}>{assessmentRound.name}</div>
                    <img src={`skill-${assessmentRound.icon}.png`} className='icon'></img>

                    {assessmentRound.assessment_stats.main && (
                        <div className={UI.getScoreClass(assessmentRound.assessment_stats.main)}>{assessmentRound.assessment_stats.main}</div>
                    )}
                    {!assessmentRound.assessment_stats.main && (
                        <div className='score score-empty'>No score yet</div>
                    )}

                </div>

                {assessmentRow.type === "Goal" && (
                    <div className='description'>
                        A personal goal you are working towards right now
                    </div>
                )}
                {assessmentRow.type === "Area" && (
                    <div className='description'>
                        {assessmentArea.description}
                    </div>
                )}


                <div className='start-content'>
                    <div className='start-button' onClick={handleStartAssessmentClick}>Start Assessment</div>
                </div>

                <div className='progress-content'>

                </div>

                <div className='expanded-content'>
                    <div className='score-breakdown'>
                        {/* <div className='score-item'>
                            <div className='label'>
                                Average of all users
                            </div>
                            <div className={UI.getScoreClass(assessmentRound.assessment_stats.global)}>{assessmentRound.assessment_stats.global}</div>
                        </div> */}
                        <div className='score-item'>
                            <div className='label'>
                                My best score
                            </div>
                            <div className={UI.getScoreClass(assessmentRound.assessment_stats.best)}>{assessmentRound.assessment_stats.best}</div>
                        </div>



                    </div>

                    <div className='score-breakdown score-breakdown-history'>
                        {assessmentHistory.map(item => {
                            return (
                                <div className='score-item'>
                                    <div className='label'>
                                        {item.date}
                                    </div>
                                    <div className={UI.getScoreClass(item.score)}>{item.score}</div>
                                </div>
                            )
                        })}

                    </div>
                </div>

            </div>
        </div>
    );


}

export default AssessmentCard;

const goals = [
    {
        id: 1,
        title: "Natural Energy",
        name: "Mood",
        description: "Discover new energy through joy and spontaneity"
    },
    {
        id: 2,
        title: "Drive to Finish",
        name: "Motivation",
        description: "Unblock beliefs holding you back from finishing things"
    },
    {
        id: 3,
        title: "Creative Flow",
        name: "Focus",
        description: "Reduce mental friction and understand your resistances"
    },
    {
        id: 5,
        title: "Life Purpose",
        name: "Purpose",
        description: "Envision and clarify a more expansive, purposeful life"
    },
    {
        id: 4,
        title: "Balanced Self-Care",
        name: "Ease",
        description: "Find balance and ease that renews your energy and flow"
    },
    {
        id: 6,
        title: "Relational Openness",
        name: "Openness",
        description: "Open up to deeper and more authentic relationships"
    }
];