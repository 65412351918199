import React, { useContext } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import './AssessmentSidebar.scss';
import ThoughtSetContent from './ThoughtSetContent';
import StackCard from './StackCard';

import UserContext from '../UserContext';
import AssessmentCard from './AssessmentCard';


function AssessmentSidebar({ activeAssessment }) {

    let className = "AssessmentSidebar";

    console.log('activeAssessment2', activeAssessment);

    console.log('activeAssess2', activeAssessment.questions, activeAssessment.questions, { ...activeAssessment });

    return (
        <div className={className}>
            <div className='slots'>
                {activeAssessment.questions && activeAssessment.questions.map(slot => {

                    console.log('activeAssessment slot', slot);

                    let className = 'slot';

                    className += " " + slot.status;

                    if (slot.isFaded) {
                        className += " IsFaded";
                    }

                    if (!isObject(slot) || slot.status !== "Completed") {
                        return (
                            <div className={"slot-placeholder"}></div>
                        );
                    }

                    return (
                        <div className={className}>

                            <div className='score-content'>
                                <div className='score'>{slot.answer}</div>
                            </div>

                            <div className='question-content'>
                                <div className='number'>{slot.number}. </div>
                                <div className='question'>{slot.label}</div>
                            </div>

                        </div>
                    )


                })}
            </div>
        </div>
    );


}

export default AssessmentSidebar;

function isObject(value) {
    return value !== null && typeof value === 'object';
}