
import React, { useContext, useRef } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import UserContext from '../UserContext';
import './QuestionCard.scss';
import EmotionMeter from './EmotionMeter';
import Back from '../helpers/Back';


function QuestionCard({ question, onCompleted }) {

    const { user, changeUser } = useContext(UserContext);

    const [isExpanded, setIsExpanded] = useState(true);
    const [isCompleted, setIsCompleted] = useState(false);
    const hoverTimeout = useRef(null);

    let className = "QuestionCard";

    if (isExpanded) {
        className += " IsExpanded";
    }
    if (question.is_new) {
        className += " NewFadedOut";
    }
    if (isCompleted) {
        className += " IsCompleted";
    }

    const handleMouseEnter = () => {
        hoverTimeout.current = setTimeout(() => {
            setIsExpanded(true);
        }, 150);
    };

    const handleMouseLeave = () => {
        clearTimeout(hoverTimeout.current);
        //setIsExpanded(false);
    };

    const handleMeterClick = async (gauge) => {

        setTimeout(() => {
            setIsCompleted(true);

        }, 50);

        question.answer = gauge;

        setTimeout(() => {
            onCompleted(question, gauge);
        }, 400);

        //const response = await Back.recordCompletedQuestion(question);
    };

    return (
        <div className='QuestionCard-position'>
            <div className={className} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>

                <div className='main-content'>
                    {/* <img src={`skill-${question.assessment_icon}.png`} className='icon' /> */}
                    <div className='number'>{question.number}. </div>
                    <div className='question'>{question.label}</div>
                </div>

                <div className='expanded-content'>
                    <EmotionMeter onCompleted={handleMeterClick} thought={"Test"} isFirst={false} fillColor={"Teal"} titleIcon={"X"}></EmotionMeter>
                </div>

            </div>
        </div>
    );


}

export default QuestionCard;