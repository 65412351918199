import React, { useContext } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import ContentLoader from 'react-content-loader';
import AnimationContext from '../AnimationContext';
import Back from '../helpers/Back';
import PageEffect from '../PageEffect';
import UserContext from '../UserContext';
import './SuggestionsSheet.scss';
import ThoughtSetContent from './ThoughtSetContent';
import UI from './UI';


function SuggestionsSheet({ sessionThoughts, row, options, goal, assessmentRound, color, category, onSelection, onRefreshRequested, onAboutClick }) {

    const animations = useContext(AnimationContext);

    const completedThoughtCount = sessionThoughts.filter(
        i => i.status === "Active" || i.status === "Completed"
    ).length;

    const { user, changeUser } = useContext(UserContext);

    const handleOptionClick = (option, index) => {
        // Workaround right now because whole-screen-click-blocking not working yet in Drawer.
        if (user && (user.is_account_gated || user.is_thoughts_locked)) {
            return;
        }

        option.row = { ...row };
        option.row.items = null; // To prevent circular references with thought sets

        setTimeout(() => {
            animateCard(option, index);
        }, 10);

        //return;

        setTimeout(() => {
            onSelection(option);
        }, 20);
    };

    const animateCard = (option, index) => {
        //animations.addPageEffect(PageEffect.FadeOutSessionGrid);
        animations.addPageEffect(PageEffect.FadeOutNegativeGrid);

        let optionId = 'suggestions-option-' + row.number + '-' + index;
        const el = document.getElementById(optionId);
        const destEl = document.querySelector('.drop-dest');

        const elRect = el.getBoundingClientRect();
        const destinationRect = destEl.getBoundingClientRect();

        const newEl = el.cloneNode(true);
        newEl.id = newEl.id + '-clone';
        newEl.classList.add("SessionThoughtExpanded");
        newEl.classList.add("ReinforceInProgress");

        newEl.style.position = "fixed";
        newEl.style.top = elRect.top - 4.5 + 'px';
        newEl.style.left = elRect.left + 13.5 + 'px';

        newEl.style.width = "171px";
        // newEl.style.height = "125px";

        // newEl.style.width = "293px";
        // newEl.style.height = "175px";

        //newEl.style.transform = "scale(1.08)";

        //newEl.style.width = "320px";
        //newEl.style.height = "185px";
        newEl.style.transform = "scale(1)";
        newEl.style.top = elRect.top - 20 + 'px';
        newEl.style.left = elRect.left - 38.5 + 'px';
        //newEl.style.transform = "scale(.95)";
        newEl.style.boxShadow = " 1px 2px 3px 1px #e3e6f7";
        newEl.style.transition = ".4s";

        newEl.style.position = "fixed";
        newEl.style.zIndex = 5000;
        newEl.style.top = elRect.top - 4.5 + 'px';
        newEl.style.left = elRect.left + 13.5 + 'px';

        // newEl.style.width = "293px";
        // newEl.style.height = "135px";

        //newEl.style.transform = "scale(1.08)";

        //newEl.style.width = "320px";
        //newEl.style.height = "185px";
        newEl.style.transform = "scale(1)";
        newEl.style.top = elRect.top - 15 + 'px';
        newEl.style.left = elRect.left - 4.5 + 'px';
        //newEl.style.transform = "scale(.95)";
        //newEl.style.boxShadow = " 1px 2px 3px 1px #e3e6f7";
        newEl.style.transition = ".4s";

        document.querySelector('.ThoughtGrid2').after(newEl);

        el.classList.add("Selected");

        //return;

        setTimeout(() => {
            // newEl.style.width = "343px";
            // newEl.style.height = "147px";
            newEl.style.transform = "scale(1.1)";
        }, 10);

        setTimeout(() => {
            const destEl = document.querySelector('.drop-dest');
            const destinationRect = destEl.getBoundingClientRect();

            // DEBT: Totally stumped on why the card is misaligned on larger screens.
            const monitorAdd = window.innerWidth > 1800 ? -1 : -1;

            newEl.style.transform = "scale(1.35)";

            // newEl.style.width = "294px";
            // newEl.style.height = "114px";
            newEl.style.top = destinationRect.top + 110 - 56 + 25 + 'px';
            newEl.style.left = destinationRect.left + 270 + 13 + 25 + 31 + monitorAdd + 'px';
            //newEl.style.boxShadow = "0.5px 0.5px 2px 0px #e3e6f7";

        }, 400);

        setTimeout(() => {


            //newEl.style.borderRadius = "18px";
            newEl.style.transform = "scale(1.25)";
            newEl.classList.add("SessionThoughtExpanded");

            newEl.style.boxShadow = "0.5px 1.5px 1px 0px rgba(182, 151, 202, 0.45)";
            newEl.style.backgroundSize = "172px";
            //newEl.style.opacity = .5;



        }, 1100);

        setTimeout(() => {
            animations.removePageEffect(PageEffect.FadeOutSessionGrid);
            animations.removePageEffect(PageEffect.FadeOutNegativeGrid);
            //newEl.remove();
            //newEl.style.opacity = .5;
        }, 1450);
    }

    let className = "SuggestionsSheet";

    className += " " + category;

    className += " Color" + row.color;

    return (
        <div className={className}>
            <div className='category-content'>

                <div className='category-heading'>
                    {category === "Work" ? "I want to make more money" : "I want to have more confidence"}
                </div>
                {/* <img src="work-about-head.png" className='category-img category-work-img' /> */}
            </div>
            <div className='clearfix'></div>

            <div className='options'>

                {row.items && row.items.map((option, index) => {
                    let className = 'SuggestionOption ';
                    let optionId = 'suggestions-option-' + row.number + '-' + index;

                    className += " Option" + (index + 1) + " ";

                    className += " Row" + row.number + " ";

                    className += " Color" + row.color + " ";

                    className += option.type;

                    if (option.isNeutral) {
                        className += " Neutral";
                    }

                    const thoughtChecks = [
                        {
                            number: 1,
                            status: "Completed"
                        },
                        {
                            number: 2,
                            status: "Completed"
                        },
                        {
                            number: 3,
                            status: "Pending"
                        },
                        // {
                        //     number: 4,
                        //     status: "Pending"
                        // }
                    ];

                    if (option.type === "Reinforce" && option.status === "Empty") {
                        className += " IsEmpty";
                        return (
                            <div className={className} id={optionId} onClick={() => handleOptionClick(option, index)} key={optionId}>
                                <div className='empty-label'>
                                    Complete more cards first
                                </div>
                            </div>
                        )
                    }

                    if (option.type === "Reinforce") {
                        const thisSlot = {
                            ...option,
                            thought: option
                        };
                        return (
                            <div className={className} id={optionId} onClick={() => handleOptionClick(option, index)} key={optionId}>
                                <div className='preview-content'>
                                    <div className='label'>
                                        <div className='label-content'>{UI.removeBracketedWord(option.reframing_thought)}</div>
                                    </div>

                                    <div className='thought-tags'>
                                        {/* <div className='thought-tag tag-calm-mind'>Calm Mind</div> */}
                                        <div className='thought-tag tag-self-esteem'>{option.thought_set.primary_skill}</div>

                                    </div>
                                    <div className='thought-checks'>
                                        <div className='area-icon'><img src={`skill-${UI.extractWordInBrackets(option.reframing_thought).toLowerCase()}.png`} className={`area-img area-${UI.extractWordInBrackets(option.reframing_thought).toLowerCase()}`} /></div>
                                    </div>

                                </div>
                                <div className='thought-set-content'>
                                    <ThoughtSetContent slot={thisSlot} isFlat={true} isIncomplete={true} isGrid={false}></ThoughtSetContent>
                                </div>
                            </div>
                        )
                    }

                    if (option.isPickUp) {
                        className += ' IsPickUp';

                        return (
                            <div className={className} id={optionId} onClick={() => handleOptionClick(option, index)} key={optionId} style={{ backgroundImage: 'url(sticky-note-' + row.color + '.png)' }}>
                                <div className='flare flare-tape'><img src="sticky-tape.png" className='tape-img' /></div>
                                {/* <div className='flare flare-pin'><img src="sticky-pin.png" className='pin-img' /></div> */}
                                <div className='label'>
                                    <div className='label-content'>{option.thought}</div>
                                </div>
                                <div className='pickup-progress'>

                                    <div className='pickup-progressbar'>
                                        <div className='pickup-fillbar' style={{ width: '50%' }}></div>
                                    </div>
                                    <div className='pickup-outof'>{option.exploreItems.length} <span className='end'>/ 6</span></div>

                                </div>
                            </div>
                        );
                    }

                    return (
                        <div className={className} id={optionId} onClick={() => handleOptionClick(option, index)} key={optionId} style={{ backgroundImage: 'url(sticky-note-' + row.color + '.png)' }}>
                            <div className='flare flare-tape'><img src="sticky-tape.png" className='tape-img' /></div>
                            {/* <div className='flare flare-pin'><img src="sticky-pin.png" className='pin-img' /></div> */}
                            <div className='label'>
                                <div className='label-content'>{option.thought}</div>
                            </div>
                        </div>
                    )
                })}

                {row.items && row.items.length < 1 && (
                    <div className='options-loading-placeholder'></div>
                )}

                <div className={`SuggestionLabel Row${row.number}`}>
                    {row.type === "Area" && (
                        <div className='widget-heading'>
                            <div className='label'>{row.name}</div>
                            <img src={`skill-${row.icon}.png`} className='icon'></img>



                        </div>
                    )}
                    {row.type === "Goal" && (
                        <div className='label'>{row.goal.label}</div>
                    )}

                </div>

                <div className='clearfix'></div>


            </div>

            {row.items && row.items.length < 1 && (
                <div className='negative-loading-placeholders'>
                    {[1, 2, 3, 4].map(
                        p => {
                            return (
                                <div className='negative-loading-placeholder' key={p}>
                                    <ContentLoader viewBox="0 0 400 160"
                                        height={78} width={400}
                                        backgroundColor="#e8d8a9"
                                        foregroundColor="#e6c14f"
                                    >
                                        <rect x="0" y="21" rx="4" ry="4" width="244" height="9" />
                                        <rect x="0" y="45" rx="3" ry="3" width="145" height="9" />
                                    </ContentLoader>
                                </div>
                            )
                        }
                    )}

                </div>
            )}


        </div>
    );


}

export default SuggestionsSheet;